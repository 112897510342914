import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Field, reduxForm, formValues } from 'redux-form'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../enhancers/index.js'
import {
  Input,
  Select,
  CurrencyInput,
  InputMaxMin,
} from '../../../components/index'
import { Button } from 'reactstrap'
import { get } from 'lodash'
import Result from './TableSearchResult'
import ModalDetail from './ModalDetail'
import { Panel, PanelHeader } from '../../../components/panel/panel.jsx'

const Search = props => (
  <div>
    <ol className="breadcrumb float-xl-right">
      <li className="breadcrumb-item">
        <Link to="/searchs">Search</Link>
      </li>
      {/* <li className="breadcrumb-item active">Active Page</li> */}
    </ol>
    <h1 className="page-header">
      Search <small></small>
    </h1>

    <div className="row">
      <div className="col-xl-4">
        <Panel>
          <PanelHeader noButton={true}>Search</PanelHeader>
          <div>
            <form onSubmit={props.onClickSearch} className="m-15">
              <div className="form-group m-r-5">
                <label htmlFor="gender">Partner</label>
                <Field
                  name="partner"
                  component={Select}
                  options={get(
                    props.searchConfig,
                    'partnerConfigs.filter.partners.options',
                    []
                  )}
                  onChange={props.setProductTypeToNull}
                  isSearchable
                />
              </div>

              <div className="form-group m-r-5">
                <label htmlFor="gender">Product Id</label>
                <Field
                  name="product_id"
                  component={Select}
                  options={get(
                    props.searchConfig,
                    'productConfigs.filter.products.options',
                    []
                  )}
                  onChange={props.setProductTypeAndType}
                  isSearchable
                />
              </div>

              <div className="form-group m-r-5">
                <label htmlFor="gender">Product Type</label>
                <Field
                  name="productType"
                  component={Select}
                  options={get(
                    props.searchConfig,
                    'productConfigs.filter.productType.options',
                    []
                  )}
                  onChange={props.setPartnerToNull}
                  isSearchable
                  required
                  disabled={props.product_id}
                />
              </div>

              {props.productType && (
                <>
                  {props.productType !== 'paConfigs' && (
                    <div className="form-group m-r-5">
                      <label htmlFor="gender">Type</label>
                      <Field
                        name="type"
                        component={Select}
                        options={get(
                          props.searchConfig,
                          `${props.productType}.filter.type.options`,
                          []
                        )}
                        isSearchable
                        disabled={props.product_id}
                        onChange={props.setWeightProtect}
                      />
                    </div>
                  )}

                  <div className="form-group m-r-5">
                    <label htmlFor="gender">Company</label>
                    <Field
                      name="companyId"
                      component={Select}
                      options={get(
                        props.searchConfig,
                        `${props.productType}.filter.companies.options`,
                        []
                      )}
                      isSearchable
                      disabled={props.product_id}
                    />
                  </div>

                  {props.productType === 'taConfigs' && (
                    <div className="form-group   m-r-5">
                      <label htmlFor="gender">Category</label>
                      <Field
                        name="category"
                        component={Select}
                        options={get(
                          props.searchConfig,
                          `${props.productType}.filter.category.options`,
                          []
                        )}
                        isSearchable
                      />
                    </div>
                  )}

                  <>
                    <div className="form-group m-r-5">
                      <label htmlFor="gender">Gender</label>
                      <Field
                        name="gender"
                        component={Select}
                        options={get(
                          props.searchConfig,
                          `${props.productType}.filter.gender`,
                          []
                        )}
                        isSearchable
                      />
                    </div>

                    <div className="form-group m-r-5">
                      <label htmlFor="age">Age</label>
                      <div className="d-flex">
                        <Field
                          name="age"
                          component={InputMaxMin}
                          className="form-control height-40 inverse-mode"
                          placeholder="Year"
                          min={0}
                          max={99}
                          onChange={props.setAgeMonthToNull}
                        />
                        <Field
                          name="ageMonth"
                          component={InputMaxMin}
                          className="form-control height-40 inverse-mode"
                          placeholder="Month"
                          disabled={!props.age}
                          min={0}
                          max={11}
                        />
                      </div>
                    </div>
                  </>

                  {props.type === 'ci' && (
                    <div className="form-group m-r-5">
                      <label htmlFor="sumInsured">Sum Insured</label>
                      <Field
                        name="sumInsured"
                        component={Input}
                        className="form-control height-40 inverse-mode"
                        onChange={props.onChangeSumInsured}
                      />
                    </div>
                  )}

                  {props.productType === 'lifeConfigs' && (
                    <>
                      <div className="form-group m-r-5">
                        <label htmlFor="sumInsured">Period</label>
                        <Field
                          name="period"
                          component={Select}
                          options={get(
                            props.searchConfig,
                            `productConfigs.filter.period.options`,
                            []
                          )}
                          isSearchable
                          disabled={props.type === 'term'}
                        />
                      </div>
                      <div className="form-group m-r-5">
                        <label htmlFor="sumInsured">Premium</label>
                        <Field
                          name="premium"
                          component={CurrencyInput}
                          className="form-control height-40 inverse-mode"
                          onChange={props.onChangPremium}
                          // required={!props.sumPension}
                        />
                      </div>
                      <div className="form-group m-r-5">
                        <label htmlFor="sumInsured">Weight Protect</label>
                        <Field
                          name="weightProtect"
                          component={Select}
                          options={get(
                            props.searchConfig,
                            `${props.productType}.filter.weightType.options`,
                            []
                          )}
                          isSearchable
                          disabled={props.type === 'term'}
                        />
                      </div>
                    </>
                  )}

                  {(props.productType === 'lifeConfigs' ||
                    props.productType === 'paConfigs') && (
                    <div className="form-group m-r-5">
                      <label htmlFor="sumInsured">Sum Insured</label>
                      <Field
                        name="sumInsured"
                        component={CurrencyInput}
                        className="form-control height-40 inverse-mode"
                        onChange={props.onChangeSumInsured}
                        // required={!props.sumPension}
                      />
                    </div>
                  )}

                  {props.productType === 'lifeConfigs' &&
                    props.type === 'annuity' && (
                      <div className="form-group m-r-5">
                        <label htmlFor="sumInsured">Sum Pension</label>
                        <Field
                          name="sumPension"
                          component={CurrencyInput}
                          className="form-control height-40 inverse-mode"
                          onChange={props.onChangePension}
                          // required={!props.sumInsured}
                        />
                      </div>
                    )}

                  {props.productType === 'healthConfigs' &&
                    props.type === 'hs' && (
                      <>
                        <div className="form-group m-r-5">
                          <label htmlFor="sumInsured">room</label>
                          <Field
                            name="room"
                            component={CurrencyInput}
                            className="form-control height-40 inverse-mode"
                            onChange={props.onChangeRoom}
                          />
                        </div>

                        <div className="form-group m-r-5">
                          <label htmlFor="sumInsured">Total Benefit</label>
                          <Field
                            name="totalAnnualBenefits"
                            component={CurrencyInput}
                            className="form-control height-40 inverse-mode"
                            onChange={props.onChangeTotalAnnualBenefits}
                          />
                        </div>
                      </>
                    )}
                </>
              )}
              <Button
                type="submit"
                className="btn m-t-10 height-40 width-full"
                color="primary"
              >
                <div
                  className="row width-full text-center"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <i className="fa fa-search fa-fw m-t-3 m-r-5" /> Search
                </div>
              </Button>
              <hr />
            </form>
          </div>
        </Panel>
      </div>
      <div className="col-xl-8 ">
        <Panel>
          <PanelHeader noButton={true}>Result</PanelHeader>
          {!props.loading && (
            <Result
              data={props.all}
              setModalIsOpen={props.setModalIsOpen}
              setProductId={props.setProductId}
              tableColumns={props.tableColumns}
            />
          )}

          {props.loading && (
            <div className="fa-5x text-center">
              <i className="fas fa-spinner fa-pulse text-grey-darker" />
            </div>
          )}
        </Panel>
      </div>
    </div>
    <ModalDetail
      isOpen={props.modalIsOpen}
      toggle={props.setIsOpenOrCloseModal}
      productId={props.productId}
      searchConfig={props.searchParams}
    />
  </div>
)

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    fetch: stores.search.fetch,
    all: stores.search.all || [],
    fetchConfig: stores.search.fetchConfig,
    searchConfig: get(stores, 'search.searchConfig', []),
    initialValues: {},
  })),
  reduxForm({
    form: 'SearchForm',
  }),
  formValues('productType'),
  formValues('type'),
  formValues('sumInsured'),
  formValues('sumPension'),
  formValues('room'),
  formValues('totalAnnualBenefits'),
  formValues('product_id'),
  formValues('partner'),
  formValues('age'),
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [productId, setProductId] = useState('')
    const [searchParams, setSearchParams] = useState({})

    const setIsOpenOrCloseModal = useCallback(() => {
      setModalIsOpen(!modalIsOpen)
    }, [modalIsOpen])

    const onClickSearch = props.handleSubmit(async values => {
      setLoading(true)
      await props.fetch({ ...values })
      setSearchParams(values)
      setLoading(false)
    })

    useEffect(() => {
      props.fetchConfig()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const tableColumns = useMemo(() => {
      let columns = [
        {
          Header: 'Product Name',
          accessor: 'productName',
          Cell: row => (
            <span className="btn-group">
              <img
                src={row.original.logoPath && row.original.logoPath}
                className="width-30 height-30 rounded-corner bg-grey-transparent-2 text-center p-auto"
                alt=""
              />
              <div className="m-l-10 p-t-5">{row.original.productName}</div>
            </span>
          ),
          // className: 'p-t-15',
        },
        {
          Header: 'Type',
          accessor: 'type',
        },
      ]

      if (props.searchConfig.product_type === 'LifeProduct') {
        columns.push({
          Header: 'Noon Star',
          accessor: 'noonStar',
          Cell: row => (
            <>
              {row.original.noonStar && <div>{row.original.noonStar}</div>}
              {!row.original.noonStar && <div className="text-center"> - </div>}
            </>
          ),
        })
        columns.push({
          Header: 'Noon Score',
          accessor: 'noonScore',
          Cell: row => (
            <>
              {row.original.noonScore && <div>{row.original.noonScore}</div>}
              {!row.original.noonScore && (
                <div className="text-center"> - </div>
              )}
            </>
          ),
        })
        columns.push({
          Header: 'Sum Insured',
          accessor: 'sumInsured',
        })
      }

      if (props.searchConfig.product_type === 'HealthProduct') {
        if (props.searchConfig.type === 'ci') {
          columns.push({
            Header: 'Sum Insured',
            Cell: row => (
              <div>
                {row.original.minSumInsured} - {row.original.maxSumInsured}
              </div>
            ),
          })
        } else {
          columns.push({
            Header: 'ค่าห้อง',
            accessor: 'room',
          })
        }

        columns.push({
          Header: 'Total Premium',
          accessor: 'totalPremium',
        })
      }

      return columns
    }, [props.searchConfig])

    const resetForm = useCallback(() => {
      props.reset()
      setSearchParams({})
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChangeSumInsured = useCallback(
      e => {
        props.change('sumPension', '')
        props.change('premium', '')
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.sumPension]
    )

    const onChangePension = useCallback(
      e => {
        props.change('sumInsured', '')
        props.change('premium', '')
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.sumInsured]
    )

    const onChangPremium = useCallback(
      e => {
        props.change('sumInsured', '')
        props.change('sumPension', '')
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.sumInsured]
    )

    const onChangeRoom = useCallback(
      e => {
        if (props.totalAnnualBenefits) {
          props.change('totalAnnualBenefits', '')
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.totalAnnualBenefits]
    )

    const onChangeTotalAnnualBenefits = useCallback(
      e => {
        if (props.room) {
          props.change('room', '')
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.room]
    )

    const setProductTypeToNull = useCallback(() => {
      props.change('productType', '')
      props.reset()
      setSearchParams({})
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setPartnerToNull = useCallback(() => {
      props.reset()
      setSearchParams({})
      props.change('partner', props.partner)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.partner])

    const setProductTypeAndType = useCallback(
      e => {
        const option = get(
          props.searchConfig,
          'productConfigs.filter.products.options',
          []
        ).find(item => item.value === e)
        props.reset()
        setSearchParams({})
        if (option) {
          props.change('productType', option.productType)
          props.change('companyId', option.companyId)

          if (
            option.productType === 'lifeConfigs' ||
            option.productType === 'healthConfigs'
          ) {
            props.change('type', option.type)
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.searchConfig]
    )

    const setWeightProtect = useCallback(e => {
      if (e === 'term') {
        props.change('weightProtect', '5')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setAgeMonthToNull = useCallback(e => {
      props.change('ageMonth', '')
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
      loading,
      setLoading,
      onClickSearch,
      setIsOpenOrCloseModal,
      modalIsOpen,
      setModalIsOpen,
      setProductId,
      productId,
      tableColumns,
      onChangePension,
      onChangeSumInsured,
      onChangeRoom,
      onChangeTotalAnnualBenefits,
      resetForm,
      setProductTypeToNull,
      setPartnerToNull,
      setProductTypeAndType,
      searchParams,
      setWeightProtect,
      onChangPremium,
      setAgeMonthToNull,
    }
  })
)

export default enhancer(Search)
