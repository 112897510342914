import React, { useState, useCallback } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import CollapseComponent from './CollapseComponent'

const SetupPremium = props => (
  <div>
    <div className="p-b-10 d-flex justify-content-between">
      <div className="f-s-20 f-w-700">Premium</div>
      <button type="button" className="btn btn-success" onClick={props.onAdd}>
        Add
      </button>
    </div>
    {props.form.values.paPremiumMasters &&
      props.form.values.paPremiumMasters.map((field, index) => (
        <CollapseComponent
          {...props}
          field={`paPremiumMasters[${index}]`}
          key={field + index}
          index={index}
          fields={props.fields}
          configs={props.configs}
          isHealth={props.isHealth}
          remove={props.remove}
          values={field}
        />
      ))}
  </div>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const onAdd = useCallback(() => {
      props.push({})
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fields])

    return {
      loading,
      setLoading,
      onAdd,
    }
  })
)

export default enhancer(SetupPremium)
