import React, { useState, useEffect, useCallback } from 'react'
import { Collapse, CardHeader, CardBody, Card } from 'reactstrap'
import { withHooks } from '../../../../enhancers/index.js'
import { compose } from 'recompose'
import { Field } from 'formik'
import {
  Input,
  Select,
  CurrencyInput,
} from '../../../../components/formikComponent/index'
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert'

export const CollapseFinancialBudgetCompany = props => (
  <Card
    className="text-black"
    key={`financialBudgetCompany[${props.itemIndex}]`}
    id={`${props.itemIndex}`}
    style={{ position: 'relative' }}
  >
    <CardHeader
      className={'card-header text-black pointer-cursor p-10'}
      style={{ position: 'relative' }}
    >
      <div className="d-flex justify-content-between align-items-center">
        {props.form.values.financialBudgetCompanies[props.itemIndex]
          .companyId ? (
          <div>
            {props.getCompanyName(
              props.form.values.financialBudgetCompanies[props.itemIndex]
                .companyId
            ) || 'new'}
          </div>
        ) : (
          'new'
        )}

        <div
          style={{
            position: 'absolute',
            left: '0',
            top: '0',
            right: '0',
            bottom: '0',
            zIndex: '10',
          }}
          onClick={props.onClick}
        />

        {!props.isEditMainForm && (
          <button
            type="button"
            className="btn btn-danger btn-sm m-r-2"
            onClick={props.setConfirmDeleteToOpposite}
            style={{ zIndex: '11' }}
          >
            Remove
          </button>
        )}
      </div>
    </CardHeader>
    <Collapse isOpen={props.isOpen}>
      <CardBody>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">Company Name</label>
          <div className="col-md-9">
            <Field
              name={`${props.name}[${props.itemIndex}].companyId`}
              component={Select}
              type="text"
              className="width-250"
              classNameReadOnly="width-300"
              placeholder="Company Name"
              readOnly={props.isEditMainForm}
              options={props.configs}
              disabled
            />
          </div>
        </div>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">TCA</label>
          <div className="col-md-9">
            <Field
              name={`${props.name}[${props.itemIndex}].tca`}
              component={CurrencyInput}
              className="form-control width-250 text-black"
              classNameReadOnly="width-300 text-black"
              placeholder="TCA"
              readOnly={props.isEditMainForm}
            />
          </div>
        </div>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">TCR</label>
          <div className="col-md-9">
            <Field
              name={`${props.name}[${props.itemIndex}].tcr`}
              component={CurrencyInput}
              className="form-control width-250 text-black"
              classNameReadOnly="width-300 text-black"
              placeholder="TCR"
              readOnly={props.isEditMainForm}
            />
          </div>
        </div>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">IL</label>
          <div className="col-md-9">
            <Field
              name={`${props.name}[${props.itemIndex}].il`}
              component={CurrencyInput}
              className="form-control width-250 text-black"
              classNameReadOnly="width-300 text-black"
              placeholder="IL"
              readOnly={props.isEditMainForm}
            />
          </div>
        </div>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">TL</label>
          <div className="col-md-9">
            <Field
              name={`${props.name}[${props.itemIndex}].tl`}
              component={CurrencyInput}
              className="form-control width-250 text-black"
              classNameReadOnly="width-300 text-black"
              placeholder="TL"
              readOnly={props.isEditMainForm}
            />
          </div>
        </div>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">CAR SCORE</label>
          <div className="col-md-9">
            <Field
              name={`${props.name}[${props.itemIndex}].carScore`}
              component={Input}
              className="form-control width-250 text-black"
              classNameReadOnly="width-300 text-black"
              placeholder="CAR SCORE"
              readOnly={props.isEditMainForm}
              disabled
            />
          </div>
        </div>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">ILR SCORE</label>
          <div className="col-md-9">
            <Field
              name={`${props.name}[${props.itemIndex}].ilrScore`}
              component={Input}
              className="form-control width-250 text-black"
              classNameReadOnly="width-300 text-black"
              placeholder="ILR SCORE"
              readOnly={props.isEditMainForm}
              disabled
            />
          </div>
        </div>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">INSURER SCORE</label>
          <div className="col-md-9">
            <Field
              name={`${props.name}[${props.itemIndex}].insurerScore`}
              component={Input}
              className="form-control width-250 text-black"
              classNameReadOnly="width-300 text-black"
              placeholder="INSURER SCORE"
              readOnly={props.isEditMainForm}
              disabled
            />
          </div>
        </div>
      </CardBody>
    </Collapse>
    {props.modalDeleteIsOpen && (
      <SweetAlert
        showCancel
        confirmBtnText="Confirm"
        cancelBtnBsStyle="danger"
        confirmBtnBsStyle="success"
        title=""
        onConfirm={props.onClickDeleteCompany(props.itemIndex)}
        onCancel={props.setConfirmDeleteToOpposite}
      >
        ต้องการลบใช่หรือไม่ ?
      </SweetAlert>
    )}
  </Card>
)

export const CalculateInsurerScoreData = (tca, tcr, il, tl, wCar) => {
  const carFactor = car => {
    const carPecent = car * 100
    if (carPecent > 400) {
      return 5.0
    } else if (carPecent > 350) {
      return 4.5
    } else if (carPecent > 300) {
      return 4.0
    } else if (carPecent > 250) {
      return 3.5
    } else if (carPecent > 200) {
      return 3.0
    } else if (carPecent > 140) {
      return 2.5
    } else if (carPecent > 120) {
      return 2.0
    } else if (carPecent > 100) {
      return 1.5
    } else {
      return 1.0
    }
  }
  const sizeFactor = tca => {
    if (tca >= 10000) {
      return 1.0
    } else if (tca >= 1000) {
      return 0.9
    } else {
      return 0.8
    }
  }

  const carScore = (carFactor(tca / tcr) * sizeFactor(tca)).toFixed(2)
  const ilrScore = (((il / tl) * 100) / 20).toFixed(2)

  const insuererScore = ((carScore * wCar + ilrScore * (1 - wCar)) * 2).toFixed(
    2
  )

  return {
    carScore: carScore,
    ilrScore: ilrScore,
    insurerScore: insuererScore,
  }
}

const enhancer = compose(
  withHooks(props => {
    const [isOpen, setIsOpen] = useState(false)
    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)

    const setConfirmDeleteToOpposite = useCallback(() => {
      setModalDeleteIsOpen(!modalDeleteIsOpen)
    }, [modalDeleteIsOpen])

    const onClick = useCallback(() => {
      setIsOpen(!isOpen)
    }, [isOpen])

    const getCompanyName = companyId => {
      return props.configs.find(element => element.value === companyId).label
    }

    useEffect(() => {
      const weight = props.values.weightCar
      const datas = CalculateInsurerScoreData(
        props.item.tca,
        props.item.tcr,
        props.item.il,
        props.item.tl,
        weight
      )

      props.setFieldValue(
        `${props.name}[${props.itemIndex}].carScore`,
        datas.carScore
      )
      props.setFieldValue(
        `${props.name}[${props.itemIndex}].ilrScore`,
        datas.ilrScore
      )
      props.setFieldValue(
        `${props.name}[${props.itemIndex}].insurerScore`,
        datas.insurerScore
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      props.item.tca,
      props.item.tcr,
      props.item.il,
      props.item.tl,
      props.values.weightCar,
    ])
    const onClickDeleteCompany = index => () => {
      props.remove(index)
      setModalDeleteIsOpen(false)
    }

    return {
      isOpen,
      onClick,
      getCompanyName,
      setConfirmDeleteToOpposite,
      modalDeleteIsOpen,
      onClickDeleteCompany,
    }
  })
)

export default enhancer(CollapseFinancialBudgetCompany)
