import { useState, useEffect, useCallback, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../../enhancers/index.js'
import { withFormik } from 'formik'
import { get, camelCase } from 'lodash'
import { Products } from '../show/index'

// const validate = values => {
//   const errors = {}

//   if (!values.maleRegister) {
//     errors.maleRegister = 'reqiured'
//   }

//   if (!values.femaleRegister) {
//     errors.femaleRegister = 'reqiured'
//   }

//   const benefitValidates = []
//   values.benefit &&
//     values.benefit.forEach((item, itemIndex) => {
//       benefitValidates.push({})
//       const benefitDetailValidate = []
//       item.benefitDetails &&
//         item.benefitDetails.forEach((detail, detailIndex) => {
//           benefitDetailValidate.push({})
//           //ดักเงื่อนไขกรณีที่ deatail เป็น undefined
//           if (detail) {
//             // ดักเงื่อนไขกรณีที่ detail.gender, values.maleRegister, values.femaleRegister, detail.startType, detail.startPeriod เป็น undefined
//             if (
//               detail.gender &&
//               values.maleRegister &&
//               values.femaleRegister &&
//               detail.startType &&
//               detail.startPeriod &&
//               values.protectPeriodType &&
//               values.protectPeriodValue
//             ) {
//               //เงื่อนไขในกรณีที่เลือก gender เป็น all จึงต้องหาค่าที่น้อยที่สุดของอายุรับสมัครชายหญิงที่สุดและต้องค่าที่มากที่สุดของอายุรับสมัครชายหญิงที่สุด
//               if (detail.gender === 'all') {
//                 const minAge = Math.min(
//                   values.maleRegister[0],
//                   values.femaleRegister[0]
//                 )
//                 let maxAge = Math.min(
//                   values.maleRegister[1],
//                   values.femaleRegister[1]
//                 )

//                 if (values.protectPeriodType === 'round') {
//                   if (detail.endType === 'age') {
//                     maxAge =
//                       parseInt(maxAge) + parseInt(values.protectPeriodValue)
//                   } else if (detail.endType === 'round') {
//                     maxAge = values.protectPeriodValue
//                   }
//                 } else if (values.protectPeriodType === 'age') {
//                   if (detail.endType === 'age') {
//                     maxAge = values.protectPeriodValue
//                   } else if (detail.endType === 'round') {
//                     maxAge =
//                       parseInt(maxAge) + parseInt(values.protectPeriodValue)
//                   }
//                 }

//                 if (
//                   parseInt(detail.startPeriod) < parseInt(minAge) ||
//                   parseInt(detail.startPeriod) > parseInt(detail.endPeriod) ||
//                   parseInt(detail.startPeriod) > parseInt(maxAge)
//                 ) {
//                   benefitDetailValidate[detailIndex].startPeriod = 'isValid'
//                 }

//                 if (
//                   parseInt(detail.endPeriod) > parseInt(maxAge) ||
//                   parseInt(detail.startPeriod) > parseInt(detail.endPeriod) ||
//                   parseInt(detail.endPeriod) < parseInt(minAge)
//                 ) {
//                   benefitDetailValidate[detailIndex].endPeriod = 'isValid'
//                 }
//                 //เงื่อนไขกรณีที่เพศเป็น ผู้ชาย
//               } else if (detail.gender === 'male') {
//                 const minAge = values.maleRegister[0]

//                 let maxAge = values.maleRegister[1]

//                 if (values.protectPeriodType === 'round') {
//                   if (detail.endType === 'age') {
//                     maxAge =
//                       parseInt(maxAge) + parseInt(values.protectPeriodValue)
//                   } else if (detail.endType === 'round') {
//                     maxAge = values.protectPeriodValue
//                   }
//                 } else if (values.protectPeriodType === 'age') {
//                   if (detail.endType === 'age') {
//                     maxAge = values.protectPeriodValue
//                   } else if (detail.endType === 'round') {
//                     maxAge =
//                       parseInt(maxAge) + parseInt(values.protectPeriodValue)
//                   }
//                 }

//                 if (
//                   parseInt(detail.startPeriod) < parseInt(minAge) ||
//                   parseInt(detail.startPeriod) > parseInt(detail.endPeriod) ||
//                   parseInt(detail.startPeriod) > parseInt(maxAge)
//                 ) {
//                   benefitDetailValidate[detailIndex].startPeriod = 'isValid'
//                 }

//                 if (
//                   parseInt(detail.endPeriod) > parseInt(maxAge) ||
//                   parseInt(detail.startPeriod) > parseInt(detail.endPeriod) ||
//                   parseInt(detail.endPeriod) < parseInt(minAge)
//                 ) {
//                   benefitDetailValidate[detailIndex].endPeriod = 'isValid'
//                 }
//                 //เงื่อนไขกรณีที่เพศเป็น ผู้หญิง
//               } else if (detail.gender === 'female') {
//                 const minAge = values.femaleRegister[0]

//                 let maxAge = values.femaleRegister[1]

//                 if (values.protectPeriodType === 'round') {
//                   if (detail.endType === 'age') {
//                     maxAge =
//                       parseInt(maxAge) + parseInt(values.protectPeriodValue)
//                   } else if (detail.endType === 'round') {
//                     maxAge = values.protectPeriodValue
//                   }
//                 } else if (values.protectPeriodType === 'age') {
//                   if (detail.endType === 'age') {
//                     maxAge = values.protectPeriodValue
//                   } else if (detail.endType === 'round') {
//                     maxAge =
//                       parseInt(maxAge) + parseInt(values.protectPeriodValue)
//                   }
//                 }

//                 if (
//                   parseInt(detail.startPeriod) < parseInt(minAge) ||
//                   parseInt(detail.startPeriod) > parseInt(detail.endPeriod) ||
//                   parseInt(detail.startPeriod) > parseInt(maxAge)
//                 ) {
//                   benefitDetailValidate[detailIndex].startPeriod = 'isValid'
//                 }

//                 if (
//                   parseInt(detail.endPeriod) > parseInt(maxAge) ||
//                   parseInt(detail.startPeriod) > parseInt(detail.endPeriod) ||
//                   parseInt(detail.endPeriod) < parseInt(minAge)
//                 ) {
//                   benefitDetailValidate[detailIndex].endPeriod = 'isValid'
//                 }
//               }
//             }
//           }
//         })

//       if (benefitDetailValidate.length > 0) {
//         benefitValidates[itemIndex].benefitDetails = benefitDetailValidate
//       }
//     })

//   const stepValidates = []
//   values.steps &&
//     values.steps.forEach((item, itemIndex) => {
//       stepValidates.push({})
//       if (item) {
//         if (
//           item.min &&
//           item.max &&
//           values.maxSumInsured &&
//           values.minSumInsured
//         ) {
//           if (
//             parseInt(item.min) < parseInt(values.minSumInsured) ||
//             parseInt(item.min) > parseInt(item.max) ||
//             parseInt(item.min) > parseInt(values.maxSumInsured)
//           ) {
//             stepValidates[itemIndex].min = 'isValid'
//           }

//           if (
//             parseInt(item.max) < parseInt(values.minSumInsured) ||
//             parseInt(item.min) > parseInt(item.max) ||
//             parseInt(item.max) > parseInt(values.maxSumInsured)
//           ) {
//             stepValidates[itemIndex].max = 'isValid'
//           }
//         }
//       }
//     })

//   if (!values.benefit || values.benefit.length === 0) {
//     errors.benefitValidate = 'required'
//   }

//   if (!values.premiumMasters || values.premiumMasters.length === 0) {
//     errors.premiumValidate = 'required'
//   }

//   if (benefitValidates.length > 0) {
//     errors.benefit = benefitValidates
//   }

//   if (stepValidates.length > 0) {
//     errors.steps = stepValidates
//   }

//   return errors
// }

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    create: stores.product.create,
    fetchConfig: stores.product.fetchConfig,
    configs: stores.product.configs,
  })),
  withFormik({
    displayName: 'newLifeProduct',
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, props) => {
      values.premiumMasters &&
        values.premiumMasters.forEach(
          (premiumMastersItem, premiumMastersIndex) => {
            const newPeriods = []

            Object.keys(premiumMastersItem.periods).forEach(item => {
              const arr = []
              premiumMastersItem.periods[item].premiumDetails &&
                premiumMastersItem.periods[item].premiumDetails.forEach(
                  (item, index) => {
                    if (item) {
                      const newItem = {
                        ...item,
                        age: index,
                      }
                      arr.push(newItem)
                    }
                  }
                )
              newPeriods.push({
                period: premiumMastersItem.periods[item].period,
                premiumOfSumInsured:
                  premiumMastersItem.periods[item].premiumOfSumInsured,
                premiumDetails: arr,
                discounts: premiumMastersItem.periods[item].discounts,
              })
            })

            values.premiumMasters[premiumMastersIndex].periods = newPeriods
          }
        )

      let newBenefit = {}
      get(values, 'benefit', []).forEach(benefit => {
        if (benefit.type === 'complete_the_contract')
          newBenefit = {
            ...newBenefit,
            [camelCase(benefit.type)]: {
              ...benefit,
            },
          }
        else
          newBenefit = {
            ...newBenefit,
            [camelCase(benefit.type)]: {
              [`${camelCase(benefit.type)}Rules`]: benefit.benefitDetails,
            },
          }
      })

      let customStatus = ''
      if (values.status) {
        customStatus = 'online'
      } else {
        customStatus = 'offline'
      }

      await props.props.create({
        ...values,
        maleMinAge: values.maleRegister && values.maleRegister[0],
        maleMaxAge: values.maleRegister && values.maleRegister[1],
        femaleMinAge: values.femaleRegister && values.femaleRegister[0],
        femaleMaxAge: values.femaleRegister && values.femaleRegister[1],
        status: customStatus,
        pensionsStartAge: values.pensions && values.pensions[0],
        pensionsEndAge: values.pensions && values.pensions[1],
        benefit: newBenefit,
      })

      props.props.history.push('/products/life')
    },
    mapPropsToValues: props => {
      return {}
    },
    enableReinitialize: true,
  }),
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState('1')
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    useEffect(() => {
      props.fetchConfig()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (queryString.parse(props.location.search).company_id) {
        props.setFieldValue(
          'companyId',
          queryString.parse(props.location.search).company_id
        )
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialValues])

    const onClickToggleTab = index => () => {
      setActiveTab(index)
    }

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])

    return {
      loading,
      setLoading,
      readOnly: false,
      isNew: true,
      textHeader: 'New Page',
      activeTab,
      onClickToggleTab,
      isCompany: queryString.parse(props.location.search).company_id,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      buttonSubmit,
      isHealth: false,
    }
  })
)

export default enhancer(Products)
