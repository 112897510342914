import React, { useState, useCallback, useEffect } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import { FastField } from 'formik'
import {
  Select,
  CurrencyInput,
  InputMaxMin,
} from '../../../../../components/formikComponent'

const RoundPremiumComponent = props => (
  <div className="d-flex m-b-20" key={props.field}>
    <div className="text-center m-r-10" style={{ width: '16%' }}>
      <FastField
        name={`${props.name}.gender`}
        className="text-black-darker"
        component={Select}
        options={
          props.configs.paProduct.paPremiumMaster.period.paPremiumDetail.options
        }
        isClearable={false}
      />
    </div>
    <div className="text-center m-r-10" style={{ width: '8%' }}>
      <FastField
        name={`${props.name}.startAge`}
        component={InputMaxMin}
        style={{ height: '38px' }}
        className="form-control"
        classNameReadOnly="f-s-15 text-center"
        placeholder="ตั้งแต่ (วัน)"
        readOnly={props.isEdit}
        min={0}
        max={99}
      />
    </div>
    <div className="text-center m-r-10" style={{ width: '8%' }}>
      <FastField
        name={`${props.name}.endAge`}
        component={InputMaxMin}
        style={{ height: '38px' }}
        className="form-control"
        classNameReadOnly="f-s-15 text-center"
        placeholder="จนถึง (วัน)"
        readOnly={props.isEdit}
        min={0}
        max={99}
      />
    </div>
    <div className="text-center m-r-10" style={{ width: '17%' }}>
      <FastField
        name={`${props.name}.premium`}
        component={CurrencyInput}
        style={{ height: '38px' }}
        className="form-control"
        classNameReadOnly="f-s-15 text-center"
        placeholder="เบี้ย (บาท)"
        readOnly={props.isEdit}
      />
    </div>
    <div className="text-center m-r-10" style={{ width: '17%' }}>
      <FastField
        name={`${props.name}.stamp`}
        component={CurrencyInput}
        style={{ height: '38px' }}
        className="form-control"
        classNameReadOnly="f-s-15 text-center"
        placeholder="แสตมป์ (บาท)"
        readOnly={props.isEdit}
      />
    </div>
    <div className="text-center m-r-10" style={{ width: '17%' }}>
      <FastField
        name={`${props.name}.tax`}
        component={CurrencyInput}
        style={{ height: '38px' }}
        className="form-control"
        classNameReadOnly="f-s-15 text-center"
        placeholder="ภาษี (บาท)"
        readOnly={props.isEdit}
      />
    </div>
    <div className="text-center m-r-10" style={{ width: '17%' }}>
      <FastField
        name={`${props.name}.premiumCf`}
        component={CurrencyInput}
        style={{ height: '38px' }}
        className="form-control"
        classNameReadOnly="f-s-15 text-center"
        placeholder="เบี้ยสุทธิ (บาท)"
        readOnly={props.isEdit}
      />
    </div>
    <div
      className="text-center d-flex align-items-center"
      style={{ width: '4%' }}
    >
      {!props.isEdit && (
        <button
          type="button"
          className="btn btn-danger btn-icon"
          onClick={props.onDelete}
        >
          <i className="ion-md-trash" />
        </button>
      )}
    </div>
  </div>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)

    const onDelete = useCallback(() => {
      props.remove(props.index)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.index])

    useEffect(() => {
      const { premium, stamp, tax } = props.values
      const premiumCf =
        parseFloat(premium || 0) + parseFloat(stamp || 0) + parseFloat(tax || 0)

      props.setFieldValue(`${props.name}.premiumCf`, premiumCf)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.values.premium, props.values.stamp, props.values.tax])

    const copyPaste = useCallback(e => {
      let valueTable
      valueTable = e.target.value.split(' ')

      let nextValue = []
      let newString = ''
      valueTable.forEach((item, index) => {
        newString = newString + item
        if (index % 3 === 2) {
          newString = newString.replace('-', '\t')
          nextValue.push(newString)
          newString = ''
        }
      })

      if (valueTable.length <= 1) {
        return
      }

      const premiumCf = []
      nextValue.forEach(item => {
        premiumCf.push(item.split('\t')[5])
      })

      nextValue = nextValue.map(item => item.split('\t'))
      const lastValue = []

      e.preventDefault()

      for (let index = 0; index < premiumCf.length; index++) {
        const first = premiumCf.indexOf(premiumCf[index])
        const last = premiumCf.lastIndexOf(premiumCf[index])
        lastValue.push(nextValue[index])

        if (first !== last) {
          lastValue[lastValue.length - 1][0] = nextValue[first][0]
          lastValue[lastValue.length - 1][1] = nextValue[last][0]
          index = last
        }
      }

      lastValue.forEach(item => {
        props.fields.push({
          startAge: item[0],
          endAge: item[1],
          premium: parseFloat(Number(item[2].replace(/[^0-9.-]+/g, ''))),
          stamp: parseFloat(Number(item[3].replace(/[^0-9.-]+/g, ''))),
          tax: parseFloat(Number(item[4].replace(/[^0-9.-]+/g, ''))),
          premiumCf: parseFloat(Number(item[5].replace(/[^0-9.-]+/g, ''))),
        })
      })
      props.fields.remove(0)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
      loading,
      setLoading,
      onDelete,
      // recalculatePremiumNet,
      copyPaste,
    }
  })
)

export default enhancer(RoundPremiumComponent)
