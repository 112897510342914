import React, { useState, useCallback } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import { Select } from '../../../../../components/formikComponent/index'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import { Field, FieldArray } from 'formik'
import classnames from 'classnames'
import TablePage from './TablePage'
import AddPeriod from './AddPeriod'
import SweetAlert from 'react-bootstrap-sweetalert'

const TabComponent = props => (
  <>
    <Nav tabs>
      {props.values.paPeriods &&
        props.values.paPeriods.map((field, index) => (
          <NavItem key={`${props.name}[${index}]`}>
            <NavLink
              className={classnames({
                active: props.activeTab === index,
                [classnames]: 'bg-grey',
              })}
              onClick={props.toggleTab(index)}
            >
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Field
                  name={`${props.name}[${index}].period`}
                  component={Select}
                  type="text"
                  className="width-150"
                  readOnlyWidth="150px"
                  readOnlyHeight="29px"
                  readOnlyfontSize="12px"
                  options={
                    (props.configs.paProduct &&
                      props.configs.paProduct.paPremiumMaster.period.period
                        .options) ||
                    []
                  }
                  readOnly
                />

                <button
                  type="button"
                  onClick={props.onCloseAndOpenDelete}
                  className="btn btn-danger btn-icon btn-sm m-r-2"
                >
                  <i className="ion-md-trash" />
                </button>
              </div>
            </NavLink>
          </NavItem>
        ))}

      <AddPeriod
        open={props.openModalAdd}
        setOpen={props.onClickAdd}
        fields={(props.values.paPeriods && props.values.paPeriods) || []}
        form={`${props.name}`}
        setActiveTab={props.setActiveTab}
        push={props.push}
        insert={props.insert}
      />
    </Nav>
    {(props.values.paPeriods && props.values.paPeriods.length) > 0 && (
      <TabContent activeTab={props.activeTab}>
        {props.values.paPeriods &&
          props.values.paPeriods.map((field, index) => (
            <TabPane tabId={index} key={`${props.name}[${index}]Tab`}>
              <FieldArray
                name={`${props.name}[${index}].paPremiumDetails`}
                render={fieldArrayprops => (
                  <TablePage
                    {...fieldArrayprops}
                    premiumMasterIndex={props.index}
                    periodsIndex={index}
                    values={field}
                    configs={props.configs}
                  />
                )}
              />
              {props.confirmDelete && (
                <SweetAlert
                  showCancel
                  confirmBtnText="Continue"
                  confirmBtnBsStyle="primary"
                  cancelBtnBsStyle="default"
                  title="Are you sure?"
                  onConfirm={props.onDelete(index)}
                  onCancel={props.onCloseAndOpenDelete}
                >
                  ต้องการลบใช่หรือไม่ ?
                </SweetAlert>
              )}
            </TabPane>
          ))}
      </TabContent>
    )}
  </>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const [openModalAdd, setOpenModalAdd] = useState(false)
    const [confirmDelete, setconFirmDelete] = useState(false)

    const toggleTab = useCallback(
      tab => () => {
        if (activeTab !== tab) {
          setActiveTab(tab)
        }
      },
      [activeTab]
    )

    const onClickAdd = useCallback(() => {
      setOpenModalAdd(!openModalAdd)
    }, [openModalAdd])

    const onCloseAndOpenDelete = useCallback(() => {
      setconFirmDelete(!confirmDelete)
    }, [confirmDelete])

    const onDelete = useCallback(
      index => () => {
        props.remove(index)
        if (index - 1 < 0) {
          setActiveTab(index)
        } else {
          setActiveTab(index - 1)
        }
        setconFirmDelete(false)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    return {
      loading,
      setLoading,
      toggleTab,
      activeTab,
      setActiveTab,
      onClickAdd,
      openModalAdd,
      onCloseAndOpenDelete,
      onDelete,
      confirmDelete,
    }
  })
)

export default enhancer(TabComponent)
