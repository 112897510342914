import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
// import { Panel, PanelHeader } from '../../../../components/panel/panel.jsx'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../../enhancers/index.js'
// import ReactTable from 'react-table'
import UpdateStatus from '../../life/index/updateStatus'
import { Products } from '../../life/index'
import 'react-table/react-table.css'
import { reduxForm } from 'redux-form'

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    fetch: stores.paProductStore.fetch,
    all: stores.paProductStore.all,
    updateStatusPublish: stores.paProductStore.updateStatusPublish,
    updateStatusUnpublished: stores.paProductStore.updateStatusUnpublished,
  })),
  reduxForm({
    form: 'updateStatus',
    enableReinitialize: true,
  }),
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const tableColumns = [
      {
        Header: 'Name',
        accessor: 'productName',
      },
      {
        Header: 'ความคุ้มครอง',
        accessor: 'maxMaleAge',
        Cell: row => (
          <div>
            <div className="m-b-10">
              {row.row._original.paymentPeriodType === 'round'
                ? 'ระยะเวลาจ่ายเบี้ย'
                : 'จ่ายเบี้ยจนถึงอายุ'}{' '}
              {row.row._original.paymentPeriodValue} ปี
            </div>
            <div>
              {row.row._original.protectPeriodType === 'round'
                ? 'ระยะเวลาคุ้มครอง'
                : 'คุ้มครองจนถึงอายุ'}{' '}
              {row.row._original.protectPeriodValue} ปี
            </div>
          </div>
        ),
      },
      {
        Header: 'อายุรับสมัคร',
        accessor: 'maxMaleAge',
        Cell: row => (
          <div>
            {Math.min(
              row.row._original.maleMinAgeYear,
              row.row._original.femaleMinAgeYear
            )}{' '}
            -{' '}
            {Math.max(
              row.row._original.maleMaxAgeYear,
              row.row._original.femaleMaxAgeYear
            )}
          </div>
        ),
      },
      {
        Header: 'รายได้ขั้นต่ำ',
        accessor: 'minIncome',
        Cell: row =>
          new Intl.NumberFormat().format(row.row._original.minIncome),
      },
      {
        Header: 'Status',

        Cell: row => (
          <UpdateStatus
            index={row.index}
            id={row.row._original.id}
            initialValues={row.row._original.status === 'online'}
            updateStatusPublish={props.updateStatusPublish}
            updateStatusUnpublished={props.updateStatusUnpublished}
          />
        ),
      },
    ]

    useEffect(() => {
      props.fetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onNew = () => {
      props.history.push('/products/pa/new')
    }

    const onRowClick = (state, rowInfo, column) => {
      return {
        onClick: e => {
          if (column.Header !== 'Status') {
            props.history.push(`/products/pa/${rowInfo.original.id}`)
          }
        },
      }
    }
    return {
      loading,
      setLoading,
      onNew,
      onRowClick,
      tableColumns,
      textHeader: 'PA Product',
    }
  })
)

export default enhancer(Products)
