import { useState, useEffect, useCallback, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../../enhancers/index.js'
import { Products } from '../show/index'
import { withFormik } from 'formik'
import { get } from 'lodash'
import { validate } from '../show/index'

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    create: stores.paProductStore.create,
    fetchConfig: stores.paProductStore.fetchConfig,
    configs: stores.paProductStore.configs,
  })),
  withFormik({
    displayName: 'paProductNew',
    enableReinitialize: true,
    validationSchema: validate,
    validateOnBlur: false,
    validateOnChange: false,
    mapPropsToValues: props => {
      const configs = get(props, 'configs.paProduct', [])
      const newInitailฺBenefit = {}
      configs.paBenefit &&
        Object.keys(configs.paBenefit).forEach(key => {
          const newProtech = []
          configs.paBenefit[key].type.options.forEach(item => {
            newProtech.push({ type: item.value, formula: 'none' })
          })
          newInitailฺBenefit[`${key}s`] = newProtech
        })

      return { paBenefits: [{ ...newInitailฺBenefit }], status: false }
    },
    handleSubmit: async (values, props) => {
      let customStatus = ''
      if (values.status) {
        customStatus = 'online'
      } else {
        customStatus = 'offline'
      }
      await props.props.create({
        ...values,
        status: customStatus,
      })

      props.props.history.push('/products/pa')
    },
  }),
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState('1')
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    useEffect(() => {
      props.fetchConfig()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickToggleTab = useCallback(
      index => () => {
        setActiveTab(index)
      },
      []
    )

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])

    return {
      loading,
      setLoading,
      readOnly: false,
      isNew: true,
      textHeader: 'New Page',
      activeTab,
      isCompany: queryString.parse(props.location.search).company_id,
      onClickToggleTab,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      buttonSubmit,
    }
  })
)

export default enhancer(Products)
