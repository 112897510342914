import { useState, useEffect, useCallback, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../../enhancers/index.js'
import queryString from 'query-string'
import { get, range, cloneDeep } from 'lodash'
import { Products } from '../../life/show/index'
import { withFormik } from 'formik'

// const validate = values => {
//   const errors = {}

//   if (!values.maleRegister) {
//     errors.maleRegister = 'reqiured'
//   }

//   if (!values.femaleRegister) {
//     errors.femaleRegister = 'reqiured'
//   }

//   const stepValidates = []
//   values.steps &&
//     values.steps.forEach((item, itemIndex) => {
//       stepValidates.push({})
//       if (item) {
//         if (
//           item.min &&
//           item.max &&
//           values.maxSumInsured &&
//           values.minSumInsured
//         ) {
//           if (
//             parseInt(item.min) < parseInt(values.minSumInsured) ||
//             parseInt(item.min) > parseInt(item.max) ||
//             parseInt(item.min) > parseInt(values.maxSumInsured)
//           ) {
//             stepValidates[itemIndex].min = 'isValid'
//           }

//           if (
//             parseInt(item.max) < parseInt(values.minSumInsured) ||
//             parseInt(item.min) > parseInt(item.max) ||
//             parseInt(item.max) > parseInt(values.maxSumInsured)
//           ) {
//             stepValidates[itemIndex].max = 'isValid'
//           }
//         }
//       }
//     })

//   if (!values.premiumMasters || values.premiumMasters.length === 0) {
//     errors.premiumValidate = 'required'
//   }

//   if (stepValidates.length > 0) {
//     errors.steps = stepValidates
//   }
//   return errors
// }

const enhancer = compose(
  withRouter,
  withStores(stores => {
    return {
      fetchById: stores.healthProductStore.fetchById,
      clearAll: stores.healthProductStore.clearAll,
      current: stores.healthProductStore.current,
      update: stores.healthProductStore.update,
      delete: stores.healthProductStore.delete,
      configs: stores.healthProductStore.configs,
      fetchConfig: stores.healthProductStore.fetchConfig,
      clearData: stores.healthProductStore.clearData,
      updateStatusPublish: stores.healthProductStore.updateStatusPublishDetail,
      updateStatusUnpublished:
        stores.healthProductStore.updateStatusUnpublishedDetail,
    }
  }),
  withFormik({
    displayName: 'editLifeProduct',
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, props) => {
      values.premiumMasters &&
        values.premiumMasters.forEach(
          (premiumMastersItem, premiumMastersIndex) => {
            const newPeriods = []

            Object.keys(premiumMastersItem.periods).forEach(item => {
              const arr = []
              premiumMastersItem.periods[item].premiumDetails &&
                premiumMastersItem.periods[item].premiumDetails.forEach(
                  (item, index) => {
                    if (item) {
                      const newItem = {
                        ...item,
                        age: index,
                      }
                      arr.push(newItem)
                    }
                  }
                )
              newPeriods.push({
                period: premiumMastersItem.periods[item].period,
                premiumOfSumInsured:
                  premiumMastersItem.periods[item].premiumOfSumInsured,
                premiumDetails: arr,
                discounts: premiumMastersItem.periods[item].discounts,
              })
            })

            values.premiumMasters[premiumMastersIndex].periods = newPeriods
          }
        )

      let customStatus = ''
      if (values.status) {
        customStatus = 'online'
      } else {
        customStatus = 'offline'
      }

      props.props.update(props.props.match.params.id, {
        ...values,
        maleMinAge: values.maleRegister && values.maleRegister[0],
        maleMaxAge: values.maleRegister && values.maleRegister[1],
        femaleMinAge: values.femaleRegister && values.femaleRegister[0],
        femaleMaxAge: values.femaleRegister && values.femaleRegister[1],
        status: customStatus,
        pensionsStartAge: values.pensions && values.pensions[0],
        pensionsEndAge: values.pensions && values.pensions[1],
        // benefit: newBenefit,
      })
    },
    mapPropsToValues: props => {
      const productSetting = cloneDeep(get(props, 'current', {}))
      const configs = get(props, 'configs', {})

      get(productSetting, 'premiumMasters', []).forEach(premiumMaster => {
        premiumMaster.periods.forEach(period => {
          const premiumDetails = range(100).map(age =>
            period.premiumDetails.find(
              premiumDetail => premiumDetail.age === age
            )
          )

          period.premiumDetails = premiumDetails
        })
      })

      let hsBenefitNew = {}
      let ciBenefitNew = {}

      if (productSetting.type === 'hs') {
        Object.keys(productSetting).length > 0 &&
          configs.product &&
          Object.keys(configs.product.hsBenefit).forEach(
            (hsBenefitItem, hsBenefitItemIndex) => {
              let newArr = []
              configs.product.hsBenefit[hsBenefitItem].type.options.forEach(
                (hsBenefitDetail, hsBenefitDetailIndex) => {
                  if (productSetting.hsBenefit) {
                    if (
                      !productSetting.hsBenefit[`${hsBenefitItem}s`][
                        hsBenefitDetailIndex
                      ].type &&
                      !productSetting.hsBenefit[`${hsBenefitItem}s`][
                        hsBenefitDetailIndex
                      ].formula
                    ) {
                      newArr.push({
                        type: hsBenefitDetail.value,
                        formula: 'none',
                      })
                    } else if (
                      !productSetting.hsBenefit[`${hsBenefitItem}s`][
                        hsBenefitDetailIndex
                      ].type
                    ) {
                      newArr.push({
                        type: hsBenefitDetail.value,
                        formula:
                          productSetting.hsBenefit[`${hsBenefitItem}s`][
                            hsBenefitDetailIndex
                          ].formula,
                      })
                    } else if (
                      !productSetting.hsBenefit[`${hsBenefitItem}s`][
                        hsBenefitDetailIndex
                      ].formula
                    ) {
                      newArr.push({
                        type: hsBenefitDetail.value,
                        formula: 'none',
                      })
                    } else {
                      newArr.push({
                        type:
                          productSetting.hsBenefit[`${hsBenefitItem}s`][
                            hsBenefitDetailIndex
                          ].type,
                        formula:
                          productSetting.hsBenefit[`${hsBenefitItem}s`][
                            hsBenefitDetailIndex
                          ].formula,
                        params:
                          productSetting.hsBenefit[`${hsBenefitItem}s`][
                            hsBenefitDetailIndex
                          ].params,
                      })
                    }
                  } else {
                    newArr.push({
                      type: hsBenefitDetail.value,
                      formula: 'none',
                    })
                  }
                }
              )
              hsBenefitNew[`${hsBenefitItem}s`] = newArr
            }
          )

        configs.product &&
          Object.keys(configs.product.ciBenefit).forEach(
            (ciBenefitItem, hsBenefitItemIndex) => {
              let newArr = []
              configs.product.ciBenefit[ciBenefitItem].type.options.forEach(
                (ciBenefitDetail, hsBenefitDetailIndex) => {
                  if (ciBenefitDetail.value === 'number_specific_disease_ci') {
                    newArr.push({
                      type: ciBenefitDetail.value,
                      formula: 'number',
                    })
                  }
                  if (
                    ciBenefitDetail.value === 'beginning_benefit_ci' ||
                    ciBenefitDetail.value === 'middle_benefit_ci' ||
                    ciBenefitDetail.value === 'severe_benefit_ci' ||
                    ciBenefitDetail.value === 'benefit_specific_disease_ci'
                  ) {
                    newArr.push({
                      type: ciBenefitDetail.value,
                      formula: 'percent',
                    })
                  }
                  if (
                    ciBenefitDetail.value === 'dead' ||
                    ciBenefitDetail.value === 'after_specific_disease_ci' ||
                    ciBenefitDetail.value === 'normal_dead_benefit_ci' ||
                    ciBenefitDetail.value === 'specific_dead_benefit_ci'
                  ) {
                    newArr.push({
                      type: ciBenefitDetail.value,
                      formula: 'none',
                    })
                  }
                }
              )
              ciBenefitNew[`${ciBenefitItem}s`] = newArr
            }
          )
      }

      if (productSetting.type === 'ci') {
        Object.keys(productSetting).length > 0 &&
          configs.product &&
          Object.keys(configs.product.ciBenefit).forEach(
            (ciBenefitItem, ciBenefitItemIndex) => {
              let newArr = []
              configs.product.ciBenefit[ciBenefitItem].type.options.forEach(
                (ciBenefitDetail, ciBenefitDetailIndex) => {
                  if (
                    productSetting.ciBenefit &&
                    productSetting.ciBenefit[`${ciBenefitItem}s`].length > 0
                  ) {
                    newArr.push({
                      type:
                        productSetting.ciBenefit[`${ciBenefitItem}s`][
                          ciBenefitDetailIndex
                        ].type,
                      formula:
                        productSetting.ciBenefit[`${ciBenefitItem}s`][
                          ciBenefitDetailIndex
                        ].formula,
                      params:
                        productSetting.ciBenefit[`${ciBenefitItem}s`][
                          ciBenefitDetailIndex
                        ].params,
                    })
                  } else {
                    if (
                      ciBenefitDetail.value === 'number_specific_disease_ci'
                    ) {
                      newArr.push({
                        type: ciBenefitDetail.value,
                        formula: 'number',
                      })
                    }
                    if (
                      ciBenefitDetail.value === 'beginning_benefit_ci' ||
                      ciBenefitDetail.value === 'middle_benefit_ci' ||
                      ciBenefitDetail.value === 'severe_benefit_ci' ||
                      ciBenefitDetail.value === 'benefit_specific_disease_ci'
                    ) {
                      newArr.push({
                        type: ciBenefitDetail.value,
                        formula: 'percent',
                      })
                    }
                    if (
                      ciBenefitDetail.value === 'dead' ||
                      ciBenefitDetail.value === 'after_specific_disease_ci' ||
                      ciBenefitDetail.value === 'normal_dead_benefit_ci' ||
                      ciBenefitDetail.value === 'specific_dead_benefit_ci'
                    ) {
                      newArr.push({
                        type: ciBenefitDetail.value,
                        formula: 'none',
                      })
                    }
                  }
                }
              )
              ciBenefitNew[`${ciBenefitItem}s`] = newArr
            }
          )

        configs.product &&
          Object.keys(configs.product.hsBenefit).forEach(
            (hsBenefitItem, hsBenefitItemIndex) => {
              let newArr = []
              configs.product.hsBenefit[hsBenefitItem].type.options.forEach(
                (hsBenefitDetail, hsBenefitDetailIndex) => {
                  newArr.push({
                    type: hsBenefitDetail.value,
                    formula: 'none',
                  })
                }
              )
              hsBenefitNew[`${hsBenefitItem}s`] = newArr
            }
          )
      }

      productSetting.hsBenefit = hsBenefitNew
      productSetting.ciBenefit = ciBenefitNew

      productSetting.maleRegister = [
        productSetting.maleMinAge,
        productSetting.maleMaxAge,
      ]

      productSetting.femaleRegister = [
        productSetting.femaleMinAge,
        productSetting.femaleMaxAge,
      ]

      productSetting.pensions = [
        productSetting.pensionsStartAge,
        productSetting.pensionsEndAge,
      ]

      if (productSetting.status === 'online') {
        productSetting.status = true
      } else {
        productSetting.status = false
      }
      return {
        ...productSetting,
      }
    },
    enableReinitialize: true,
  }),
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [confirmDelete, setconFirmDelete] = useState(false)
    const [activeTab, setActiveTab] = useState('1')
    const [confirmChangeStatus, setConfirmChangeStatus] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    useEffect(() => {
      props.fetchById(props.match.params.id)
      props.fetchConfig()
      return () => {
        props.clearData()
        // destroy('editProduct')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (queryString.parse(props.location.search).company_id) {
        props.change(
          'companyId',
          queryString.parse(props.location.search).company_id
        )
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialValues])

    const onUpdateStatus = useCallback(() => {
      if (props.status) {
        props.updateStatusPublish(props.match.params.id, 'online')
      } else {
        props.updateStatusUnpublished(props.match.params.id, 'offline')
      }

      setConfirmChangeStatus(!confirmChangeStatus)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmChangeStatus, props.status])

    const onStatusCancel = useCallback(() => {
      props.setFieldValue('status', props.initialValues.status)
      setConfirmChangeStatus(!confirmChangeStatus)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmChangeStatus])

    const onClickStatus = useCallback(() => {
      setConfirmChangeStatus(!confirmChangeStatus)
    }, [confirmChangeStatus])

    const onClickIsEdit = useCallback(() => {
      props.setFieldValue('status', props.initialValues.status)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialValues])

    const onClickDelete = useCallback(() => {
      setconFirmDelete(!confirmDelete)
    }, [confirmDelete])

    const onReset = useCallback(() => {
      props.reset()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onDelete = useCallback(async () => {
      await props.delete(props.match.params.id)
      props.history.push('/products/health')

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id])

    const onClickToggleTab = useCallback(
      index => () => {
        setActiveTab(index)
      },
      []
    )

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])

    return {
      loading,
      setLoading,
      isEdit: false,
      onClickIsEdit,
      onDelete,
      confirmDelete,
      onClickDelete,
      activeTab,
      onClickToggleTab: onClickToggleTab,
      textHeader: 'Products Detail',
      isCompany: queryString.parse(props.location.search).company_id,
      onReset,
      onClickStatus,
      confirmChangeStatus,
      onUpdateStatus,
      onStatusCancel,
      isHealth: true,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      buttonSubmit,
    }
  })
)

export default enhancer(Products)
