import { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../../enhancers/index.js'
import { withFormik } from 'formik'
import { FinancialBudgetShow, validate } from '../show/index'
import { cloneDeep } from 'lodash'

const enhancer = compose(
  withRouter,
  withStores(stores => {
    return {
      create: stores.financialBudgetStore.create,
      fetchConfig: stores.financialBudgetStore.fetchConfig,
      companyConfigs: stores.financialBudgetStore.configs.companies,
      fetchScoreStarConfig: stores.scoreStarConfigStore.fetch,
      scoreStarConfigs: stores.scoreStarConfigStore.current,
    }
  }),
  withFormik({
    displayName: 'newFinancialBudgetTable',
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, props) => {
      await props.props.create({
        ...values,
      })
      props.props.history.push('/score_star/financial_budget')
    },
    mapPropsToValues: props => {
      return {}
    },
    enableReinitialize: true,
    validationSchema: validate,
  }),

  withHooks(props => {
    const [ModalIsOpen, setModalIsOpen] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    const filterCompanyConfigs = useMemo(() => {
      let options =
        props.companyConfigs && cloneDeep(props.companyConfigs.options)
      const arr_ids = (props.values.financialBudgetCompanies || []).map(
        item => item.companyId
      )

      options = (options || []).filter(item => !arr_ids.includes(item.value))

      return options
    }, [props.companyConfigs, props.values.financialBudgetCompanies])

    const onReset = useCallback(() => {
      props.resetForm()
      props.history.push('/score_star/financial_budget/')
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickOpenOrCloseModal = useCallback(() => {
      setModalIsOpen(!ModalIsOpen)
    }, [ModalIsOpen])

    useEffect(() => {
      props.fetchConfig()
      props.fetchScoreStarConfig()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])

    return {
      textHeader: 'New Financial Budget Table',
      isEdit: false,
      isNew: true,
      onReset,
      onClickOpenOrCloseModal,
      ModalIsOpen,
      setModalIsOpen,
      filterCompanyConfigs,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      buttonSubmit,
    }
  })
)

export default enhancer(FinancialBudgetShow)
