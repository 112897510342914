import React, { useState, useMemo, useCallback } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../enhancers'
import { get, uniqBy } from 'lodash'
import { useEffect } from 'react'
import Select from 'react-select'

const Table = props => (
  <div className="table-responsive m-r-10 m-l-10 m-b-20">
    <div className="table  m-b-0">
      <div>
        <div style={{ background: '#1d2226' }}>
          <div
            className="text-center text-silver-lighter height-50 f-w-700 f-s-18"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {props.textHeader}
          </div>
        </div>
      </div>
      <div>
        <div
          style={{ background: '#1d2226', display: 'flex', padding: '15px' }}
        >
          <div style={{ width: '33%', marginRight: '8px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="text-silver-lighter m-b-5">Company</div>
              <Select
                name={`${props.name}company`}
                options={get(props, 'remainingsCompanyOptions', [])}
                onChange={props.setFilterRemainingCompany}
                value={
                  (props.remainingsCompanyOptions || []).find(
                    x => x.value === props.valueCompanyFilter
                  ) || null
                }
                isClearable
              />
            </div>
          </div>
          <div style={{ width: '34%', marginRight: '8px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="text-silver-lighter m-b-5">Type</div>
              <Select
                name={`${props.name}type`}
                options={get(props, 'remainingsTypeOptions', [])}
                onChange={props.setFilterRemainingType}
                value={
                  (props.remainingsTypeOptions || []).find(
                    x => x.value === props.valueTypeFilter
                  ) || null
                }
                isClearable
              />
            </div>
          </div>
          <div style={{ width: '33%' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="text-silver-lighter m-b-5">Sub Type</div>
              <Select
                name={`${props.name}subType`}
                options={get(props, 'remainingsSubTypeOptions', [])}
                onChange={props.setFilterRemainingSubType}
                value={
                  (props.remainingsSubTypeOptions || []).find(
                    x => x.value === props.valueSubTypeFilter
                  ) || null
                }
                isClearable
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style={{ background: '#1d2226', padding: '15px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="text-silver-lighter m-b-5">Product Name</div>
            <Select
              value={
                (props.remainingsNameOptions || []).find(
                  x => x.value === props.valueNameFilter
                ) || null
              }
              name={`${props.name}product`}
              options={get(props, 'remainingsNameOptions', [])}
              onChange={props.setFilterRemainingName}
              isClearable
            />
          </div>
        </div>
      </div>
      <div>
        <div style={{ background: '#1d2226', padding: '15px 15px ' }}>
          <button
            className={`btn btn-${props.buttonStyle} width-full`}
            type="button"
            onClick={props.onClickeAddOrRemove(props.itemList)}
            disabled={props.readOnly || props.isEdit}
          >
            {props.buttonStyle === 'danger' && (
              <i className={props.iconAll}>
                {' '}
                <i className={props.iconAll}></i>{' '}
                <i className={props.iconAll}></i>
              </i>
            )}{' '}
            {props.textAll}{' '}
            {props.buttonStyle === 'success' && (
              <i className={props.iconAll}>
                {' '}
                <i className={props.iconAll}></i>{' '}
                <i className={props.iconAll}></i>
              </i>
            )}
          </button>
        </div>
      </div>
      <div style={{ height: '700px', overflow: 'auto' }}>
        {props.itemList &&
          props.itemList.map((item, index) => (
            <div key={index}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '10px 20px',
                }}
              >
                <div>
                  {(props.isNew || !props.readOnly) &&
                    props.buttonStyle === 'danger' && (
                      <button
                        type="button"
                        className={`btn btn-${props.buttonStyle} btn-icon btn-circle m-r-2`}
                        onClick={props.onClickeAddOrRemove(item)}
                      >
                        <i className={props.iconAll}></i>
                      </button>
                    )}
                </div>
                <div colSpan={2} className="text-center f-s-15">
                  {item.name}
                </div>
                <div>
                  {(props.isNew || !props.readOnly) &&
                    props.buttonStyle === 'success' && (
                      <button
                        type="button"
                        className={`btn btn-${props.buttonStyle} btn-icon btn-circle m-r-2`}
                        onClick={props.onClickeAddOrRemove(item)}
                      >
                        <i className={props.iconAll}></i>
                      </button>
                    )}
                </div>
              </div>
              <hr style={{ margin: 'unset' }} />
            </div>
          ))}
      </div>
    </div>
  </div>
)
const AssignProduct = props => (
  <div style={{ display: 'flex', justifyContent: 'center', margin: 'unset' }}>
    <Table
      textHeader="Remaining Product"
      name="remaining"
      valueCompanyFilter={props.remainingCompanyFilter}
      valueTypeFilter={props.remainingTypeFilter}
      valueSubTypeFilter={props.remainingSubTypeFilter}
      valueNameFilter={props.remainingNameFilter}
      remainingsCompanyOptions={props.remainingCompanyOptions}
      remainingsTypeOptions={props.remainingTypeOptions}
      remainingsSubTypeOptions={props.remainingSubTypeOptions}
      remainingsNameOptions={props.remainingsNameOptions}
      setFilterRemainingCompany={props.setFilterRemainingCompany}
      setFilterRemainingType={props.setFilterRemainingType}
      setFilterRemainingSubType={props.setFilterRemainingSubType}
      setFilterRemainingName={props.setFilterRemainingName}
      itemList={props.filteredRemainingItems}
      onClickeAddOrRemove={props.addItems}
      buttonStyle="success"
      readOnly={props.readOnly}
      isNew={props.isNew}
      textAll="Select All"
      iconAll="ion-md-play-circle"
    />
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      {/* <button
        class="btn btn-success width-80 "
        type="button"
        onClick={props.addItems(props.filteredRemainingItems)}
        disabled={props.readOnly || props.isEdit}
      >
        Select
      </button>
      <button
        class="btn btn-danger width-80 m-t-10"
        type="button"
        onClick={props.removeItems(props.filteredSelectedItems)}
        disabled={props.readOnly || props.isEdit}
      >
        Remove
      </button> */}
    </div>
    <Table
      textHeader="Selected Product"
      name="selected"
      itemList={props.filteredSelectedItems}
      onClickeAddOrRemove={props.removeItems}
      remainingsCompanyOptions={props.selectedCompanyOptions}
      remainingsTypeOptions={props.selectedTypeOptions}
      remainingsSubTypeOptions={props.selectedSubTypeOptions}
      remainingsNameOptions={props.selectedNameOptions}
      setFilterRemainingCompany={props.setFilterSelectedCompany}
      setFilterRemainingType={props.setFilterSelectedType}
      setFilterRemainingSubType={props.setFilterSelectedSubType}
      setFilterRemainingName={props.setFilterSelectedName}
      valueCompanyFilter={props.selectedCompanyFilter}
      valueTypeFilter={props.selectedTypeFilter}
      valueSubTypeFilter={props.selectedSubTypeFilter}
      valueNameFilter={props.selectedNameFilter}
      buttonStyle="danger"
      readOnly={props.readOnly}
      isNew={props.isNew}
      textAll="Remove All"
      iconAll="ion-md-arrow-dropleft-circle"
    />
  </div>
)

const enhancer = compose(
  withHooks(props => {
    const allItems = props.configs || []

    const selectedItems = useMemo(() => {
      const selectedItemIds = props.input.value || []
      return selectedItemIds.map(id => allItems.find(item => item.id === id.id))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.input.value])

    const remainingItems = useMemo(() => {
      const selectedItemIds = selectedItems

      return allItems.filter(
        item => selectedItemIds.findIndex(id => item.id === id.id) === -1
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allItems, selectedItems, props.input.value])

    const [remainingCompanyFilter, setRemainingCompanyFilter] = useState('')
    const [remainingTypeFilter, setRemainingTypeFilter] = useState('')
    const [remainingSubTypeFilter, setRemainingSubTypeFilter] = useState('')
    const [remainingNameFilter, setRemainingNameFilter] = useState('')

    const [selectedCompanyFilter, setSelectedCompanyFilter] = useState('')
    const [selectedTypeFilter, setSelectedTypeFilter] = useState('')
    const [selectedSubTypeFilter, setSelectedSubTypeFilter] = useState('')
    const [selectedNameFilter, setSelectedNameFilter] = useState('')

    const remainingCompanyOptions = useMemo(() => {
      const items = remainingItems
      const options = uniqBy(items, 'companyId').map(item => ({
        label: item.companyName,
        value: item.companyId,
      }))
      return [...options]
    }, [remainingItems])

    const remainingTypeOptions = useMemo(() => {
      let items = remainingItems
      if (remainingCompanyFilter) {
        items = remainingItems.filter(
          item => remainingCompanyFilter === item.companyId
        )
      }

      const options = uniqBy(items, 'productType').map(item => ({
        label: item.productType,
        value: item.productType,
      }))
      return [...options]
    }, [remainingItems, remainingCompanyFilter])

    const remainingSubTypeOptions = useMemo(() => {
      let items = remainingItems
      if (remainingCompanyFilter) {
        items = remainingItems.filter(
          item => remainingCompanyFilter === item.companyId
        )
      }
      if (remainingTypeFilter) {
        items = items.filter(item => remainingTypeFilter === item.productType)
      }

      const options = uniqBy(items, 'productSubType').map(item => ({
        label: item.productSubType,
        value: item.productSubType,
      }))
      return [...options]
    }, [remainingItems, remainingCompanyFilter, remainingTypeFilter])

    const remainingsNameOptions = useMemo(() => {
      let items = remainingItems
      if (remainingCompanyFilter) {
        items = remainingItems.filter(
          item => remainingCompanyFilter === item.companyId
        )
      }
      if (remainingTypeFilter) {
        items = items.filter(item => remainingTypeFilter === item.productType)
      }
      if (remainingSubTypeFilter) {
        items = items.filter(
          item => remainingSubTypeFilter === item.productSubType
        )
      }

      const options = items.map(item => ({
        label: item.name,
        value: item.id,
      }))
      return [...options]
    }, [
      remainingItems,
      remainingCompanyFilter,
      remainingSubTypeFilter,
      remainingTypeFilter,
    ])

    const selectedCompanyOptions = useMemo(() => {
      const options = uniqBy(selectedItems, 'companyId').map(item => ({
        label: item.companyName,
        value: item.companyId,
      }))
      return [...options]
    }, [selectedItems])

    const selectedTypeOptions = useMemo(() => {
      let items = selectedItems
      if (selectedCompanyFilter) {
        items = selectedItems.filter(
          item => selectedCompanyFilter === item.companyId
        )
      }
      const options = uniqBy(items, 'productType').map(item => ({
        label: item.productType,
        value: item.productType,
      }))
      return [...options]
    }, [selectedItems, selectedCompanyFilter])

    const selectedSubTypeOptions = useMemo(() => {
      let items = selectedItems
      if (selectedCompanyFilter) {
        items = selectedItems.filter(
          item => selectedCompanyFilter === item.companyId
        )
      }

      if (selectedTypeFilter) {
        items = items.filter(item => selectedTypeFilter === item.productType)
      }

      const options = uniqBy(items, 'productSubType').map(item => ({
        label: item.productSubType,
        value: item.productSubType,
      }))
      return [...options]
    }, [selectedItems, selectedCompanyFilter, selectedTypeFilter])

    const selectedNameOptions = useMemo(() => {
      let items = selectedItems
      if (selectedCompanyFilter) {
        items = selectedItems.filter(
          item => selectedCompanyFilter === item.companyId
        )
      }

      if (selectedTypeFilter) {
        items = items.filter(item => selectedTypeFilter === item.productType)
      }

      if (selectedSubTypeFilter) {
        items = items.filter(
          item => selectedSubTypeFilter === item.productSubType
        )
      }
      const options = items.map(item => ({
        label: item.name,
        value: item.id,
      }))
      return [...options]
    }, [
      selectedItems,
      selectedCompanyFilter,
      selectedTypeFilter,
      selectedSubTypeFilter,
    ])

    const filteredRemainingItems = useMemo(() => {
      return remainingItems.filter(item => {
        if (
          remainingCompanyFilter &&
          item.companyId !== remainingCompanyFilter
        ) {
          return false
        }
        if (remainingTypeFilter && item.productType !== remainingTypeFilter) {
          return false
        }
        if (
          remainingSubTypeFilter &&
          item.productSubType !== remainingSubTypeFilter
        ) {
          return false
        }
        if (remainingNameFilter && item.id !== remainingNameFilter) {
          return false
        }
        return true
      })
    }, [
      remainingItems,
      remainingCompanyFilter,
      remainingTypeFilter,
      remainingSubTypeFilter,
      remainingNameFilter,
    ])

    const filteredSelectedItems = useMemo(() => {
      return selectedItems.filter(item => {
        if (selectedCompanyFilter && item.companyId !== selectedCompanyFilter) {
          return false
        }
        if (selectedTypeFilter && item.productType !== selectedTypeFilter) {
          return false
        }
        if (
          selectedSubTypeFilter &&
          item.productSubType !== selectedSubTypeFilter
        ) {
          return false
        }
        if (selectedNameFilter && item.id !== selectedNameFilter) {
          return false
        }
        return true
      })
    }, [
      selectedItems,
      selectedCompanyFilter,
      selectedTypeFilter,
      selectedSubTypeFilter,
      selectedNameFilter,
    ])

    const addItems = useCallback(
      items => () => {
        let ids = items
        let nextIds = []

        if (ids instanceof Array) {
          nextIds = [...props.input.value, ...ids]
        } else {
          nextIds = [...props.input.value, ids]
        }

        props.input.onChange(nextIds)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.input.onChange, props.input.value]
    )

    const removeItems = useCallback(
      items => () => {
        let ids = items
        let nextIds = []
        if (ids instanceof Array) {
          nextIds = [...props.input.value].filter(
            id => ids.findIndex(item => item.id === id.id) === -1
          )
        } else {
          nextIds = [...props.input.value].filter(item => item.id !== ids.id)
        }

        props.input.onChange(nextIds)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.input.onChange, props.input.value]
    )

    useEffect(() => {
      setRemainingTypeFilter(null)
      setRemainingSubTypeFilter(null)
      setRemainingNameFilter(null)
    }, [remainingCompanyFilter])
    useEffect(() => {
      setRemainingSubTypeFilter(null)
      setRemainingNameFilter(null)
    }, [remainingTypeFilter])
    useEffect(() => {
      setRemainingNameFilter(null)
    }, [remainingSubTypeFilter])

    useEffect(() => {
      setSelectedTypeFilter(null)
      setSelectedSubTypeFilter(null)
      setSelectedNameFilter(null)
    }, [selectedCompanyFilter])
    useEffect(() => {
      setSelectedSubTypeFilter(null)
      setSelectedNameFilter(null)
    }, [selectedTypeFilter])
    useEffect(() => {
      setSelectedNameFilter(null)
    }, [selectedSubTypeFilter])

    const setFilterRemainingCompany = value => {
      if (value) {
        setRemainingCompanyFilter(value.value)
      } else {
        setRemainingCompanyFilter(value)
      }
    }

    const setFilterRemainingType = value => {
      if (value) {
        setRemainingTypeFilter(value.value)
      } else {
        setRemainingTypeFilter(value)
      }
    }

    const setFilterRemainingSubType = value => {
      if (value) {
        setRemainingSubTypeFilter(value.value)
      } else {
        setRemainingSubTypeFilter(value)
      }
    }

    const setFilterRemainingName = value => {
      if (value) {
        setRemainingNameFilter(value.value)
      } else {
        setRemainingNameFilter(value)
      }
    }

    const setFilterSelectedCompany = value => {
      if (value) {
        setSelectedCompanyFilter(value.value)
      } else {
        setSelectedCompanyFilter(value)
      }
    }

    const setFilterSelectedType = value => {
      if (value) {
        setSelectedTypeFilter(value.value)
      } else {
        setSelectedTypeFilter(value)
      }
    }

    const setFilterSelectedSubType = value => {
      if (value) {
        setSelectedSubTypeFilter(value.value)
      } else {
        setSelectedSubTypeFilter(value)
      }
    }

    const setFilterSelectedName = value => {
      if (value) {
        setSelectedNameFilter(value.value)
      } else {
        setSelectedNameFilter(value)
      }
    }

    return {
      filteredRemainingItems,
      filteredSelectedItems,

      remainingCompanyOptions,
      remainingTypeOptions,
      remainingSubTypeOptions,
      remainingsNameOptions,

      selectedCompanyOptions,
      selectedTypeOptions,
      selectedSubTypeOptions,
      selectedNameOptions,

      setFilterRemainingCompany,
      setFilterRemainingType,
      setFilterRemainingSubType,
      setFilterRemainingName,
      setFilterSelectedCompany,
      setFilterSelectedType,
      setFilterSelectedSubType,
      setFilterSelectedName,

      remainingCompanyFilter,
      remainingTypeFilter,
      remainingSubTypeFilter,
      remainingNameFilter,
      selectedCompanyFilter,
      selectedTypeFilter,
      selectedSubTypeFilter,
      selectedNameFilter,

      addItems,
      removeItems,
    }
  })
)
export default enhancer(AssignProduct)
