import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Panel, PanelHeader } from '../../../../components/panel/panel.jsx'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../../enhancers/index.js'
import 'react-table/react-table.css'
import { withFormik, Form, FastField } from 'formik'
import { Input } from '../../../../components/formikComponent/index.js'
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert'
import MotalityBase from './MotalityBase'
import { get } from 'lodash'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'

export const MortalityShow = props => (
  <Form onSubmit={props.handleSubmit}>
    <ol className="breadcrumb float-xl-right">
      <li className="breadcrumb-item">
        <Link to="/score_star/mortality_base">Mortality Base Table</Link>
      </li>
      <li className="breadcrumb-item active">
        {props.isNew ? 'Mortality Base Table New' : 'Mortality Base Table Edit'}
      </li>
    </ol>
    <h1 className="page-header">Mortality Base Table</h1>
    <Panel>
      <PanelHeader noButton={true}>
        <div className="d-flex justify-content-between">
          <div className="text-center p-t-10">{props.textHeader}</div>
          {props.isEdit && (
            <div>
              <button
                type="button"
                className="btn btn-success width-60 m-r-10 m-l-10"
                onClick={props.onClickIsEdit}
                style={{ justifySelf: 'flex-end' }}
              >
                Edit
              </button>
              <button
                type="button"
                className="btn btn-danger width-60"
                onClick={props.onClickDelete}
              >
                Delete
              </button>
            </div>
          )}
          {!props.isEdit && (
            <div>
              <button
                type="button"
                className="btn btn-primary width-60 m-r-10 m-l-10"
                onClick={props.setConfirmUpdateToCon}
                style={{ justifySelf: 'flex-end' }}
              >
                Save
              </button>
              {!props.isNew && (
                // <button
                //   type="button"
                //   className="btn btn-danger  width-60 "
                //   onClick={props.onReset}
                // >
                //   Cancel
                // </button>
                <button
                  type="button"
                  className="btn btn-danger width-60"
                  onClick={props.onClickDelete}
                >
                  Delete
                </button>
              )}
            </div>
          )}
        </div>
      </PanelHeader>

      <div
        className="form-group m-b-15"
        style={{ padding: '16px', alignContent: 'center' }}
      >
        <div className="form-group row m-b-15 m-l-20 width-450">
          <label className="col-sm-3 col-form-label height-40">Year</label>
          <div className="col-md-9 height-40">
            <FastField
              name="year"
              component={Input}
              type="number"
              className="form-control width-250 "
              classNameReadOnly="width-150"
              placeholder="Year"
              required
              readOnly={props.isEdit}
            />
          </div>
        </div>

        <Nav className="mb-3" pills>
          <NavItem className="btn">
            <NavLink
              className={classnames({ active: props.activeTab === '1' })}
              onClick={props.toggleTab('1')}
            >
              <span className="d-sm-none">Tab 1</span>
              <span className="d-sm-block d-none">Common</span>
            </NavLink>
          </NavItem>
          <NavItem className="btn">
            <NavLink
              className={classnames({ active: props.activeTab === '2' })}
              onClick={props.toggleTab('2')}
            >
              <span className="d-sm-none">Tab 2</span>
              <span className="d-sm-block d-none">Industry</span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={props.activeTab}>
          <TabPane tabId="1">
            <MotalityBase
              riskType="common"
              isEdit={props.isEdit}
              setFieldValue={props.setFieldValue}
            />
          </TabPane>
          <TabPane tabId="2">
            <MotalityBase
              riskType="industry"
              isEdit={props.isEdit}
              setFieldValue={props.setFieldValue}
            />
          </TabPane>
        </TabContent>
      </div>

      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title=""
          onConfirm={props.onClickconfirmUpdate}
          onCancel={props.setConfirmUpdateToCon}
        >
          ต้องการบันทึกใช่หรือไม่ ?
        </SweetAlert>
      )}

      {props.confirmDelete && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={props.onDelete}
          onCancel={props.onClickDelete}
        >
          ต้องการลบใช่หรือไม่ ?
        </SweetAlert>
      )}
    </Panel>
    <button type="submit" ref={props.buttonSubmit} hidden />
  </Form>
)

const enhancer = compose(
  withRouter,
  withStores(stores => {
    return {
      fetchById: stores.mortalityBaseStore.fetchById,
      update: stores.mortalityBaseStore.update,
      current: stores.mortalityBaseStore.current,
      delete: stores.mortalityBaseStore.delete,
      fetchConfig: stores.mortalityBaseStore.fetchConfig,
      configs: stores.mortalityBaseStore.configs,
      riskType: get(stores.mortalityBaseStore, 'configs.riskType.options', []),
      gender: get(stores.mortalityBaseStore, 'configs.gender.options', []),
      initialValues: {
        ...stores.mortalityBaseStore.current,
      },
    }
  }),
  withFormik({
    displayName: 'editMortalityBaseTable',
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, props) => {
      await props.props.update(props.props.match.params.id, values)
    },
    mapPropsToValues: props => {
      const values = props.current

      return {
        ...values,
      }
    },
    enableReinitialize: true,
  }),

  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [confirmDelete, setConFirmDelete] = useState(false)
    const [activeTab, setActiveTab] = useState('1')
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    const toggleTab = useCallback(
      () => tab => {
        if (activeTab !== tab) {
          setActiveTab(tab)
        }
      },
      [activeTab]
    )

    const onReset = () => {
      props.reset()
    }

    const onClickDelete = useCallback(() => {
      setConFirmDelete(!confirmDelete)
    }, [confirmDelete])

    const onDelete = useCallback(async () => {
      await props.delete(props.match.params.id)
      props.history.push('/score_star/mortality_base')

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id])

    const onClickIsEdit = () => {}
    useEffect(() => {
      props.fetchById(props.match.params.id)
      props.fetchConfig()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])

    return {
      loading,
      setLoading,
      textHeader: 'Edit page',
      isEdit: false,
      onClickIsEdit,
      onReset,
      onClickDelete,
      onDelete,
      confirmDelete,
      toggleTab,
      activeTab,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      buttonSubmit,
    }
  })
)

export default enhancer(MortalityShow)
