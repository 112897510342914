import React, { useState, useCallback, useEffect } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import { FastField } from 'formik'
import { Input } from '../../../../../components/formikComponent'

const RoundPremiumComponent = props => (
  <div className="d-flex m-b-20" key={props.field}>
    <div className="text-center m-r-10" style={{ width: '8%' }}>
      <FastField
        name={`${props.field}.startAge`}
        component={Input}
        className="form-control"
        classNameReadOnly="f-s-15 text-center"
        placeholder="ตั้งแต่ (วัน)"
        readOnly={props.isEdit}
        onChange={props.copyPaste}
        required
      />
    </div>
    <div className="text-center m-r-10" style={{ width: '8%' }}>
      <FastField
        name={`${props.field}.endAge`}
        component={Input}
        type="number"
        className="form-control"
        classNameReadOnly="f-s-15 text-center"
        placeholder="จนถึง (วัน)"
        readOnly={props.isEdit}
        required
      />
    </div>
    <div className="text-center m-r-10" style={{ width: '20%' }}>
      <FastField
        name={`${props.field}.premium`}
        component={Input}
        type="number"
        className="form-control"
        classNameReadOnly="f-s-15 text-center"
        placeholder="เบี้ย (บาท)"
        readOnly={props.isEdit}
        onChange={props.recalculatePremiumNet(`${props.field}.premium`)}
        required
      />
    </div>
    <div className="text-center m-r-10" style={{ width: '20%' }}>
      <FastField
        name={`${props.field}.stamp`}
        component={Input}
        type="number"
        className="form-control"
        classNameReadOnly="f-s-15 text-center"
        placeholder="แสตมป์ (บาท)"
        readOnly={props.isEdit}
        onChange={props.recalculatePremiumNet(`${props.field}.stamp`)}
        required
      />
    </div>
    <div className="text-center m-r-10" style={{ width: '20%' }}>
      <FastField
        name={`${props.field}.tax`}
        component={Input}
        type="number"
        className="form-control"
        classNameReadOnly="f-s-15 text-center"
        placeholder="ภาษี (บาท)"
        readOnly={props.isEdit}
        onChange={props.recalculatePremiumNet(`${props.field}.tax`)}
        required
      />
    </div>
    <div className="text-center m-r-10" style={{ width: '20%' }}>
      <FastField
        name={`${props.field}.premiumCf`}
        component={Input}
        type="number"
        className="form-control"
        classNameReadOnly="f-s-15 text-center"
        placeholder="เบี้ยสุทธิ (บาท)"
        readOnly={props.isEdit}
        required
      />
    </div>
    <div
      className="text-center d-flex align-items-center"
      style={{ width: '4%' }}
    >
      {!props.isEdit && props.values.length > 1 && (
        <button
          type="button"
          className="btn btn-danger btn-icon"
          onClick={props.onDelete}
        >
          <i className="ion-md-trash" />
        </button>
      )}
    </div>
  </div>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)

    const onDelete = useCallback(() => {
      props.remove(props.index)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.index])

    const recalculatePremiumNet = useCallback(
      name => async e => {
        await props.setFieldValue(name, e.target.value)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.value]
    )

    useEffect(() => {
      const { premium, stamp, tax } = props.value
      const premiumCf =
        parseFloat(premium || 0) + parseFloat(stamp || 0) + parseFloat(tax || 0)

      props.setFieldValue(`${props.field}.premiumCf`, premiumCf)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value])

    const copyPaste = useCallback(e => {
      let valueTable
      valueTable = e.target.value.split(' ')

      let nextValue = []
      let newString = ''
      valueTable.forEach((item, index) => {
        newString = newString + item
        if (index % 3 === 2) {
          newString = newString.replace('-', '\t')
          nextValue.push(newString)
          newString = ''
        }
      })

      if (valueTable.length <= 1) {
        props.setFieldValue(`${props.field}.startAge`, e.target.value)
        return
      }

      const premiumCf = []
      nextValue.forEach(item => {
        premiumCf.push(item.split('\t')[5])
      })

      nextValue = nextValue.map(item => item.split('\t'))
      const lastValue = []

      e.preventDefault()

      for (let index = 0; index < premiumCf.length; index++) {
        const first = premiumCf.indexOf(premiumCf[index])
        const last = premiumCf.lastIndexOf(premiumCf[index])
        lastValue.push(nextValue[index])

        if (first !== last) {
          lastValue[lastValue.length - 1][0] = nextValue[first][0]
          lastValue[lastValue.length - 1][1] = nextValue[last][0]
          index = last
        }
      }

      lastValue.forEach(item => {
        props.push({
          startAge: item[0],
          endAge: item[1],
          premium: parseFloat(Number(item[2].replace(/[^0-9.-]+/g, ''))),
          stamp: parseFloat(Number(item[3].replace(/[^0-9.-]+/g, ''))),
          tax: parseFloat(Number(item[4].replace(/[^0-9.-]+/g, ''))),
          premiumCf: parseFloat(Number(item[5].replace(/[^0-9.-]+/g, ''))),
        })
      })
      props.remove(0)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
      loading,
      setLoading,
      onDelete,
      recalculatePremiumNet,
      copyPaste,
    }
  })
)

export default enhancer(RoundPremiumComponent)
