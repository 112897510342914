import React, { useState, useCallback } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import { withRouter } from 'react-router-dom'
import PremiumComponent from './RoundPremiumComponent'

const TabComponent = props => (
  <div className="m-b-30">
    <div
      style={{ background: '#1d2226' }}
      className="d-flex height-40 text-white m-b-20"
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '16%' }}
      >
        เพศ
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '8%' }}
      >
        ตั้งแต่ (วัน)
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '8%' }}
      >
        จนถึง (วัน)
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '17%' }}
      >
        เบี้ย (บาท)
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '17%' }}
      >
        แสตมป์ (บาท)
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '17%' }}
      >
        ภาษี (บาท)
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '17%' }}
      >
        เบี้ยสุทธิ (บาท)
      </div>
      <div style={{ width: '4%' }}></div>
    </div>
    {props.values.paPremiumDetails &&
      props.values.paPremiumDetails.map((field, index) => (
        <PremiumComponent
          name={`${props.name}[${index}]`}
          key={props.name + index}
          index={index}
          isEdit={props.isEdit}
          values={field}
          remove={props.remove}
          setFieldValue={props.form.setFieldValue}
          configs={props.configs}
        />
      ))}
    {!props.isEdit && (
      <button type="button" className="btn btn-success" onClick={props.onAdd}>
        Add
      </button>
    )}
  </div>
)

const enhancer = compose(
  withRouter,
  withHooks(props => {
    const [loading, setLoading] = useState(false)

    const onAdd = useCallback(() => {
      props.push({})
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onDelete = useCallback(
      index => () => {
        props.remove(index)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    return {
      loading,
      setLoading,
      onAdd,
      onDelete,
    }
  })
)

export default enhancer(TabComponent)
