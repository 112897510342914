import React, { useEffect, useCallback } from 'react'
import { Link, withRouter } from 'react-router-dom'
import NVD3Chart from 'react-nvd3'
import d3 from 'd3'
import Calendar from 'react-calendar'
import {
  Panel,
  PanelHeader,
  PanelFooter,
  PanelBody,
} from '../../components/panel/panel'
import { withStores, withHooks } from '../../enhancers'
import { compose } from 'redux'
import { orderBy } from 'lodash'
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap'

const Dashboard = props => (
  <div>
    <ol className="breadcrumb float-xl-right">
      <li className="breadcrumb-item">
        <Link to="/">Home</Link>
      </li>
      <li className="breadcrumb-item">
        <Link to="/dashboard">Dashboard</Link>
      </li>
    </ol>
    <h1 className="page-header">Dashboard</h1>
    <div className="row">
      <div className="col-xl-3 col-md-6">
        <div className="widget widget-stats bg-teal">
          <div className="stats-icon stats-icon-lg">
            <i className="fa fa-globe fa-fw"></i>
          </div>
          <div className="stats-content">
            <div className="stats-title">Company Total</div>
            <div className="stats-number">{props.all.totalCompany}</div>
            <div className="stats-progress progress">
              <div className="progress-bar w-100"></div>
            </div>
            <div className="stats-desc text-ellipsis">
              {props.companyNameString}
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="widget widget-stats bg-blue">
          <div className="stats-icon stats-icon-lg">
            <i className="fa fa-dollar-sign fa-fw"></i>
          </div>
          <div className="stats-content">
            <div className="stats-title">Product Total</div>
            <div className="stats-number">{props.all.totalProduct}</div>
            <div className="stats-progress progress">
              <div className="progress-bar w-100"></div>
            </div>
            <div className="stats-desc text-ellipsis">
              {props.productTypeString}
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="widget widget-stats bg-indigo">
          <div className="stats-icon stats-icon-lg">
            <i className="fa fa-archive fa-fw"></i>
          </div>
          <div className="stats-content">
            <div className="stats-title">Product Online</div>
            <div className="stats-number">{props.productOnline}</div>
            <div className="stats-progress progress">
              <div
                className="progress-bar"
                style={{ width: `${props.productOnline}%` }}
              ></div>
            </div>
            <div className="stats-desc text-ellipsis">
              {props.productTypeString}
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="widget widget-stats bg-dark">
          <div className="stats-icon stats-icon-lg">
            <i className="fa fa-comment-alt fa-fw"></i>
          </div>
          <div className="stats-content">
            <div className="stats-title">Product offline</div>
            <div className="stats-number">{props.productOffline}</div>
            <div className="stats-progress progress">
              <div
                className="progress-bar"
                style={{ width: `${props.productOffline}%` }}
              ></div>
            </div>
            <div className="stats-desc text-ellipsis">
              {props.productTypeString}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-xl-8">
        <div className="widget-chart with-sidebar inverse-mode">
          <div className="widget-chart-content bg-dark">
            <h4 className="chart-title">
              Product
              <small>Where do our visitors come from</small>
            </h4>
            <div className="widget-chart-full-width nvd3-inverse-mode">
              <NVD3Chart
                type="discreteBarChart"
                id="barChart"
                datum={props.barChartData}
                height="300"
                x="label"
                y="value"
              />
            </div>
          </div>
          <div className="widget-chart-sidebar bg-dark-darker">
            <div className="chart-number">
              {props.all.totalProduct}
              <small>Total Product</small>
            </div>
            <div className="flex-grow-1 d-flex align-items-center nvd3-inverse-mode">
              <NVD3Chart
                type="pieChart"
                datum={props.donutChartData}
                height={180}
                options={props.donutChartOptions}
                x="label"
                y="value"
              />
            </div>
            <ul className="chart-legend f-s-11">
              <li>
                <i className="fa fa-circle fa-fw text-blue f-s-9 m-r-5 t-minus-1"></i>{' '}
                {props.all.totalLifeProducts} <span>Life Product</span>
              </li>
              <li>
                <i className="fa fa-circle fa-fw text-teal f-s-9 m-r-5 t-minus-1"></i>{' '}
                {props.all.totalHealthProducts} <span>Non-life Product</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-xl-4">
        <Panel>
          <PanelHeader noButton={true}>Company</PanelHeader>
          <PanelBody
            className="overflow-auto p-0 scrollbar scrollbar-primary"
            style={{ height: '335px' }}
          >
            <div className="list-group">
              {props.companySort.map((item, index) => (
                <Link
                  key={index}
                  to="/dashboard"
                  className="list-group-item list-group-item-inverse list-group-item-action d-flex justify-content-between align-items-center text-ellipsis"
                >
                  <span className="btn-group">
                    <img
                      src={item.logoPath}
                      className="width-30 height-30 rounded-corner bg-grey-transparent-2 text-center p-auto"
                      alt="Pic"
                    />
                    <div
                      className="m-l-10 p-t-5 w-100 overflow-hidden"
                      style={{
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.name}
                    </div>
                  </span>
                  <div>
                    <span className="badge f-w-500 bg-blue f-s-10 width-40">
                      {item.totalProductLife}
                    </span>
                    <span className="badge f-w-500 bg-teal f-s-10 width-40">
                      {item.totalProductNonLife}
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </PanelBody>
        </Panel>
      </div>
    </div>
    <div className="row">
      <div className="col-xl-4 col-lg-6">
        <Panel>
          <PanelHeader noButton={true}>
            New Registered Users{' '}
            <span className="pull-right label bg-teal pt-0 pb-0">
              {props.all.totalUser} users
            </span>
          </PanelHeader>
          <ul className="registered-users-list clearfix">
            {props.all.usersCreateAt &&
              props.all.usersCreateAt.map((item, index) => (
                <li key={index}>
                  <Link to="/dashboard">
                    <img src={item.logo} alt="" />
                  </Link>
                  <h4 className="username text-ellipsis">
                    {item.nameInfo.firstName} {item.nameInfo.LastName}
                    <small>{item.nameInfo.firstName}</small>
                  </h4>
                </li>
              ))}
          </ul>

          {props.checkRole && (
            <PanelFooter className="text-center">
              <Link to="/User" className="text-inverse">
                View All
              </Link>
            </PanelFooter>
          )}
        </Panel>
      </div>

      <div className="col-xl-4 col-lg-6">
        <Panel>
          <PanelHeader noButton={true}>Today's Schedule</PanelHeader>
          <div>
            <Calendar value={props.date} />
          </div>
        </Panel>
      </div>

      <div className="col-xl-4 col-lg-6">
        <div className="card border-0 bg-dark-darker text-white mb-3">
          <div className="card-body">
            <div className="mb-3 text-grey">
              <b>LOGIN HISTORY</b>
              <span className="ml-2">
                <i className="fa fa-info-circle" id="popover7"></i>
                <UncontrolledPopover
                  trigger="hover"
                  placement="top"
                  target="popover7"
                >
                  <PopoverHeader>Top products with units sold</PopoverHeader>
                  <PopoverBody>
                    Products with the most individual units sold. Includes
                    orders from all sales channels.
                  </PopoverBody>
                </UncontrolledPopover>
              </span>
            </div>
            {props.all.usersLastLogin &&
              props.all.usersLastLogin.map((item, index) => (
                <div className="d-flex align-items-center m-b-15" key={index}>
                  <div className="widget-img rounded-lg width-30 m-r-10 bg-white">
                    <div
                      className="h-100 w-100"
                      style={{
                        background: `url(${item.logo}) center no-repeat`,
                        backgroundSize: 'auto 100%',
                      }}
                    ></div>
                  </div>
                  <div className="text-truncate">
                    <div>
                      {item.nameInfo.firstName} {item.nameInfo.LastName}
                    </div>
                    {/* <div className="text-grey">$799.00</div> */}
                  </div>
                  <div className="ml-auto ">
                    {item.roles.map((roleItem, index) => (
                      <React.Fragment key={index}>
                        <span
                          className={`label label-${
                            roleItem === 'god'
                              ? 'pink'
                              : roleItem === 'product_admin'
                              ? 'info'
                              : 'purple'
                          } m-r-3`}
                        >
                          {roleItem}
                        </span>
                        {/* <div className="f-s-13">195</div>
                        <div className="text-grey f-s-10">sold</div> */}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  </div>
)

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    fetch: stores.dashboardStore.fetch,
    all: stores.dashboardStore.all,
    current: stores.app.current,
  })),
  withHooks(props => {
    useEffect(() => {
      props.fetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const productOnline = useCallback(
      (props.all.totalProductOnline / props.all.totalProduct).toFixed(2) * 100,
      [props.all]
    )

    const productOffline = useCallback(
      (props.all.totalProductOffline / props.all.totalProduct).toFixed(2) * 100,
      [props.all]
    )

    const companyNameString = useCallback(
      props.all.companies &&
        props.all.companies
          .map(item => {
            return item.name
          })
          .join(', '),
      [props.all]
    )

    const checkRole = useCallback(
      props.current.roles.find(item => item === 'god'),
      [props.current]
    )

    const productTypeString = useCallback(
      props.all.productAllType &&
        props.all.productAllType
          .map(item => {
            return item.label
          })
          .join(', '),
      [props.all]
    )

    const barChartData = useCallback(
      [
        {
          key: 'Cumulative Return',
          values: [
            {
              label: 'Term',
              value: props.all.totalProductTerm,
              color: '#348fe2',
            },
            {
              label: 'WholeLife',
              value: props.all.totalProductWholeLife,
              color: '#348fe2',
            },
            {
              label: 'Saving',
              value: props.all.totalProductSaving,
              color: '#348fe2',
            },
            {
              label: 'Annuity',
              value: props.all.totalProductAnnuity,
              color: '#348fe2',
            },
            { label: 'Hs', value: props.all.totalProductHs, color: '#5AC8FA' },
            { label: 'Hb', value: props.all.totalProductHb, color: '#5AC8FA' },
            { label: 'Ci', value: props.all.totalProductCi, color: '#5AC8FA' },
          ],
        },
      ],
      [props.all]
    )

    const formatDate = d => {
      var monthsName = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      d = new Date(d)
      d = monthsName[d.getMonth()] + ' ' + d.getDate()
      return d
    }
    const getDate = minusDate => {
      var d = new Date()
      d = d.setDate(d.getDate() - minusDate)
      return d
    }

    const donutChartOptions = {
      donut: true,
      showLegend: false,
      growOnHover: false,
      arcsRadius: [
        { inner: 0.65, outer: 0.93 },
        { inner: 0.6, outer: 1 },
      ],
      margin: { left: 10, right: 10, top: 10, bottom: 10 },
      donutRatio: 0.5,
      valueFormat: d3.format(',.0f'),
    }
    const donutChartData = [
      {
        label: 'Life',
        value:
          (props.all.totalLifeProducts / props.all.totalProduct).toFixed(2) *
          100,
        color: '#348fe2',
      },
      {
        label: 'Non-life',
        value:
          (props.all.totalHealthProducts / props.all.totalProduct).toFixed(2) *
          100,
        color: '#00ACAC',
      },
    ]

    const companySort = orderBy(props.all.companies, ['total_product', 'desc'])

    const map = {
      center: {
        lat: 59.95,
        lng: 30.33,
      },
      zoom: 9,
    }
    const date = new Date()

    return {
      formatDate,
      getDate,
      map,
      date,
      donutChartData,
      donutChartOptions,
      productOnline,
      productOffline,
      barChartData,
      companySort,
      companyNameString,
      productTypeString,
      checkRole,
    }
  })
)

export default enhancer(Dashboard)
