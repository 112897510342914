import React, { useState, useCallback } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import CollapseComponent from './CollapseComponent'
import BenefitTypeModal from './BenefitTypeModal'

const SetupBenefit = props => (
  <div>
    <div className="p-b-20 d-flex justify-content-between">
      <div className="f-s-20 f-w-700">Benefit</div>
      {props.isShowAddButton && !props.isEdit && (
        <button
          type="button"
          className="btn btn-success"
          onClick={props.onClickAdd}
        >
          Add
        </button>
      )}
    </div>
    {props.form.values.benefit &&
      props.form.values.benefit.map((field, index) => (
        <CollapseComponent
          {...props}
          field={`benefit[${index}]`}
          key={field + index}
          index={index}
          configs={props.configs}
          isHealth={props.isHealth}
          remove={props.remove}
          values={field}
        />
      ))}

    <BenefitTypeModal
      open={props.openModalAdd}
      setOpen={props.onClickAdd}
      fields={props.form.values.benefit}
      push={props.push}
      configs={props.configs}
    />
  </div>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [openModalAdd, setOpenModalAdd] = useState(false)

    const onClickAdd = useCallback(() => {
      setOpenModalAdd(!openModalAdd)
    }, [openModalAdd])

    return {
      loading,
      setLoading,
      openModalAdd,
      setOpenModalAdd,
      onClickAdd,
      isShowAddButton:
        (props.configs.product &&
          props.configs.product.benefit.type.options.length) !==
        (props.form.values.benefit && props.form.values.benefit.length),
    }
  })
)

export default enhancer(SetupBenefit)
