import React, { useState, useCallback } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import PremiumComponent from './RoundPremiumComponent'

const DailyPremium = props => (
  <div className="m-b-30">
    <div
      style={{ background: '#1d2226' }}
      className="d-flex height-40 text-white"
    >
      <div className="f-s-20 f-w-700 d-flex justify-content-center align-items-center width-full">
        รายเที่ยว
      </div>
    </div>
    <div
      style={{ background: '#1d2226' }}
      className="d-flex height-40 text-white m-b-20"
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '8%' }}
      >
        ตั้งแต่ (วัน)
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '8%' }}
      >
        จนถึง (วัน)
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '20%' }}
      >
        เบี้ย (บาท)
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '20%' }}
      >
        แสตมป์ (บาท)
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '20%' }}
      >
        ภาษี (บาท)
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '20%' }}
      >
        เบี้ยสุทธิ (บาท)
      </div>
      {!props.isEdit &&
        props.form.values.taPremiumMaster.taPremiumPerRound.length > 1 && (
          <div style={{ width: '4%' }}></div>
        )}
    </div>
    {props.form.values.taPremiumMaster &&
      props.form.values.taPremiumMaster.taPremiumPerRound.map((item, index) => (
        <PremiumComponent
          key={`${props.name}[${index}]`}
          field={`${props.name}[${index}]`}
          index={index}
          fields={props.fields}
          isEdit={props.isEdit}
          value={item}
          values={props.form.values.taPremiumMaster.taPremiumPerRound}
          remove={props.remove}
          push={props.push}
          setFieldValue={props.form.setFieldValue}
        />
      ))}
    {!props.isEdit && (
      <button type="button" className="btn btn-success" onClick={props.onAdd}>
        Add
      </button>
    )}
  </div>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const onAdd = useCallback(() => {
      props.push({})
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onDelete = useCallback(
      index => () => {
        props.remove(index)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    return {
      loading,
      setLoading,
      onAdd,
      onDelete,
    }
  })
)

export default enhancer(DailyPremium)
