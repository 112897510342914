import React, { useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { Panel, PanelHeader } from '../../../../components/panel/panel.jsx'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../../enhancers/index.js'
import ReactTable from 'react-table'
import 'react-table/react-table.css'

const tableColumns = [
  {
    Header: 'Year',
    accessor: 'year',
  },
  {
    Header: 'Quarter',
    accessor: 'quarter',
  },
]

const FinancialBudgetTable = props => (
  <div>
    <ol className="breadcrumb float-xl-right">
      <li className="breadcrumb-item"></li>
    </ol>
    <h1 className="page-header">Financial Budget Tables </h1>
    <Panel>
      <PanelHeader noButton={true}>
        Financial Budget Tables
        <button
          type="button"
          className="btn btn-success m-l-15"
          onClick={props.goToNewPage}
        >
          New
        </button>
      </PanelHeader>

      <ReactTable
        filterable
        data={props.all}
        columns={tableColumns}
        className="-highlight"
        defaultPageSize={10}
        getTrProps={props.goToEditPage}
      />
    </Panel>
  </div>
)

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    fetch: stores.financialBudgetStore.fetch,
    all: stores.financialBudgetStore.all,
  })),

  withHooks(props => {
    useEffect(() => {
      props.fetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const goToEditPage = useCallback((state, rowInfo) => {
      return {
        onClick: e => {
          props.history.push(
            `/score_star/financial_budget/${rowInfo.original.id}`
          )
        },
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const goToNewPage = useCallback(
      () => props.history.push('/score_star/financial_budget/new'),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    return { goToEditPage, goToNewPage }
  })
)

export default enhancer(FinancialBudgetTable)
