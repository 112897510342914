import React, { useState, useCallback, useEffect } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import { Select } from '../../../../../components/formikComponent/index'
import { Collapse, CardHeader, CardBody, Card } from 'reactstrap'
import { Field, FieldArray } from 'formik'
import TabComponent from './TabComponent'
import SweetAlert from 'react-bootstrap-sweetalert'

const CollapseComponent = props => (
  <Card className="bg-dark text-white" key={props.field}>
    <CardHeader
      className={
        'card-header bg-dark-darker text-white pointer-cursor p-10 position-relative'
      }
    >
      <div className="d-flex justify-content-between">
        <div
          className="d-flex align-items-center"
          style={{ zIndex: 200 - props.index }}
        >
          {!props.openCollapse && (
            <i className="fas fa-chevron-down text-blue mr-2 f-s-20" />
          )}
          {props.openCollapse && (
            <i className="fas fa-chevron-up text-blue mr-2 f-s-20" />
          )}
          <Field
            name={`${props.field}.type`}
            component={Select}
            options={
              props.configs.paProduct &&
              props.configs.paProduct.paPremiumMaster.type.options
            }
            className="width-250 text-black"
            isClearable={false}
          />
        </div>

        <div
          style={{
            position: 'absolute',
            left: '0',
            top: '0',
            right: '0',
            bottom: '0',
            zIndex: '10',
            cursor: 'pointer',
          }}
          onClick={props.toggleCollapse()}
        />

        <button
          type="button"
          onClick={props.onCloseAndOpenDelete}
          className="btn btn-danger btn-sm m-r-2"
          style={{ zIndex: '11' }}
        >
          Remove
        </button>
      </div>
    </CardHeader>

    <Collapse isOpen={props.openCollapse}>
      <CardBody>
        <FieldArray
          name={`${props.field}.paPeriods`}
          index={props.index}
          render={fieldArrayProps => (
            <TabComponent
              {...fieldArrayProps}
              index={props.index}
              configs={props.configs}
              values={props.values}
            />
          )}
        />
      </CardBody>
    </Collapse>

    {props.confirmDelete && (
      <SweetAlert
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        title="Are you sure?"
        onConfirm={props.onDelete}
        onCancel={props.onCloseAndOpenDelete}
      >
        ต้องการลบใช่หรือไม่ ?
      </SweetAlert>
    )}
  </Card>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [openCollapse, setOpenCollapse] = useState(false)
    const [confirmDelete, setconFirmDelete] = useState(false)

    useEffect(() => {
      const label = props.configs.paProduct.paPremiumMaster.type.options.find(
        x => x.value === props.values.type
      )
      if (label) {
        props.form.setFieldValue(`${props.field}.premiumName`, label.label)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.values.type])

    const toggleCollapse = useCallback(
      () => () => {
        setOpenCollapse(!openCollapse)
      },
      [openCollapse]
    )

    const onCloseAndOpenDelete = useCallback(() => {
      setconFirmDelete(!confirmDelete)
    }, [confirmDelete])
    const onDelete = useCallback(() => {
      props.remove(props.index)
      setconFirmDelete(false)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
      loading,
      setLoading,
      toggleCollapse,
      openCollapse,
      setOpenCollapse,
      onCloseAndOpenDelete,
      confirmDelete,
      onDelete,
    }
  })
)

export default enhancer(CollapseComponent)
