import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../../../enhancers/index.js'
import { get } from 'lodash'

const HealthCiDetail = props => (
  <div>
    {props.current.type === 'ci' &&
      props.current.benefitTable &&
      props.current.benefitTable.map((benefitItem, benefitIndex) => (
        <>
          {Object.keys(benefitItem).map((key, keyIndex) => (
            <>
              {benefitItem[key].map((detail, detailIndex) => (
                <div className="m-t-10 p-l-10" key={detailIndex}>
                  <h5>{detail.label}</h5>
                  <div className="f-s-14 p-l-10">{detail.value}</div>
                </div>
              ))}
            </>
          ))}
        </>
      ))}
  </div>
)

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    healthFetchById: stores.search.healthFetchById,
    current: stores.search.current,
    searchConfig: get(stores, 'search.searchConfig', []),
  })),
  withHooks(props => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      delete props.searchConfig.lifeConfigs
      delete props.searchConfig.healthConfigs
      delete props.searchConfig.taConfigs
      delete props.searchConfig.paConfigs
      delete props.searchConfig.productConfigs
      delete props.searchConfig.partnerConfigs

      if (props.productId) {
        delete props.searchConfig.companies
        delete props.searchConfig.product

        props.healthFetchById(props.productId, {
          ...props.searchConfig,
          productType: 'HealthProduct',
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.productId])

    return {
      loading,
      setLoading,
      textHeader: [
        'เบี้ยที่ต้องชำระ',
        'ผลประโยชน์ทั่วไป',
        'ค่ารักษาพยาบาลในฐานะผู้ป่วยใน',
        'ค่ารักษาพยาบาลในฐานะผู้ป่วยนอก',
        'ค่ารักษาพยาบาลสำหรับผลประโยชน์อื่นๆ',
      ],
    }
  })
)

export default enhancer(HealthCiDetail)
