import { useState, useCallback, useRef } from 'react'
import { withRouter } from 'react-router-dom'

import { compose } from 'recompose'
import { withStores, withHooks } from '../../../enhancers/index.js'
import { reduxForm } from 'redux-form'

import { Company } from '../show/index'

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    create: stores.company.create,
  })),
  reduxForm({
    form: 'newCompany',
    enableReinitialize: true,
  }),

  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)
    const [activeTab, setActiveTab] = useState('2')

    const onSave = props.handleSubmit(async values => {
      await props.create(values)
      props.history.push('/company')
    })

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])

    const onClickToggleTab = useCallback(
      index => () => {
        setActiveTab(index)
      },
      []
    )
    return {
      loading,
      setLoading,
      textHeader: 'New Page',
      isNew: true,
      onSave,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      buttonSubmit,
      activeTab,
      onClickToggleTab,
    }
  })
)

export default enhancer(Company)
