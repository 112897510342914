import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Panel, PanelHeader } from './../../../components/panel/panel.jsx'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../enhancers/index.js'
import 'react-table/react-table.css'
import { reduxForm, Field, Form } from 'redux-form'
import { Input, Avatar, CheckboxGroup } from '../../../components/index.js'
import { get } from 'lodash'
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert'

export const Profile = props => (
  <Form onSubmit={props.onSave}>
    <ol className="breadcrumb float-xl-right">
      <li className="breadcrumb-item">
        <Link to="/user">User</Link>
      </li>
      <li className="breadcrumb-item active">User Edit</li>
    </ol>
    <h1 className="page-header">User</h1>
    <Panel>
      <PanelHeader noButton={true}>
        <div className="d-flex justify-content-between">
          <div className="text-center p-t-10">{props.textHeader}</div>
          {props.isEdit && (
            <div>
              <button
                type="button"
                className="btn btn-success"
                onClick={props.onClickIsEdit}
                style={{ justifySelf: 'flex-end' }}
              >
                Edit
              </button>
              {/* <button
                type="button"
                className="btn btn-danger"
                onClick={props.onClickDelete}
              >
                Delete
              </button> */}
            </div>
          )}
          {!props.isEdit && (
            <div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={props.setConfirmUpdateToCon}
                style={{ justifySelf: 'flex-end' }}
              >
                Save
              </button>
              {/* {!props.isNew && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={props.onReset}
                >
                  Cancel
                </button>
              )} */}
            </div>
          )}
        </div>
      </PanelHeader>

      <div
        className="form-group row m-b-15"
        style={{ padding: '16px', alignContent: 'center' }}
      >
        <div className="form-group colunm m-b-15">
          <label className="col-sm-3 col-form-label"></label>
          <div className="col-md-9">
            <Field name="logo" component={Avatar} readOnly={props.isEdit} />
          </div>
        </div>

        <div>
          <div className="form-group row m-b-15 m-l-10 width-450 height-40 m-t-20">
            <div className="col-sm-1 col-form-label height-40">Email</div>
            <div className="row m-l-40">
              <Field
                name="email"
                component={Input}
                type="text"
                className="form-control width-250 "
                classNameReadOnly="width-150"
                placeholder="Email"
                required
                readOnly
              />
            </div>
          </div>

          <div className="form-group row m-b-15 m-l-10 width-full">
            <div className="col-sm-1 col-form-label">Name</div>
            <div className="row m-l-40">
              <Field
                name="nameInfo.firstName"
                component={Input}
                type="text"
                className="form-control width-200 m-r-10 "
                classNameReadOnly="width-150 "
                placeholder="First Name"
                style={{ height: '38px' }}
                required
                readOnly={props.isEdit}
              />
              <Field
                name="nameInfo.lastName"
                component={Input}
                type="text"
                style={{ height: '38px' }}
                className="form-control width-200 "
                classNameReadOnly="width-150"
                placeholder="Last Name"
                required
                readOnly={props.isEdit}
              />
            </div>
          </div>

          {props.IsGod && (
            <div className="form-group row m-b-15 m-l-10 width-450 m-t-20">
              <div className="col-sm-1 col-form-label height-40">Roles</div>
              <div className="row m-l-40">
                <Field
                  name="roles"
                  component={CheckboxGroup}
                  className="form-control width-250 "
                  options={get(props.configs, 'user.roles.options', [])}
                  readOnly={props.isEdit}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title=""
          onConfirm={props.onClickconfirmUpdate}
          onCancel={props.setConfirmUpdateToCon}
        >
          ต้องการบันทึกใช่หรือไม่ ?
        </SweetAlert>
      )}
    </Panel>
    <button type="submit" ref={props.buttonSubmit} hidden />
  </Form>
)

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    fetchById: stores.profileStore.fetchById,
    update: stores.profileStore.update,
    current: stores.profileStore.current,
    user: stores.app.current,
    initialValues: {
      ...stores.profileStore.current,
      logo: stores.profileStore.current.logo || '',
    },
    fetchConfig: stores.profileStore.fetchConfig,
    configs: stores.profileStore.configs,
  })),
  reduxForm({
    form: 'editProfile',
    enableReinitialize: true,
  }),

  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    const onSave = props.handleSubmit(async values => {
      if (typeof values.logo === 'string') {
        await props.update(props.match.params.id, {
          ...values,
          logo: undefined,
        })
      } else {
        await props.update(props.match.params.id, values)
      }
    })
    const onReset = () => {
      props.reset()
    }

    const onClickIsEdit = () => {}
    useEffect(() => {
      props.fetchById(props.match.params.id)
      props.fetchConfig()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const IsGod = useCallback(
      get(props.user, 'roles', []).find(role => role === 'god'),
      [props.user]
    )
    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])

    return {
      loading,
      setLoading,
      textHeader: 'Edit User',
      onSave,
      isEdit: false,
      onClickIsEdit,
      onReset,
      IsGod,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      buttonSubmit,
    }
  })
)

export default enhancer(Profile)
