import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { Panel, PanelHeader } from './../../../components/panel/panel.jsx'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../enhancers/index.js'
import ReactTable from 'react-table'
import 'react-table/react-table.css'

const tableColumns = [
  {
    Header: 'Email',
    accessor: 'email',
    Cell: row => (
      <span className="btn-group">
        {row.original.logo ? (
          <img
            src={row.original.logo && row.original.logo}
            className="width-30 height-30 rounded-corner bg-grey-transparent-2 text-center p-auto"
            alt=""
          />
        ) : (
          <div
            className="image image-icon bg-black text-grey-darker width-30 height-30 rounded-corner m-r-0 text-center f-s-20"
            style={{ overflow: 'hidden', paddingTop: '8px' }}
          >
            <i className="fa fa-user" />
          </div>
        )}

        <div className="m-l-10 p-t-5">{row.original.email}</div>
      </span>
    ),
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: row => (
      <span className="btn-group">
        <div className="m-l-10 p-t-5">{row.original.nameInfo.firstName}</div>
        <div className="m-l-10 p-t-5">{row.original.nameInfo.lastName}</div>
      </span>
    ),
  },
]

const Profile = props => (
  <div>
    <ol className="breadcrumb float-xl-right">
      <li className="breadcrumb-item"></li>
    </ol>
    <h1 className="page-header">User</h1>
    <Panel>
      <PanelHeader noButton={true}>User</PanelHeader>

      <ReactTable
        filterable
        data={props.all}
        columns={tableColumns}
        className="-highlight"
        defaultPageSize={10}
        getTrProps={props.goToEditPage}
      />
    </Panel>
  </div>
)

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    fetch: stores.profileStore.fetch,
    all: stores.profileStore.all,
  })),

  withHooks(props => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      props.fetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const goToEditPage = useCallback((state, rowInfo) => {
      return {
        onClick: e => {
          props.history.push(`/user/${rowInfo.original.id}`)
        },
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { loading, setLoading, goToEditPage }
  })
)

export default enhancer(Profile)
