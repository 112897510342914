import React, { useState, useCallback, useEffect } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import { FastField } from 'formik'
import {
  Input,
  Select,
  Params,
} from '../../../../../components/formikComponent'

const DetailComponent = props => (
  <div className="d-flex m-b-10">
    <div className="w-50 d-flex align-items-center">
      <FastField
        name={`${props.name}.type`}
        component={Input}
        className="form-control"
        required
        hidden
      />
      {props.label}
    </div>

    <div className="w-25 m-l-20">
      <FastField
        name={`${props.name}.formula`}
        component={Select}
        options={props.options}
        onChange={props.onChangeFormula}
        readOnly={props.isEdit}
        isClearable={false}
      />
    </div>

    <div className="w-25 m-l-20">
      <Params
        configs={props.findParams()}
        name={`${props.name}`}
        isEdit={props.isEdit}
      />
    </div>
  </div>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [formula, setFormula] = useState('')

    useEffect(() => {
      setFormula(props.initialValueFormula)
    }, [props.initialValueFormula])

    const findParams = useCallback(() => {
      const newParams = props.options.find(x => x.value === formula)
      return newParams ? newParams.params : []
    }, [formula, props.options])

    const onChangeFormula = useCallback(e => {
      props.setFieldValue(`${props.name}.formula`, e.value)
      setFormula(e.value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
      loading,
      setLoading,
      formula,
      onChangeFormula,
      findParams,
    }
  })
)

export default enhancer(DetailComponent)
