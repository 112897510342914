import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Panel, PanelHeader } from './../../../components/panel/panel.jsx'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../enhancers/index.js'
import 'react-table/react-table.css'
import { reduxForm, Field, Form, FieldArray } from 'redux-form'
import { Input, Avatar } from '../../../components/index.js'
import { Products } from '../../product/life/index/index'
import UpdateStatus from '../../product/life/index/updateStatus'
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import JobLevel from './jobLevel'

export const Company = props => (
  <Form onSubmit={props.onSave}>
    <ol className="breadcrumb float-xl-right">
      <li className="breadcrumb-item">
        <Link to="/company">Company</Link>
      </li>
      <li className="breadcrumb-item active">
        {props.isNew ? 'Company New' : 'Company Edit'}
      </li>
    </ol>
    <h1 className="page-header">Company</h1>
    <Panel>
      <PanelHeader noButton={true}>
        <div className="d-flex justify-content-between">
          <div className="text-center p-t-10">{props.textHeader}</div>
          {props.isEdit && (
            <div>
              <button
                type="button"
                className="btn btn-success width-60 m-r-10 m-l-10"
                onClick={props.onClickIsEdit}
                style={{ justifySelf: 'flex-end' }}
              >
                Edit
              </button>
              <button
                type="button"
                className="btn btn-danger width-60"
                onClick={props.onClickDelete}
              >
                Delete
              </button>
            </div>
          )}
          {!props.isEdit && (
            <div>
              <button
                type="button"
                className="btn btn-primary  width-60 m-r-10 m-l-10"
                onClick={props.setConfirmUpdateToCon}
                style={{ justifySelf: 'flex-end' }}
              >
                Save
              </button>
              {!props.isNew && (
                // <button
                //   type="button"
                //   className="btn btn-danger  width-60 "
                //   onClick={props.onReset}
                // >
                //   Cancel
                // </button>
                <button
                  type="button"
                  className="btn btn-danger width-60"
                  onClick={props.onClickDelete}
                >
                  Delete
                </button>
              )}
            </div>
          )}
        </div>
      </PanelHeader>

      <div
        className="form-group row m-b-15"
        style={{ padding: '16px', alignContent: 'center' }}
      >
        <div className="form-group colunm m-b-15">
          <label className="col-sm-3 col-form-label"></label>
          <div className="col-md-9">
            <Field name="logo" component={Avatar} readOnly={props.isEdit} />
          </div>
        </div>
        <div className="form-group row m-b-15 m-l-20 width-450 m-t-20">
          <label className="col-sm-3 col-form-label height-40">
            Company Name
          </label>
          <div className="col-md-9 height-40">
            <Field
              name="name"
              component={Input}
              type="text"
              className="form-control width-250 "
              classNameReadOnly="width-150"
              placeholder="Company Name"
              required
              readOnly={props.isEdit}
            />
          </div>
        </div>
      </div>
      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title=""
          onConfirm={props.onClickconfirmUpdate}
          onCancel={props.setConfirmUpdateToCon}
        >
          ต้องการบันทึกใช่หรือไม่ ?
        </SweetAlert>
      )}
      {props.confirmDelete && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={props.onDelete}
          onCancel={props.onClickDelete}
        >
          ต้องการลบใช่หรือไม่ ?
        </SweetAlert>
      )}
    </Panel>
    <div className="row p-l-10 p-r-10">
      <div className="width-full (100%)">
        <Nav tabs>
          {!props.isNew && (
            <NavItem>
              <NavLink
                className={classnames({ active: props.activeTab === '1' })}
                onClick={props.onClickToggleTab('1')}
              >
                <span className="d-sm-none">Tab 1</span>
                <span className="d-sm-block d-none">Product</span>
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              className={classnames({ active: props.activeTab === '2' })}
              onClick={props.onClickToggleTab('2')}
            >
              <span className="d-sm-none">Tab 2</span>
              <span className="d-sm-block d-none">Job Level</span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={props.activeTab}>
          {!props.isNew && (
            <TabPane tabId="1">
              <Products
                isCompany={true}
                onNew={props.onNew}
                onRowClick={props.onRowClick}
                all={props.all}
                tableColumns={props.tableColumns}
              />
            </TabPane>
          )}
          <TabPane tabId="2">
            <FieldArray name="companyCareerLevels" component={JobLevel} />
          </TabPane>
        </TabContent>
      </div>
    </div>
    <button type="submit" ref={props.buttonSubmit} hidden />
  </Form>
)

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    fetchById: stores.company.fetchById,
    update: stores.company.update,
    current: stores.company.current,
    delete: stores.company.delete,
    fetch: stores.product.fetch,
    all: stores.product.all || [],
    initialValues: {
      ...stores.company.current,
      logo: stores.company.current.logoPath || '',
    },
  })),
  reduxForm({
    form: 'editCompany',
    enableReinitialize: true,
  }),

  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [confirmDelete, setConFirmDelete] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)
    const [activeTab, setActiveTab] = useState('1')
    const tableColumns = [
      {
        Header: 'Name',
        accessor: 'productName',
      },
      {
        Header: 'ความคุ้มครอง',
        accessor: 'maxMaleAge',
        Cell: row => (
          <div>
            <div className="m-b-10">
              {row.row._original.paymentPeriodType === 'round'
                ? 'ระยะเวลาจ่ายเบี้ย'
                : 'จ่ายเบี้ยจนถึงอายุ'}{' '}
              {row.row._original.paymentPeriodValue} ปี
            </div>
            <div>
              {row.row._original.protectPeriodType === 'round'
                ? 'ระยะเวลาคุ้มครอง'
                : 'คุ้มครองจนถึงอายุ'}{' '}
              {row.row._original.protectPeriodValue} ปี
            </div>
          </div>
        ),
      },
      {
        Header: 'อายุรับสมัคร',
        accessor: 'maxMaleAge',
        Cell: row => (
          <div>
            {Math.min(
              row.row._original.maleMinAge,
              row.row._original.femaleMinAge
            )}{' '}
            -{' '}
            {Math.max(
              row.row._original.maleMaxAge,
              row.row._original.femaleMaxAge
            )}
          </div>
        ),
      },
      {
        Header: 'ประเภท',
        accessor: 'type',
      },
      {
        Header: 'Status',

        Cell: row => (
          <UpdateStatus
            index={row.index}
            id={row.row._original.id}
            initialValues={row.row._original.status === 'online'}
            updateStatusPublish={props.updateStatusPublish}
            updateStatusUnpublished={props.updateStatusUnpublished}
          />
        ),
      },
    ]

    const onSave = props.handleSubmit(async values => {
      if (typeof values.logo === 'string') {
        await props.update(props.match.params.id, {
          ...values,
          logo: undefined,
        })
      } else {
        await props.update(props.match.params.id, values)
      }
    })
    const onReset = () => {
      props.reset()
    }

    const onClickDelete = useCallback(() => {
      setConFirmDelete(!confirmDelete)
    }, [confirmDelete])

    const onDelete = useCallback(async () => {
      await props.delete(props.match.params.id)
      props.history.push('/company')

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id])

    const onClickIsEdit = () => {}
    useEffect(() => {
      props.fetchById(props.match.params.id)
      props.fetch({ companyId: props.match.params.id })

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onNew = () => {
      props.history.push(
        `/products/life/new?company_id=${props.match.params.id}`
      )
    }

    const onRowClick = (state, rowInfo) => {
      return {
        onClick: e => {
          props.history.push(
            `/products/life/${rowInfo.original.id}?company_id=${props.match.params.id}`
          )
        },
      }
    }

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])

    const onClickToggleTab = useCallback(
      index => () => {
        setActiveTab(index)
      },
      []
    )

    return {
      loading,
      setLoading,
      textHeader: 'Edit page',
      onSave,
      isEdit: false,
      onClickIsEdit,
      onNew,
      onRowClick,
      onReset,
      onClickDelete,
      onDelete,
      confirmDelete,
      tableColumns,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      buttonSubmit,
      onClickToggleTab,
      activeTab,
    }
  })
)

export default enhancer(Company)
