import React, { useState, useEffect, useCallback, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { Panel, PanelHeader } from './../../../components/panel/panel.jsx'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../enhancers/index.js'
import 'react-table/react-table.css'
import { reduxForm, Field } from 'redux-form'
import { Input, Select } from '../../../components/index.js'
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert'

export const Partner = props => (
  <div>
    <ol className="breadcrumb float-xl-right">
      <li className="breadcrumb-item active"></li>
    </ol>
    <h1 className="page-header">Config</h1>
    <form onSubmit={props.onSave}>
      <Panel>
        <PanelHeader noButton={true}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div className="text-center p-t-10">{props.textHeader}</div>
            {props.isEdit && (
              <div>
                <button
                  type="button"
                  className="btn btn-success width-60 m-r-10 m-l-10"
                  onClick={props.onClickIsEdit}
                  style={{ justifySelf: 'flex-end' }}
                >
                  Edit
                </button>
              </div>
            )}
            {!props.isEdit && (
              <div>
                <button
                  type="button"
                  className="btn btn-primary  width-60 m-r-10 m-l-10"
                  style={{ justifySelf: 'flex-end' }}
                  onClick={props.setConfirmUpdateToCon}
                >
                  Save
                </button>
                {/* {!props.isNew && (
                  <button
                    type="button"
                    className="btn btn-danger  width-60 "
                    onClick={props.onReset}
                  >
                    Cancel
                  </button>
                )} */}
              </div>
            )}
          </div>
        </PanelHeader>

        <div style={{ padding: '16px' }}>
          <div className="form-group row m-b-15">
            <label className="col-sm-3 col-form-label">Discount Rate</label>
            <div className="col-md-9">
              <Field
                name="discountRate"
                component={Input}
                type="text"
                className="form-control width-250"
                classNameReadOnly="width-300"
                placeholder="Discount Rate"
                required
                readOnly={props.isEdit}
              />
            </div>
          </div>

          <div className="form-group row m-b-15">
            <label className="col-sm-3 col-form-label">Reinvestment Rate</label>
            <div className="col-md-9">
              <Field
                name="reinvestmentRate"
                component={Input}
                type="text"
                className="form-control width-250"
                classNameReadOnly="width-300"
                placeholder="Reinvestment Rate"
                required
                readOnly={props.isEdit}
              />
            </div>
          </div>

          <div className="form-group row m-b-15">
            <label className="col-sm-3 col-form-label">
              Mortality Base Table Year
            </label>
            <div className="col-md-9">
              <div
                className="position-relative"
                style={{ width: 'fit-content' }}
              >
                <Field
                  name="mortalityBaseTableYear"
                  component={Select}
                  type="number"
                  className="width-250"
                  classNameReadOnly="width-300"
                  placeholder="Mortality Base Table Year"
                  required
                  readOnly={props.isEdit}
                  options={
                    (props.configs.mortalityBaseTableYear &&
                      props.configs.mortalityBaseTableYear.options) ||
                    []
                  }
                />
              </div>
            </div>
          </div>

          <div className="form-group row m-b-15">
            <label className="col-sm-3 col-form-label">
              Financial Budget Year
            </label>
            <div className="col-md-9">
              <div
                className="position-relative"
                style={{ width: 'fit-content' }}
              >
                <Field
                  name="financialBudgetYear"
                  component={Select}
                  type="number"
                  className=" width-250"
                  classNameReadOnly="width-300"
                  placeholder="Financial Budget Year"
                  required
                  readOnly={props.isEdit}
                  options={
                    (props.configs.financialBudgetTable &&
                      props.configs.financialBudgetTable.options) ||
                    []
                  }
                />
              </div>
            </div>
          </div>

          {/* <div className="form-group row m-b-15">
            <label className="col-sm-3 col-form-label">Quarter</label>
            <div className="col-md-9">
              <Field
                name="quarter"
                component={Select}
                type="text"
                className="form-control width-250"
                classNameReadOnly="width-300"
                placeholder="Quarter"
                required
                options={[]}
                readOnly={props.isEdit}
              />
            </div>
          </div> */}

          {/* <div className="form-group row m-b-15">
            <label className="col-sm-3 col-form-label">Weight Car</label>
            <div className="col-md-9">
              <Field
                name="weightCar"
                component={Input}
                type="text"
                className="form-control width-250"
                classNameReadOnly="width-300"
                placeholder="Weight Car"
                required
                readOnly={props.isEdit}
              />
            </div>
          </div> */}

          <div className="form-group row m-b-15">
            <label className="col-sm-3 col-form-label">Alpha</label>
            <div className="col-md-9">
              <Field
                name="alpha"
                component={Input}
                type="text"
                className="form-control width-250"
                classNameReadOnly="width-300"
                placeholder="Alpha"
                required
                readOnly={props.isEdit}
              />
            </div>
          </div>
        </div>
      </Panel>

      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title=""
          onConfirm={props.onClickconfirmUpdate}
          onCancel={props.setConfirmUpdateToCon}
        >
          ต้องการบันทึกใช่หรือไม่ ?
        </SweetAlert>
      )}
      <button type="submit" ref={props.buttonSubmit} hidden />
    </form>
  </div>
)

const enhancer = compose(
  withRouter,
  withStores(stores => {
    return {
      fetch: stores.scoreStarConfigStore.fetch,
      update: stores.scoreStarConfigStore.update,
      current: stores.scoreStarConfigStore.current,
      delete: stores.scoreStarConfigStore.delete,
      fetchConfig: stores.scoreStarConfigStore.fetchConfig,
      configs: stores.scoreStarConfigStore.configs,
      initialValues: {
        ...stores.scoreStarConfigStore.current,
      },
    }
  }),
  reduxForm({
    form: 'editScoreStarConfig',
    enableReinitialize: true,
  }),

  withHooks(props => {
    const [loading, setLoading] = useState(false)

    const [confirmUpdate, setConfirmUpdate] = useState(false)

    const onSave = props.handleSubmit(async values => {
      await props.update(values)
    })
    const onReset = () => {
      props.reset()
    }

    const onClickIsEdit = () => {}

    useEffect(() => {
      props.fetch()
      props.fetchConfig()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])

    return {
      loading,
      setLoading,
      textHeader: 'Edit page',
      onSave,
      onClickIsEdit,
      onReset,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      buttonSubmit,
    }
  })
)

export default enhancer(Partner)
