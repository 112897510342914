import React, { useState } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import { get } from 'lodash'

import GeneralBenefit from './HsBenefit'
import CiBenefit from './CiBenefit'

const SetupBenefit = props => (
  <div>
    {props.type === 'hs' && (
      <>
        <h5 className="m-b-30 m-t-20">ผลประโยชน์ทั่วไป</h5>
        {props.configs.product &&
          props.configs.product.hsBenefit.hsGeneralBenefit.type.options.map(
            (item, index) => (
              <GeneralBenefit
                key={`hsBenefit.hsOtherBenefits${index}`}
                name="hsBenefit.hsGeneralBenefits"
                configs={props.configs.product.hsBenefit.hsGeneralBenefit}
                item={item}
                index={index}
                whatBenefit={item.value}
                initialValueFormula={get(
                  props.initialValues,
                  `hsBenefit.hsGeneralBenefits[${index}].formula`,
                  ''
                )}
                isEdit={props.isEdit}
                initialValues={props.initialValues}
                setFieldValue={props.setFieldValue}
              />
            )
          )}
        <hr />
        <h5 className="m-b-30">ค่ารักษาพยาบาลในฐานะผู้ป่วยใน</h5>
        {props.configs.product &&
          props.configs.product.hsBenefit.hsInPatientBenefit.type.options.map(
            (item, index) => (
              <GeneralBenefit
                key={`hsBenefit.hsOtherBenefits${index}`}
                name="hsBenefit.hsInPatientBenefits"
                configs={props.configs.product.hsBenefit.hsInPatientBenefit}
                item={item}
                index={index}
                whatBenefit={item.value}
                initialValueFormula={get(
                  props.initialValues,
                  `hsBenefit.hsInPatientBenefits[${index}].formula`,
                  ''
                )}
                isEdit={props.isEdit}
                initialValues={props.initialValues}
                setFieldValue={props.setFieldValue}
              />
            )
          )}
        <hr />

        <h5 className="m-b-30">ค่ารักษาพยาบาลในฐานะผู้ป่วยนอก</h5>
        {props.configs.product &&
          props.configs.product.hsBenefit.hsOutPatientBenefit.type.options.map(
            (item, index) => (
              <GeneralBenefit
                key={`hsBenefit.hsOtherBenefits${index}`}
                name="hsBenefit.hsOutPatientBenefits"
                configs={props.configs.product.hsBenefit.hsOutPatientBenefit}
                item={item}
                index={index}
                whatBenefit={item.value}
                initialValueFormula={get(
                  props.initialValues,
                  `hsBenefit.hsOutPatientBenefits[${index}].formula`,
                  ''
                )}
                isEdit={props.isEdit}
                initialValues={props.initialValues}
                setFieldValue={props.setFieldValue}
              />
            )
          )}
        <hr />
        <h5 className="m-b-30"> ค่ารักษาพยาบาลสำหรับผลประโยชน์อื่นๆ</h5>
        {props.configs.product &&
          props.configs.product.hsBenefit.hsOtherBenefit.type.options.map(
            (item, index) => (
              <GeneralBenefit
                key={`hsBenefit.hsOtherBenefits${index}`}
                name="hsBenefit.hsOtherBenefits"
                configs={props.configs.product.hsBenefit.hsOtherBenefit}
                item={item}
                index={index}
                whatBenefit={item.value}
                initialValueFormula={get(
                  props.initialValues,
                  `hsBenefit.hsOtherBenefits[${index}].formula`,
                  ''
                )}
                isEdit={props.isEdit}
                initialValues={props.initialValues}
                setFieldValue={props.setFieldValue}
              />
            )
          )}
      </>
    )}
    {props.type === 'ci' && (
      <>
        <CiBenefit
          configs={
            (props.configs.product && props.configs.product.ciBenefit) || []
          }
          isEdit={props.isEdit}
          initialValues={props.initialValues}
          setFieldValue={props.setFieldValue}
        />
      </>
    )}
  </div>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)

    return {
      loading,
      setLoading,
    }
  })
)

export default enhancer(SetupBenefit)
