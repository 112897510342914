import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Panel, PanelHeader } from './../../../components/panel/panel.jsx'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../enhancers/index.js'
import 'react-table/react-table.css'
import { reduxForm, Field } from 'redux-form'
import { Input, Avatar } from '../../../components/index.js'
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert'
import { v4 as uuidv4 } from 'uuid'
import AssignProducts from './AssignProducts'
import { get } from 'lodash'

export const Partner = props => (
  <div>
    <ol className="breadcrumb float-xl-right">
      <li className="breadcrumb-item">
        <Link to="/Partner">Partner</Link>
      </li>
      <li className="breadcrumb-item active">
        {props.isNew ? 'Partner New' : 'Partner Edit'}
      </li>
    </ol>
    <h1 className="page-header">Partner</h1>
    <form onSubmit={props.onSave}>
      <Panel>
        <PanelHeader noButton={true}>
          <div className="d-flex justify-content-between">
            <div className="text-center p-t-10">{props.textHeader}</div>
            {props.isEdit && (
              <div>
                <button
                  type="button"
                  className="btn btn-success width-60 m-r-10 m-l-10"
                  onClick={props.onClickIsEdit}
                  style={{ justifySelf: 'flex-end' }}
                >
                  Edit
                </button>
                <button
                  type="button"
                  className="btn btn-danger width-60"
                  onClick={props.onClickDelete}
                >
                  Delete
                </button>
              </div>
            )}
            {!props.isEdit && (
              <div>
                <button
                  type="button"
                  className="btn btn-primary  width-60 m-r-10 m-l-10"
                  style={{ justifySelf: 'flex-end' }}
                  onClick={props.setConfirmUpdateToCon}
                >
                  Save
                </button>
                {!props.isNew && (
                  // <button
                  //   type="button"
                  //   className="btn btn-danger  width-60 "
                  //   onClick={props.onReset}
                  // >
                  //   Cancel
                  // </button>
                  <button
                    type="button"
                    className="btn btn-danger width-60"
                    onClick={props.onClickDelete}
                  >
                    Delete
                  </button>
                )}
              </div>
            )}
          </div>
        </PanelHeader>

        <div
          className="form-group row m-b-15"
          style={{ padding: '16px', alignContent: 'center' }}
        >
          <div className="form-group colunm m-b-15">
            <label className="col-sm-3 col-form-label"></label>
            <div className="col-md-9">
              <Field name="logo" component={Avatar} readOnly={props.isEdit} />
            </div>
          </div>
          <div style={{ width: '80%' }}>
            <div className="form-group row m-b-15 m-l-20 width-450">
              <label className="col-sm-3 col-form-label height-40">
                Partner Name
              </label>
              <div className="col-md-9 height-40">
                <Field
                  name="nameEn"
                  component={Input}
                  type="text"
                  className="form-control width-250 "
                  classNameReadOnly="width-150"
                  placeholder="Partner Name"
                  required
                  readOnly={props.isEdit}
                />
              </div>
            </div>
            <div className="form-group row m-b-15 m-l-20 width-500">
              <label className="col-sm-3 col-form-label height-40">
                Api key
              </label>
              <div className="col-md-9 height-40">
                <div className="row">
                  <Field
                    name="apiKey"
                    component={Input}
                    type="text"
                    className="form-control width-250 "
                    classNameReadOnly="width-150"
                    placeholder="Api key"
                    required
                    readOnly={props.isEdit}
                  />
                  {(!props.isEdit || props.isNew) && (
                    <button
                      type="button"
                      className="btn btn-yellow m-l-5"
                      onClick={props.keyGenerate}
                    >
                      Generate Key
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Field
          name="productIds"
          component={AssignProducts}
          configs={props.configs}
          readOnly={props.isEdit}
          isNew={props.isNew}
        />

        {props.confirmUpdate && (
          <SweetAlert
            showCancel
            confirmBtnText="Continue"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title=""
            onConfirm={props.onClickconfirmUpdate}
            onCancel={props.setConfirmUpdateToCon}
          >
            ต้องการบันทึกใช่หรือไม่ ?
          </SweetAlert>
        )}
        {props.confirmDelete && (
          <SweetAlert
            showCancel
            confirmBtnText="Continue"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title="Are you sure?"
            onConfirm={props.onDelete}
            onCancel={props.onClickDelete}
          >
            ต้องการลบใช่หรือไม่ ?
          </SweetAlert>
        )}
      </Panel>
      <button type="submit" ref={props.buttonSubmit} hidden />
    </form>
  </div>
)

const enhancer = compose(
  withRouter,
  withStores(stores => {
    const configs = get(stores, 'partnerStore.configs', [])
    const newItem = []
    get(stores, 'partnerStore.current.productIds', []).forEach(item =>
      configs.forEach(value => {
        if (value.id === item) {
          newItem.push(value)
        }
      })
    )

    return {
      fetchById: stores.partnerStore.fetchById,
      update: stores.partnerStore.update,
      current: stores.partnerStore.current,
      delete: stores.partnerStore.delete,
      fetchConfig: stores.partnerStore.fetchConfig,
      configs: stores.partnerStore.configs,
      initialValues: {
        ...stores.partnerStore.current,
        logo: stores.partnerStore.current.logoPath || '',
        productIds: newItem,
      },
    }
  }),
  reduxForm({
    form: 'editPartner',
    enableReinitialize: true,
  }),

  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [confirmDelete, setConFirmDelete] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    const onSave = useCallback(
      props.handleSubmit(async values => {
        const products = (values.productIds || []).map(value => value.id)
        if (typeof values.logo === 'string') {
          await props.update(props.match.params.id, {
            ...values,
            logo: undefined,
            productIds: products,
          })
        } else {
          await props.update(props.match.params.id, {
            ...values,
            productIds: products,
          })
        }
      }),
      []
    )

    const onReset = useCallback(() => {
      props.reset()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickDelete = useCallback(() => {
      setConFirmDelete(!confirmDelete)
    }, [confirmDelete])

    const onDelete = useCallback(async () => {
      await props.delete(props.match.params.id)
      props.history.push('/partner')

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id])

    const onClickIsEdit = () => {}

    const keyGenerate = useCallback(() => {
      props.change('apiKey', uuidv4())
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      props.fetchById(props.match.params.id)
      props.fetchConfig()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])

    return {
      loading,
      setLoading,
      textHeader: 'Edit page',
      onSave,
      isEdit: false,
      onClickIsEdit,
      onReset,
      onClickDelete,
      onDelete,
      confirmDelete,
      keyGenerate,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      buttonSubmit,
    }
  })
)

export default enhancer(Partner)
