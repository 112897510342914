import React, { useState, useCallback, useEffect } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../../enhancers'
import { FastField } from 'formik'
import {
  Input,
  Select,
  Params,
} from '../../../../../../components/formikComponent'
import { get } from 'lodash'

const ParamsOther = props => {
  if (
    props.whatBenefit === 'room' ||
    props.whatBenefit === 'room_icu' ||
    props.whatBenefit === 'doctor_visit' ||
    props.whatBenefit === 'other_opd_benefit_per_time' ||
    props.whatBenefit === 'x_ray_and_lab_opd'
  ) {
    switch (props.formula) {
      case 'amount':
        return (
          <FastField
            name={`${props.name}.maxPerDayOrYear`}
            className="form-control width-150 m-l-10"
            component={Input}
            placeholder="สูงสุด (วัน/ปี)"
            readOnly={props.isEdit}
          />
        )
      case 'limit_deduct_amount':
        return (
          <>
            <FastField
              name={`${props.name}.maxPerDayOrYear`}
              className="form-control width-150 m-l-10"
              component={Input}
              placeholder="สูงสุด (วัน/ปี)"
              readOnly={props.isEdit}
            />
          </>
        )

      case 'description':
        return (
          <>
            <FastField
              name={`${props.name}.maxPerDayOrYear`}
              className="form-control width-150 m-l-10"
              component={Input}
              placeholder="สูงสุด (วัน/ปี)"
              readOnly={props.isEdit}
            />
          </>
        )

      default:
        return (
          <>
            <FastField
              name={`${props.name}.maxPerDayOrYear`}
              className="form-control width-150 m-l-10"
              component={Input}
              placeholder="สูงสุด (วัน/ปี)"
              readOnly={props.isEdit}
            />
          </>
        )
    }
  } else if (props.whatBenefit === 'continuous_treatment_opd') {
    switch (props.formula) {
      case 'amount':
        return (
          <>
            <FastField
              name={`${props.name}.maxPerDayOrYear`}
              className="form-control width-150 m-l-10"
              component={Input}
              placeholder="สูงสุด (วัน/ปี)"
              readOnly={props.isEdit}
            />
            <FastField
              name={`${props.name}.maxPerRoundOrYear`}
              className="form-control width-150 m-l-10"
              component={Input}
              placeholder="สูงสุด (ครั้ง/ปี)"
              readOnly={props.isEdit}
            />
          </>
        )
      case 'limit_deduct_amount':
        return (
          <>
            <FastField
              name={`${props.name}.maxPerDayOrYear`}
              className="form-control width-150 m-l-10"
              component={Input}
              placeholder="สูงสุด (วัน/ปี)"
              readOnly={props.isEdit}
            />
            <FastField
              name={`${props.name}.maxPerRoundOrYear`}
              className="form-control width-150 m-l-10"
              component={Input}
              placeholder="สูงสุด (ครั้ง/ปี)"
              readOnly={props.isEdit}
            />
          </>
        )

      case 'description':
        return (
          <>
            <FastField
              name={`${props.name}.maxPerDayOrYear`}
              className="form-control width-150 m-l-10"
              component={Input}
              placeholder="สูงสุด (วัน/ปี)"
              readOnly={props.isEdit}
            />
            <FastField
              name={`${props.name}.maxPerRoundOrYear`}
              className="form-control width-150 m-l-10"
              component={Input}
              placeholder="สูงสุด (ครั้ง/ปี)"
              readOnly={props.isEdit}
            />
          </>
        )

      default:
        return (
          <>
            <FastField
              name={`${props.name}.maxPerDayOrYear`}
              className="form-control width-150 m-l-10"
              component={Input}
              placeholder="สูงสุด (วัน/ปี)"
              readOnly={props.isEdit}
            />
            <FastField
              name={`${props.name}.maxPerRoundOrYear`}
              className="form-control width-150 m-l-10"
              component={Input}
              placeholder="สูงสุด (ครั้ง/ปี)"
              readOnly={props.isEdit}
            />
          </>
        )
    }
  }

  return null
}

const SetupHealthBenefit = props => (
  <div key={props.name}>
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '40% 150px auto',
        gridGap: '10px',
        marginTop: '15px',
      }}
    >
      <div style={{ gridColumnStart: '1', gridColumnEnd: '2' }}>
        <FastField
          name={`${props.name}[${[props.index]}].type`}
          className="form-control"
          component={Input}
          readOnly={props.isEdit}
          hidden
        />
        <div className="h-100 d-flex align-items-center">
          {props.item.label}
        </div>
      </div>
      <div style={{ gridColumnStart: '2', gridColumnEnd: '3' }}>
        <FastField
          name={`${props.name}[${[props.index]}].formula`}
          component={Select}
          options={get(props, 'configs.formula.options', [])}
          onChange={props.onChangeFormula}
          className="width-150"
          readOnly={props.isEdit}
          isClearable={false}
          required
        />
      </div>
      <div style={{ gridColumnStart: '3', gridColumnEnd: '4' }}>
        <div className="row">
          <Params
            configs={props.findParams()}
            name={`${props.name}[${[props.index]}]`}
            isEdit={props.isEdit}
            className="m-l-10"
          />
          <ParamsOther
            formula={props.formula}
            name={`${props.name}[${[props.index]}].params`}
            whatBenefit={props.whatBenefit}
            isEdit={props.isEdit}
          />
        </div>
      </div>
    </div>
  </div>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [formula, setFormula] = useState('')

    const findParams = useCallback(() => {
      const newParams = props.configs.formula.options.find(
        x => x.value === formula
      )
      return newParams ? newParams.params : []
    }, [formula, props.configs])

    useEffect(() => {
      setFormula(props.initialValueFormula)
    }, [props.initialValueFormula])

    const onChangeFormula = useCallback(e => {
      props.setFieldValue(`${props.name}[${[props.index]}].formula`, e.value)
      setFormula(e.value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
      loading,
      setLoading,
      formula,
      onChangeFormula,
      findParams,
    }
  })
)

export default enhancer(SetupHealthBenefit)
