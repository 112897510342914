import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Panel, PanelHeader } from '../../../../components/panel/panel.jsx'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../../enhancers/index.js'
import { Field, withFormik, Form, FieldArray, FastField } from 'formik'
import {
  Input,
  Select,
  Switch,
  CurrencyInput,
  InputMaxMin,
} from '../../../../components/formikComponent/index'
import SetupPremium from './setupPremium/index'
import SetupBenefit from './setupBenefit'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert'
import queryString from 'query-string'
import * as yup from 'yup'
import { isEmpty, get } from 'lodash'

export const validate = yup.object().shape({
  productName: yup.string().required(),
  companyId: yup.string().required(),
  minIncome: yup.number().required(),
  paymentPeriodType: yup.string().required(),
  paymentPeriodValue: yup.number().required(),
  protectPeriodType: yup.string().required(),
  protectPeriodValue: yup.number().required(),
  maleMinAgeYear: yup.number().required(),
  maleMaxAgeYear: yup.number().required(),
  maleExtendContract: yup.number().required(),
  femaleExtendContract: yup.number().required(),
  childExtendContract: yup.number().required(),
  femaleMinAgeYear: yup.number().required(),
  femaleMaxAgeYear: yup.number().required(),
  childMinAgeYear: yup.number().required(),
  childMaxAgeYear: yup.number().required(),
  paBenefits: yup
    .array()
    .of(
      yup.object().shape({
        minAge: yup.number().required(),
        maxAge: yup.number().required(),
        paGeneralBenefits: yup.array().of(
          yup.object().shape({
            params: yup
              .object()
              .nullable()
              .when('formula', {
                is: value => value === 'amount',
                then: yup.object().shape({ amount: yup.string().required() }),
              })
              .when('formula', {
                is: value => value === 'text',
                then: yup.object().shape({ text: yup.string().required() }),
              })
              .when('formula', {
                is: value => value === 'day',
                then: yup.object().shape({ day: yup.string().required() }),
              })
              .when('formula', {
                is: value => value === 'month',
                then: yup.object().shape({ month: yup.string().required() }),
              }),
          })
        ),
      })
    )
    .required(),
  paPremiumMasters: yup
    .array()
    .of(
      yup.object().shape({
        premiumName: yup.string().required(),
        type: yup.string().required(),
        paPeriods: yup
          .array()
          .of(
            yup.object().shape({
              period: yup.string().required(),
              paPremiumDetails: yup
                .array()
                .of(
                  yup.object().shape({
                    gender: yup.string().required(),
                    startAge: yup.number().required(),
                    endAge: yup.number().required(),
                    premium: yup.number().required(),
                    stamp: yup.number().required(),
                    tax: yup.number().required(),
                    premiumCf: yup.number().required(),
                  })
                )
                .required(),
            })
          )
          .required(),
      })
    )
    .required(),
})

export const Products = props => (
  <Form onSubmit={props.handleSubmit}>
    <ol className="breadcrumb float-xl-right">
      <li className="breadcrumb-item">
        <Link to="/products/pa">PA Product</Link>
      </li>
      <li className="breadcrumb-item active">
        {props.isNew ? 'Product New' : 'Product Edit'}
      </li>
    </ol>
    <h1 className="page-header">
      {props.textHeader}
      {/* <small>{props.textHeader}</small> */}
    </h1>
    <Panel style={{ padding: '16px' }}>
      <PanelHeader noButton={true}>
        <div className="d-flex justify-content-between">
          <div className="text-center p-t-10">{props.textHeader}</div>
          <div className="row">
            <div className="d-flex align-items-center">
              <Field
                name="status"
                component={Switch}
                className="form-control width-250"
                readOnly={!props.isNew}
                onClick={props.onClickStatus}
                type="checkbox"
              />
              {props.values.status && (
                <span className="label label-info m-r-3 width-40">online</span>
              )}
              {!props.values.status && (
                <span className="label label-danger m-r-3 width-40">
                  offine
                </span>
              )}
            </div>
            {props.isEdit && (
              <>
                <button
                  type="button"
                  className="btn btn-success width-60 m-r-10 m-l-10"
                  onClick={props.onClickIsEdit}
                  style={{ justifySelf: 'flex-end' }}
                >
                  Edit
                </button>
                <button
                  type="button"
                  className="btn btn-danger width-60"
                  onClick={props.onClickDelete}
                >
                  Delete
                </button>
              </>
            )}
            {!props.isEdit && (
              <>
                <button
                  type="button"
                  className="btn btn-primary width-60 m-r-10 m-l-10"
                  style={{ justifySelf: 'flex-end' }}
                  onClick={props.setConfirmUpdateToCon}
                >
                  Save
                </button>
                {!props.isNew && (
                  // <button
                  //   type="button"
                  //   className="btn btn-danger  width-60 "
                  //   onClick={props.onReset}
                  // >
                  //   Cancel
                  // </button>
                  <button
                    type="button"
                    className="btn btn-danger width-60"
                    onClick={props.onClickDelete}
                  >
                    Delete
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </PanelHeader>
      <div style={{ padding: '16px' }}>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">Product Name</label>
          <div className="col-md-9">
            <Field
              name="productName"
              component={Input}
              type="text"
              className="form-control width-250"
              classNameReadOnly="width-300"
              placeholder="Product Name"
              readOnly={props.isEdit}
            />
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">Company</label>
          <div className="col-md-9">
            <Field
              name="companyId"
              component={Select}
              type="number"
              className="width-250"
              placeholder="company"
              classNameReadOnly="width-300"
              readOnly={props.isEdit}
              options={
                (props.configs.companies && props.configs.companies.options) ||
                []
              }
              disabled={props.isCompany}
              isClearable={false}
            />
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">รายได้ขั้นต่ำ</label>
          <div className="col-md-9">
            <Field
              name="minIncome"
              component={CurrencyInput}
              type="text"
              className="form-control width-250"
              classNameReadOnly="width-300"
              placeholder="รายได้ขั้นต่ำ"
              readOnly={props.isEdit}
            />
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">ระยะเวลาจ่ายเบี้ย</label>
          <div className="col-md-9">
            <div className="input-group width-250">
              <Field
                name="paymentPeriodType"
                component={Select}
                classNameReadOnly="width-100 p-t-15"
                type="number"
                width="105px"
                readOnly={props.isEdit}
                options={get(
                  props,
                  'configs.paProduct.paymentPeriodType.options',
                  []
                )}
                isClearable={false}
              />

              <Field
                name="paymentPeriodValue"
                component={Input}
                classNameReadOnly="width-100 p-t-10"
                type="number"
                className="form-control"
                placeholder="ระยะเวลาจ่ายเบี้ย"
                readOnly={props.isEdit}
                style={{ height: '38px', width: '135px', marginLeft: '10px' }}
              />
            </div>
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">ระยะเวลาคุ้มครอง</label>
          <div className="col-md-9">
            <div className="input-group  width-250">
              <Field
                name="protectPeriodType"
                component={Select}
                classNameReadOnly="width-100"
                width="105px"
                readOnly={props.isEdit}
                options={get(
                  props,
                  'configs.paProduct.protectPeriodType.options',
                  []
                )}
                isClearable={false}
              />

              <Field
                name="protectPeriodValue"
                component={Input}
                classNameReadOnly="width-100 p-t-15"
                type="number"
                className="form-control"
                style={{ height: '38px', width: '135px', marginLeft: '10px' }}
                placeholder="ระยะเวลาคุ้มครอง"
                readOnly={props.isEdit}
              />
            </div>
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">อายุรับสมัครชาย</label>
          <div className="row p-l-20">
            <FastField
              name="maleMinAgeYear"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="ปี"
              min={0}
              max={99}
            />
            <FastField
              name="maleMinAgeMonth"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="เดือน"
              min={0}
              max={11}
            />
            <div className="f-s-10 m-l-10 m-r-10 d-flex align-items-center">
              -
            </div>
            <FastField
              name="maleMaxAgeYear"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="ปี"
              min={0}
              max={99}
            />
            <FastField
              name="maleMaxAgeMonth"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="เดือน"
              min={0}
              max={11}
            />
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">
            ต่ออายุสัญญาได้ถึง (ชาย)
          </label>
          <div className="col-md-9">
            <Field
              name="maleExtendContract"
              component={Input}
              type="number"
              className="form-control width-250"
              classNameReadOnly="width-300"
              placeholder="ต่ออายุสัญญาได้ถึง"
              readOnly={props.isEdit}
            />
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">อายุรับสมัครหญิง</label>
          <div className="row p-l-20">
            <FastField
              name="femaleMinAgeYear"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="ปี"
              min={0}
              max={99}
            />
            <FastField
              name="femaleMinAgeMonth"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="เดือน"
              min={0}
              max={11}
            />
            <div className="f-s-10 m-l-10 m-r-10 d-flex align-items-center">
              -
            </div>
            <FastField
              name="femaleMaxAgeYear"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="ปี"
              min={0}
              max={99}
            />
            <FastField
              name="femaleMaxAgeMonth"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="เดือน"
              min={0}
              max={11}
            />
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">
            ต่ออายุสัญญาได้ถึง (หญิง)
          </label>
          <div className="col-md-9">
            <Field
              name="femaleExtendContract"
              component={Input}
              type="number"
              className="form-control width-250"
              classNameReadOnly="width-300"
              placeholder="ต่ออายุสัญญาได้ถึง"
              readOnly={props.isEdit}
            />
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">อายุรับสมัครเด็ก</label>
          <div className="row p-l-20">
            <FastField
              name="childMinAgeYear"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="ปี"
              min={0}
              max={99}
            />
            <FastField
              name="childMinAgeMonth"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="เดือน"
              min={0}
              max={11}
            />
            <div className="f-s-10 m-l-10 m-r-10 d-flex align-items-center">
              -
            </div>
            <FastField
              name="childMaxAgeYear"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="ปี"
              min={0}
              max={99}
            />
            <FastField
              name="childMaxAgeMonth"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="เดือน"
              min={0}
              max={11}
            />
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">
            ต่ออายุสัญญาได้ถึง (เด็ก)
          </label>
          <div className="col-md-9">
            <Field
              name="childExtendContract"
              component={Input}
              type="number"
              className="form-control width-250"
              classNameReadOnly="width-300"
              placeholder="ต่ออายุสัญญาได้ถึง"
              readOnly={props.isEdit}
            />
          </div>
        </div>
      </div>

      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title=""
          onConfirm={props.onClickconfirmUpdate}
          onCancel={props.setConfirmUpdateToCon}
        >
          ต้องการบันทึกใช่หรือไม่ ?
        </SweetAlert>
      )}

      {props.confirmDelete && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={props.onDelete}
          onCancel={props.onClickDelete}
        >
          ต้องการลบใช่หรือไม่ ?
        </SweetAlert>
      )}
      {props.confirmChangeStatus && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="Are you sure update status?"
          onConfirm={props.onUpdateStatus}
          onCancel={props.onStatusCancel}
        >
          ต้องการ update status ใช่หรือไม่ ?
        </SweetAlert>
      )}
    </Panel>
    <div className="row p-l-10 p-r-10">
      <div className="width-full (100%)">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: props.activeTab === '1' })}
              onClick={props.onClickToggleTab('1')}
            >
              <span className="d-sm-none">Tab 1</span>
              <span className="d-sm-block d-none">Premium</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: props.activeTab === '2' })}
              onClick={props.onClickToggleTab('2')}
            >
              <span className="d-sm-none">Tab 2</span>
              <span className="d-sm-block d-none">Benefit</span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={props.activeTab}>
          <TabPane tabId="1">
            <FieldArray
              name="paPremiumMasters"
              render={fieldArrayProps => (
                <SetupPremium {...fieldArrayProps} configs={props.configs} />
              )}
            />
          </TabPane>
          <TabPane tabId="2">
            <SetupBenefit configs={props.configs} />
          </TabPane>
        </TabContent>
      </div>
    </div>
    <button type="submit" ref={props.buttonSubmit} hidden />
  </Form>
)

const enhancer = compose(
  withRouter,
  withStores(stores => {
    return {
      fetchById: stores.paProductStore.fetchById,
      current: stores.paProductStore.current,
      update: stores.paProductStore.update,
      delete: stores.paProductStore.delete,
      configs: stores.paProductStore.configs,
      fetchConfig: stores.paProductStore.fetchConfig,
      clearData: stores.paProductStore.clearData,
      initialValues: {},
      updateStatusPublish: stores.paProductStore.updateStatusPublish,
      updateStatusUnpublished: stores.paProductStore.updateStatusUnpublished,
    }
  }),
  withFormik({
    displayName: 'paProductEdit',
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, props) => {
      let customStatus = ''
      if (values.status) {
        customStatus = 'online'
      } else {
        customStatus = 'offline'
      }

      await props.props.update(props.props.match.params.id, {
        ...values,
        status: customStatus,
      })
    },
    mapPropsToValues: props => {
      const values = props.current

      let status = false
      if (props.current.status === 'online') {
        status = true
      }

      return {
        ...values,
        maleRegister: [values.maleMinAge, values.maleMaxAge],
        femaleRegister: [values.femaleMinAge, values.femaleMaxAge],
        childRegister: [values.childMinAge, values.childMaxAge],
        status: status,
      }
    },
    enableReinitialize: true,
    validationSchema: validate,
  }),
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [confirmDelete, setconFirmDelete] = useState(false)
    const [activeTab, setActiveTab] = useState('1')
    const [confirmChangeStatus, setConfirmChangeStatus] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    useEffect(() => {
      if (isEmpty(props.errors) && props.isSubmitting) {
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSubmitting, props.errors])

    useEffect(() => {
      props.fetchById(props.match.params.id)
      props.fetchConfig()
      return () => {
        props.clearData()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onUpdateStatus = useCallback(() => {
      if (props.values.status) {
        props.updateStatusPublish(props.match.params.id, 'online')
      } else {
        props.updateStatusUnpublished(props.match.params.id, 'offline')
      }

      setConfirmChangeStatus(!confirmChangeStatus)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmChangeStatus, props.status])

    const onStatusCancel = useCallback(() => {
      props.setFieldValue('status', props.initialValues.status)
      setConfirmChangeStatus(!confirmChangeStatus)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmChangeStatus])

    const onClickStatus = useCallback(() => {
      setConfirmChangeStatus(!confirmChangeStatus)
    }, [confirmChangeStatus])

    const onClickIsEdit = useCallback(() => {
      // props.setFieldValue('status', props.initialValues.status)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialValues])

    const onClickDelete = useCallback(() => {
      setconFirmDelete(!confirmDelete)
    }, [confirmDelete])

    const onReset = useCallback(() => {
      props.resetForm()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onDelete = useCallback(async () => {
      await props.delete(props.match.params.id)

      props.history.push('/products/pa')

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id])

    const onClickToggleTab = useCallback(
      index => () => {
        setActiveTab(index)
      },
      []
    )

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])

    return {
      loading,
      setLoading,
      onClickIsEdit,
      onDelete,
      confirmDelete,
      onClickDelete,
      activeTab,
      onClickToggleTab: onClickToggleTab,
      textHeader: 'Products Detail',
      isCompany: queryString.parse(props.location.search).company_id,
      onReset,
      onClickStatus,
      confirmChangeStatus,
      onUpdateStatus,
      onStatusCancel,
      isEdit: false,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      buttonSubmit,
    }
  })
)

export default enhancer(Products)
