import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Field, Form } from 'formik'
import { Select } from '../../../../../components/formikComponent'
import { cloneDeep, get } from 'lodash'
import { withFormik } from 'formik'
import * as yup from 'yup'

const ModalAdd = props => (
  <Modal isOpen={props.open} toggle={props.setOpen}>
    <Form onSubmit={props.handleSubmit}>
      <ModalHeader toggle={props.setOpen}>เพิ่มเงื่อนไข</ModalHeader>

      <ModalBody>
        <div className="col-md-9">
          <Field name="type" component={Select} options={props.options} />
        </div>
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-white" onClick={props.setOpen}>
          Close
        </button>
        <button
          type="submit"
          className="btn btn-success"
          // onClick={props.handleSubmit}
        >
          Action
        </button>
      </ModalFooter>
    </Form>
  </Modal>
)

const validate = yup.object().shape({
  type: yup.string().required(),
})

const enhancer = compose(
  withFormik({
    displayName: 'addBenefit',
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, props) => {
      if (values.type !== 'completeTheContract')
        props.props.push({ type: values.type, benefitDetails: [{}] })
      props.props.setOpen()
    },
    mapPropsToValues: props => {
      return {}
    },
    enableReinitialize: true,
    validationSchema: validate,
  }),
  withHooks(props => {
    const [options, setOptions] = useState([])

    useEffect(() => {
      const newOptions = cloneDeep(
        get(props, 'configs.product.benefit.type.options', [])
      )

      props.fields &&
        props.fields.forEach(item => {
          const index = newOptions.findIndex(x => x.value === item.type)

          if (index !== undefined) newOptions.splice(index, 1)
        })

      if (newOptions) setOptions([...newOptions])
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fields, props.configs])

    return { options }
  })
)

export default enhancer(ModalAdd)
