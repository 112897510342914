import React, { useState } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../enhancers'
import Table from './TablePage'

const CollapseComponent = props => (
  <div className="d-flex flex-row">
    <div className="m-r-10 w-50">
      <Table
        riskType={props.riskType}
        gender="male"
        isEdit={props.isEdit}
        setFieldValue={props.setFieldValue}
      />
    </div>
    <div className="w-50">
      <Table
        riskType={props.riskType}
        gender="female"
        isEdit={props.isEdit}
        setFieldValue={props.setFieldValue}
      />
    </div>
  </div>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)

    return {
      loading,
      setLoading,
    }
  })
)

export default enhancer(CollapseComponent)
