import React from 'react'
import { Route, withRouter, Redirect, Switch } from 'react-router-dom'
import routes from './../../config/page-route.jsx'
import { PageSettings } from './../../config/page-settings.js'
import { AUTH_TOKEN_KEY } from '../../common/api.js'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../enhancers/index.js'
import { useState } from 'react'
import { useEffect } from 'react'

function setTitle(path, routeArray) {
  var pageTitle
  for (var i = 0; i < routeArray.length; i++) {
    if (routeArray[i].path === path) {
      pageTitle = 'Insurance Product | ' + routeArray[i].title
    }
  }
  document.title = pageTitle ? pageTitle : 'Insurance Product'
}

class Content extends React.Component {
  componentDidMount() {
    setTitle(this.props.history.location.pathname, routes)
  }
  componentWillMount() {
    this.props.history.listen(() => {
      setTitle(this.props.history.location.pathname, routes)
    })
  }

  render() {
    return (
      <PageSettings.Consumer>
        {({
          pageContentFullWidth,
          pageContentClass,
          pageContentInverseMode,
        }) => (
          <div
            className={
              'content ' +
              (pageContentFullWidth ? 'content-full-width ' : '') +
              (pageContentInverseMode ? 'content-inverse-mode ' : '') +
              pageContentClass
            }
          >
            {!localStorage.getItem(AUTH_TOKEN_KEY) && (
              <Redirect push to="/auth/login" />
            )}
            <Switch>
              {routes.map((route, index) => {
                const checkRoles =
                  this.props.current.roles &&
                  this.props.current.roles.some(roles =>
                    route.roles.some(item => roles === item)
                  )
                if (checkRoles || route.roles.length === 0) {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  )
                }

                return null
              })}
              {/* <Redirect push to="/dashboard" /> */}
            </Switch>
          </div>
        )}
      </PageSettings.Consumer>
    )
  }
}

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    auth: stores.app.auth,
    current: stores.app.current,
    logout: stores.app.logout,
    token: stores.app.token,
  })),

  withHooks(props => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      if (
        props.location.pathname !== '/auth/login' &&
        props.location.pathname !== '/auth/register'
      ) {
        props.auth()
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.token])

    // const r = ['product_admin']
    // const checkRoles =
    //   props.current.roles &&
    //   props.current.roles.some(roles => roles === r.find(item => item))

    // console.log(checkRoles)

    return { loading, setLoading }
  })
)

export default enhancer(Content)
