import React from 'react'
import { withHooks } from '../../../../enhancers/index.js'
import { compose } from 'recompose'
import ModalNewFinancialCompany from './ModalNewFinancialCompany'
import CollapseFinancialBudgetCompany from './CollapseFinancialBudgetCompany'

export const FinancialBudgetCompanyShow = props => (
  <div>
    {props.form.values.financialBudgetCompanies &&
      props.form.values.financialBudgetCompanies.map((item, itemIndex) => (
        <CollapseFinancialBudgetCompany
          key={`${props.name}${itemIndex}`}
          itemIndex={itemIndex}
          name={props.name}
          form={props.form}
          configs={props.configs.options}
          isEditMainForm={props.isEditMainForm}
          remove={props.remove}
          item={item}
          setFieldValue={props.form.setFieldValue}
          scoreStarConfigs={props.scoreStarConfigs}
          values={props.values}
        />
      ))}
    <ModalNewFinancialCompany
      ModalIsOpen={props.ModalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      toggle={props.onClickOpenOrCloseModal}
      configs={props.filterCompanyConfigs}
      push={props.push}
      scoreStarConfigs={props.scoreStarConfigs}
      weightCar={props.values.weightCar}
    />
  </div>
)

const enhancer = compose(
  withHooks(props => {
    return {}
  })
)

export default enhancer(FinancialBudgetCompanyShow)
