import React, { useState, useCallback, useEffect } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../../enhancers'
import { FastField } from 'formik'
import {
  Input,
  Select,
  Params,
} from '../../../../../../components/formikComponent'
import { get } from 'lodash'

const Condition = props => {
  if (
    props.type === 'number_specific_disease_ci' ||
    props.type === 'after_specific_disease_ci' ||
    props.type === 'normal_dead_benefit_ci' ||
    props.type === 'specific_dead_benefit_ci'
  ) {
    return null
  }
  switch (props.formula) {
    case 'percent':
      return (
        <div className="input-group width-150">
          <FastField
            name={`${props.name}.params.conditionValue`}
            className="form-control"
            component={Input}
          />

          <div className="input-group-append">
            <span className="input-group-text">%</span>
          </div>
        </div>
      )

    case 'number':
      return (
        <div className="input-group width-150">
          <FastField
            name={`${props.name}.params.conditionValue`}
            className="form-control"
            component={Input}
          />

          <div className="input-group-append">
            <span className="input-group-text">%</span>
          </div>
        </div>
      )

    default:
      return null
  }
}

const SetupHealthBenefit = props => (
  <tr className="table-active">
    <td style={{ paddingTop: '20px' }}>
      <FastField
        name={`${props.name}.type`}
        component={Input}
        className="width-150"
        hidden
      />
      {props.typeItem.label}
    </td>
    <td style={{ textAlign: 'center', paddingTop: '20px' }}>
      <FastField
        required
        name={`${props.name}.formula`}
        component={Select}
        options={get(props, 'options', [])}
        onChange={props.onChangeFormula}
        className="width-150"
        disabled={props.typeItem.value === 'number_specific_disease_ci'}
        isClearable={false}
      />
    </td>
    <td
      style={{ textAlign: 'center', paddingTop: '20px' }}
      className="valign-middle"
    >
      <Params configs={props.findParams()} name={`${props.name}`} />
    </td>
    <td style={{ textAlign: 'center', paddingTop: '20px' }}>
      <Condition
        formula={props.formula}
        type={props.typeItem.value}
        name={props.name}
      />
    </td>
  </tr>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [formula, setFormula] = useState('')

    const findParams = useCallback(() => {
      const newParams = props.options.find(x => x.value === formula)
      return newParams ? newParams.params : []
    }, [formula, props.options])

    useEffect(() => {
      setFormula(props.initialValueFormula)
    }, [props.initialValueFormula])

    const onChangeFormula = useCallback(e => {
      props.setFieldValue(`${props.name}.formula`, e.value)
      setFormula(e.value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
      loading,
      setLoading,
      formula,
      onChangeFormula,
      findParams: findParams || [],
    }
  })
)

export default enhancer(SetupHealthBenefit)
