import React, { useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withHooks } from '../../../../enhancers/index.js'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Form, Field, withFormik } from 'formik'
import {
  Input,
  Select,
  CurrencyInput,
} from '../../../../components/formikComponent'
import { isEmpty } from 'lodash'
import { CalculateInsurerScoreData } from './CollapseFinancialBudgetCompany'
import * as yup from 'yup'

const validate = yup.object().shape({
  companyId: yup.string().required(),
  tca: yup.number().required(),
  tcr: yup.number().required(),
  il: yup.number().required(),
  tl: yup.number().required(),
})

export const ModalNewFinancialCompany = props => (
  <Modal isOpen={props.ModalIsOpen} toggle={props.toggle} size="lg">
    <Form onSubmit={props.handleSubmit}>
      <ModalHeader toggle={props.toggle}>
        New FinancialBudgetCompany
      </ModalHeader>
      <ModalBody>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">Company Name</label>
          <div className="col-md-9">
            <Field
              name="companyId"
              component={Select}
              className="width-250"
              options={props.configs}
            />
          </div>
        </div>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">TCA</label>
          <div className="col-md-9">
            <Field
              name="tca"
              component={CurrencyInput}
              className="form-control width-250 text-black"
              classNameReadOnly="width-300 text-black"
              placeholder="TCA"
            />
          </div>
        </div>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">TCR</label>
          <div className="col-md-9">
            <Field
              name="tcr"
              component={CurrencyInput}
              className="form-control width-250 text-black"
              classNameReadOnly="width-300 text-black"
              placeholder="TCR"
            />
          </div>
        </div>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">IL</label>
          <div className="col-md-9">
            <Field
              name="il"
              component={CurrencyInput}
              className="form-control width-250 text-black"
              classNameReadOnly="width-300 text-black"
              placeholder="IL"
            />
          </div>
        </div>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">TL</label>
          <div className="col-md-9">
            <Field
              name="tl"
              component={CurrencyInput}
              className="form-control width-250 text-black"
              classNameReadOnly="width-300 text-black"
              placeholder="TL"
            />
          </div>
        </div>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">CAR SCORE</label>
          <div className="col-md-9">
            <Field
              name="carScore"
              component={Input}
              className="form-control width-250 text-black"
              classNameReadOnly="width-300 text-black"
              placeholder="CAR SCORE"
              disabled
            />
          </div>
        </div>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">ILR SCORE</label>
          <div className="col-md-9">
            <Field
              name="ilrScore"
              component={Input}
              className="form-control width-250 text-black"
              classNameReadOnly="width-300 text-black"
              placeholder="ILR SCORE"
              disabled
            />
          </div>
        </div>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">INSURER SCORE</label>
          <div className="col-md-9">
            <Field
              name="insurerScore"
              component={Input}
              className="form-control width-250 text-black"
              classNameReadOnly="width-300 text-black"
              placeholder="INSURER SCORE"
              disabled
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button type="submit" className="btn btn-success width-60">
          save
        </button>
        <button
          type="button"
          className="btn bg-danger text-white"
          onClick={props.toggle}
        >
          Close
        </button>
      </ModalFooter>
    </Form>
  </Modal>
)

const enhancer = compose(
  withRouter,
  withFormik({
    displayName: 'createFinancialBudgetCompany',
    handleSubmit: async (values, props) => {
      if (!isEmpty(values)) {
        props.props.push({ ...values })
        props.props.setModalIsOpen(false)
        props.resetForm()
      }
    },
    mapPropsToValues: props => {
      return {}
    },
    enableReinitialize: true,
    validationSchema: validate,
  }),
  withHooks(props => {
    const toggle = useCallback(() => {
      props.resetForm()
      props.setModalIsOpen(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.ModalIsOpen])

    useEffect(() => {
      const weight = props.weightCar
      const datas = CalculateInsurerScoreData(
        props.values.tca,
        props.values.tcr,
        props.values.il,
        props.values.tl,
        weight
      )
      props.setFieldValue(`carScore`, datas.carScore)
      props.setFieldValue(`ilrScore`, datas.ilrScore)
      props.setFieldValue(`insurerScore`, datas.insurerScore)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      props.values.tca,
      props.values.tcr,
      props.values.il,
      props.values.tl,
      props.weightCar,
    ])

    return {
      toggle,
    }
  })
)

export default enhancer(ModalNewFinancialCompany)
