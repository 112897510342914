import React, { useState, useCallback } from 'react'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../enhancers'
import { Field } from 'redux-form'
import { Input } from '../../../components/index.js'

const JobLevel = props => (
  <div>
    <button type="button" className="btn btn-success" onClick={props.addItem}>
      Add Job Level
    </button>
    {props.fields.map((name, index) => (
      <div className="border border-light p-10 m-t-10">
        <div className="d-flex justify-content-end">
          <button
            type="button"
            onClick={props.removeItem(index)}
            className="btn btn-danger btn-sm m-r-2"
            style={{ zIndex: '11' }}
          >
            Remove
          </button>
        </div>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">Type</label>
          <div className="col-md-9">
            <Field
              name={`${name}.type`}
              component={Input}
              type="text"
              className="form-control width-250"
            />
          </div>
        </div>
      </div>
    ))}
  </div>
)

const enhancer = compose(
  withStores(stores => ({})),

  withHooks(props => {
    const [loading, setLoading] = useState(false)

    const addItem = useCallback(() => {
      props.fields.push()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const removeItem = useCallback(
      index => () => props.fields.remove(index),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    return {
      loading,
      setLoading,
      addItem,
      removeItem,
    }
  })
)

export default enhancer(JobLevel)
