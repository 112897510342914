import React, { useState, useCallback } from 'react'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../../enhancers'
import { FastField } from 'formik'
import { Input, Select } from '../../../../components/formikComponent/index.js'
import { fill } from 'lodash'

const TablePage = props => (
  <div>
    <div className="height-50 text-white width-full m-0 bg-dark d-flex justify-content-center align-items-center border-bottom">
      {props.gender}
    </div>
    <div className="height-50 text-white width-full m-0 bg-dark d-flex">
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          width: '10%',
        }}
      >
        Age
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          width: '22%',
        }}
      >
        l(x)
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          width: '22%',
        }}
      >
        d(x)
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          width: '22%',
        }}
      >
        eo(x)
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          width: '22%',
        }}
      >
        1000q(x)
      </div>
    </div>
    {fill(Array(100), '').map((item, index) => (
      <div
        className="text-black-darker width-full m-0 border-bottom p-b-10 d-flex"
        // style={{
        //   borderBottom: '1px solid #EAEAEA',
        //   paddingBottom: '10px',
        // }}
        key={index}
      >
        <div
          className="d-flex justify-content-center align-items-center m-t-10"
          style={{
            width: '10%',
          }}
        >
          <FastField
            name={`mortalityBaseTables.${props.riskType}.${props.gender}[${index}].age`}
            component={Input}
            type="text"
            className="form-control "
            classNameReadOnly="width-150"
            placeholder="age"
            required
            hidden
          />
          <FastField
            name={`mortalityBaseTables.${props.riskType}.${props.gender}[${index}].riskType`}
            component={Select}
            type="text"
            className="d-none"
            classNameReadOnly="width-150"
            placeholder="Year"
            required
            options={[
              {
                label: 'Common',
                value: 'common',
              },
              {
                label: 'Industry',
                value: 'industry',
              },
            ]}
            hidden
          />
          <FastField
            name={`mortalityBaseTables.${props.riskType}.${props.gender}[${index}].gender`}
            component={Select}
            type="text"
            className="d-none"
            classNameReadOnly="width-150"
            options={[
              {
                label: 'ผู้ชาย',
                value: 'male',
              },
              {
                label: 'ผู้หญิง',
                value: 'female',
              },
            ]}
            required
            hidden
          />
          {index}
        </div>

        <div
          className="d-flex justify-content-center align-items-center m-t-10"
          style={{
            width: '22%',
          }}
        >
          <FastField
            name={`mortalityBaseTables.${props.riskType}.${props.gender}[${index}].lx`}
            component={Input}
            type="text"
            className="form-control"
            classNameReadOnly="f-s-15 text-center"
            placeholder="l(x)"
            readOnly={props.isEdit}
            onChange={e => {
              const value = e.target.value
              const newValue = value.split(' ').map(item => item.split('\t'))

              if (newValue.length <= 1) {
                props.setFieldValue(
                  `mortalityBaseTables.${props.riskType}.${props.gender}[${index}].lx`,
                  e.target.value
                )
              }
              e.preventDefault()
              newValue.forEach((item, indexOnChange) => {
                if (item.length === 5) {
                  props.setFieldValue(
                    `mortalityBaseTables.${props.riskType}.${props.gender}[${indexOnChange}].lx`,
                    Number(item[1].replace(/[^0-9.-]+/g, ''))
                  )
                  props.setFieldValue(
                    `mortalityBaseTables.${props.riskType}.${props.gender}[${indexOnChange}].dx`,
                    Number(item[2].replace(/[^0-9.-]+/g, ''))
                  )
                  props.setFieldValue(
                    `mortalityBaseTables.${props.riskType}.${props.gender}[${indexOnChange}].eox`,
                    Number(item[3].replace(/[^0-9.-]+/g, ''))
                  )
                  props.setFieldValue(
                    `mortalityBaseTables.${props.riskType}.${props.gender}[${indexOnChange}]._1kqx`,
                    Number(item[4].replace(/[^0-9.-]+/g, ''))
                  )
                }
              })
            }}
            required
          />
        </div>

        <div
          className="d-flex justify-content-center align-items-center m-t-10"
          style={{
            width: '22%',
          }}
        >
          <FastField
            name={`mortalityBaseTables.${props.riskType}.${props.gender}[${index}].dx`}
            component={Input}
            type="text"
            className="form-control"
            classNameReadOnly="f-s-15 text-center"
            placeholder="d(x)"
            required
            readOnly={props.isEdit}
          />
        </div>
        <div
          className="d-flex justify-content-center align-items-center m-t-10"
          style={{
            width: '22%',
          }}
        >
          <FastField
            name={`mortalityBaseTables.${props.riskType}.${props.gender}[${index}].eox`}
            component={Input}
            type="text"
            className="form-control"
            classNameReadOnly="f-s-15 text-center"
            placeholder="eo(x)"
            required
            readOnly={props.isEdit}
          />
        </div>
        <div
          className="d-flex justify-content-center align-items-center m-t-10"
          style={{
            width: '22%',
          }}
        >
          <FastField
            name={`mortalityBaseTables.${props.riskType}.${props.gender}[${index}]._1kqx`}
            component={Input}
            type="text"
            className="form-control"
            classNameReadOnly="f-s-15 text-center"
            placeholder="1000q(x)"
            required
            readOnly={props.isEdit}
          />
        </div>
      </div>
    ))}
  </div>
)

const enhancer = compose(
  withStores(stores => ({})),

  withHooks(props => {
    const [activeTab, setActiveTab] = useState(0)

    const togglePill = useCallback(
      tab => {
        if (activeTab !== tab) {
          setActiveTab(tab)
        }
      },
      [activeTab]
    )
    return { togglePill, activeTab }
  })
)

export default enhancer(TablePage)
