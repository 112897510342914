import React from 'react'
import Home from './../pages/Home.js'
import Login from '../pages/auth/login.jsx'
import Register from '../pages/auth/register'

import LifeProducts from '../pages/product/life/index/index'
import LifeProduct from '../pages/product/life/show/index'
import LifeNewProduct from '../pages/product/life/new/index'

import HealthProducts from '../pages/product/health/index/index'
import HealthProduct from '../pages/product/health/show/index'
import HealthNewProduct from '../pages/product/health/new/index'

import Searchs from '../pages/search/index/index'
// import LifeSearch from '../pages/search/show/life/index'
// import HealthSearch from '../pages/search/show/health/HsHealth'

import Companys from '../pages/company/index/index.js'
import NewCompany from '../pages/company/new/index'
import Company from '../pages/company/show/index'

import Profiles from '../pages/profile/index/index'
import Profile from '../pages/profile/show/index'

import Dashboard from '../pages/Dashboard'

import Partners from '../pages/partner/index'
import Partner from '../pages/partner/show'
import NewPartner from '../pages/partner/new'

import MortalityBases from '../pages/scoreStar/mortalityBaseTable/index'
import MortalityBase from '../pages/scoreStar/mortalityBaseTable/showFormik'
import NewMortalityBase from '../pages/scoreStar/mortalityBaseTable/new'

import PaProducts from '../pages/product/pa/index'
import PaProduct from '../pages/product/pa/show'
import PaProductsNew from '../pages/product/pa/new'

import TaProducts from '../pages/product/ta/index'
import TaProduct from '../pages/product/ta/show'
import TaProductsNew from '../pages/product/ta/new'

import ScoreStarConfig from '../pages/scoreStar/Config'

import Financials from '../pages/scoreStar/financialBudget/index'
import Financial from '../pages/scoreStar/financialBudget/show'
import FinancialsNew from '../pages/scoreStar/financialBudget/new'

const routes = [
  {
    path: '/',
    exact: true,
    title: 'Home',
    roles: ['new'],
    component: () => <Home />,
  },
  {
    path: '/auth/login',
    title: 'Login',
    roles: [],
    component: () => <Login />,
  },
  {
    path: '/auth/register',
    title: 'register',
    roles: [],
    component: () => <Register />,
  },
  {
    path: '/products/life/new',
    title: 'NewProduct',
    exact: true,
    roles: ['god', 'product_admin'],
    component: () => <LifeNewProduct />,
  },
  {
    path: '/products/life/:id',
    title: 'product',
    exact: true,
    roles: ['god', 'product_admin'],
    component: () => <LifeProduct />,
  },
  {
    path: '/products/life',
    title: 'product',
    exact: true,
    roles: ['god', 'product_admin'],
    component: () => <LifeProducts />,
  },
  {
    path: '/products/health/new',
    title: 'NewProduct',
    exact: true,
    roles: ['god', 'product_admin'],
    component: () => <HealthNewProduct />,
  },
  {
    path: '/products/health/:id',
    title: 'product',
    exact: true,
    roles: ['god', 'product_admin'],
    component: () => <HealthProduct />,
  },
  {
    path: '/products/health',
    title: 'product',
    exact: true,
    roles: ['god', 'product_admin'],
    component: () => <HealthProducts />,
  },

  {
    path: '/search',
    title: 'search',
    exact: true,
    roles: ['god', 'product_admin'],
    component: () => <Searchs />,
  },

  {
    path: '/company/new',
    title: 'Company New',
    exact: true,
    roles: ['god', 'product_admin'],
    component: () => <NewCompany />,
  },
  {
    path: '/company/:id',
    title: 'Company Edit',
    roles: ['god', 'product_admin'],
    component: () => <Company />,
  },
  {
    path: '/company',
    title: 'company',
    exact: true,
    roles: ['god', 'product_admin'],
    component: () => <Companys />,
  },
  {
    path: '/user/:id',
    title: 'user',
    exact: true,
    roles: [],
    component: () => <Profile />,
  },
  {
    path: '/user',
    title: 'user',
    exact: true,
    roles: ['god'],
    component: () => <Profiles />,
  },

  {
    path: '/dashboard',
    title: 'dashboard',
    exact: true,
    roles: ['god', 'product_admin'],
    component: () => <Dashboard />,
  },
  {
    path: '/partner',
    title: 'partner',
    exact: true,
    roles: ['god'],
    component: () => <Partners />,
  },
  {
    path: '/partner/new',
    title: 'partner',
    exact: true,
    roles: ['god'],
    component: () => <NewPartner />,
  },
  {
    path: '/partner/:id',
    title: 'partner',
    exact: true,
    roles: ['god'],
    component: () => <Partner />,
  },
  {
    path: '/score_star/mortality_base',
    title: 'mortality_base',
    exact: true,
    roles: ['god'],
    component: () => <MortalityBases />,
  },
  {
    path: '/score_star/mortality_base/new',
    title: 'mortality_base',
    exact: true,
    roles: ['god'],
    component: () => <NewMortalityBase />,
  },
  {
    path: '/score_star/mortality_base/:id',
    title: 'mortality_base',
    exact: true,
    roles: ['god'],
    component: () => <MortalityBase />,
  },
  {
    path: '/products/pa',
    title: 'PAProduct',
    exact: true,
    roles: ['god', 'product_admin'],
    component: () => <PaProducts />,
  },
  {
    path: '/products/pa/new',
    title: 'PAProduct',
    exact: true,
    roles: ['god', 'product_admin'],
    component: () => <PaProductsNew />,
  },
  {
    path: '/products/pa/:id',
    title: 'PAProduct',
    exact: true,
    roles: ['god', 'product_admin'],
    component: () => <PaProduct />,
  },
  {
    path: '/products/ta',
    title: 'PAProduct',
    exact: true,
    roles: ['god', 'product_admin'],
    component: () => <TaProducts />,
  },
  {
    path: '/products/ta/new',
    title: 'PAProduct',
    exact: true,
    roles: ['god', 'product_admin'],
    component: () => <TaProductsNew />,
  },
  {
    path: '/products/ta/:id',
    title: 'PAProduct',
    exact: true,
    roles: ['god', 'product_admin'],
    component: () => <TaProduct />,
  },
  {
    path: '/score_star/config',
    title: 'config',
    exact: true,
    roles: ['god'],
    component: () => <ScoreStarConfig />,
  },
  {
    path: '/score_star/financial_budget',
    title: 'Financial Budget',
    exact: true,
    roles: ['god'],
    component: () => <Financials />,
  },
  {
    path: '/score_star/financial_budget/new',
    title: 'Financial Budget',
    exact: true,
    roles: ['god'],
    component: () => <FinancialsNew />,
  },
  {
    path: '/score_star/financial_budget/:id',
    title: 'Financial Budget',
    exact: true,
    roles: ['god'],
    component: () => <Financial />,
  },
]

export default routes
