import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../../enhancers/index.js'
import { get } from 'lodash'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'

const ModalDetail = props => (
  <div>
    <Nav className="mb-3" pills>
      <NavItem>
        <NavLink
          className={classnames({
            active: props.activeTab === 0,
          })}
          onClick={props.onClickToggleTab(0)}
        >
          <span className="d-sm-none">เบี้ยที่ต้องชำระ</span>
          <span className="d-sm-block d-none">เบี้ยที่ต้องชำระ</span>
        </NavLink>
      </NavItem>
      {get(props, 'current.benefit', []).map((benefitItem, benefitIndex) => (
        <NavItem>
          <NavLink
            className={classnames({
              active: props.activeTab === benefitIndex + 1,
            })}
            onClick={props.onClickToggleTab(benefitIndex + 1)}
          >
            <span className="d-sm-none">{benefitItem.label}</span>
            <span className="d-sm-block d-none">{benefitItem.label}</span>
          </NavLink>
        </NavItem>
      ))}
    </Nav>
    <TabContent
      className="p-15 rounded bg-white mb-4"
      activeTab={props.activeTab}
    >
      <TabPane tabId={0}>
        {get(props, 'current.premiumTable', []).map(premiumTableItem => (
          <div className="m-b-30">
            <div
              style={{ background: '#1d2226' }}
              className="d-flex height-40 text-white justify-content-center align-items-center"
            >
              {premiumTableItem.label}
            </div>
            <div
              style={{ background: '#1d2226' }}
              className="d-flex height-40 text-white m-b-20"
            >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: '25%' }}
              >
                เบี้ย (บาท)
              </div>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: '25%' }}
              >
                แสตมป์ (บาท)
              </div>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: '25%' }}
              >
                ภาษี (บาท)
              </div>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: '25%' }}
              >
                เบี้ยสุทธิ (บาท)
              </div>
            </div>
            {premiumTableItem.label === 'เบี้ยแบบรายเที่ยว' &&
              premiumTableItem.value.map((detail, detailIndex) => (
                <div className="d-flex m-b-20" key={detailIndex}>
                  <div className="text-center m-r-10" style={{ width: '25%' }}>
                    {detail.premium}
                  </div>
                  <div className="text-center m-r-10" style={{ width: '25%' }}>
                    {detail.tax}
                  </div>
                  <div className="text-center m-r-10" style={{ width: '25%' }}>
                    {detail.stamp}
                  </div>
                  <div className="text-center m-r-10" style={{ width: '25%' }}>
                    {detail.premiumCf}
                  </div>
                </div>
              ))}

            {premiumTableItem.label === 'เบี้ยแบบรายเดือน' && (
              <div className="d-flex m-b-20">
                <div className="text-center m-r-10" style={{ width: '25%' }}>
                  {premiumTableItem.value.premium}
                </div>
                <div className="text-center m-r-10" style={{ width: '25%' }}>
                  {premiumTableItem.value.tax}
                </div>
                <div className="text-center m-r-10" style={{ width: '25%' }}>
                  {premiumTableItem.value.stamp}
                </div>
                <div className="text-center m-r-10" style={{ width: '25%' }}>
                  {premiumTableItem.value.premiumCf}
                </div>
              </div>
            )}
          </div>
        ))}
      </TabPane>
      {get(props, 'current.benefit', []).map((benefitItem, benefitIndex) => (
        <TabPane tabId={benefitIndex + 1}>
          {benefitItem.details.map(benefitItemHeader => (
            <div>
              <h4 className="m-t-10 f-w-600">{benefitItemHeader.label}</h4>
              {benefitItemHeader.details.map(benefitDetail => (
                <div className="m-t-20 p-l-40" key={benefitDetail.label}>
                  <h5>{benefitDetail.label}</h5>
                  <div className="f-s-14 p-l-10">{benefitDetail.value}</div>
                </div>
              ))}
            </div>
          ))}
        </TabPane>
      ))}
    </TabContent>
  </div>
)

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    current: stores.search.current,
    searchConfig: get(stores, 'search.searchConfig', []),
    taFetchById: stores.search.taFetchById,
  })),
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(0)

    useEffect(() => {
      delete props.searchConfig.lifeConfigs
      delete props.searchConfig.healthConfigs
      delete props.searchConfig.taConfigs
      delete props.searchConfig.paConfigs
      delete props.searchConfig.productConfigs
      delete props.searchConfig.partnerConfigs

      if (props.productId) {
        delete props.searchConfig.companies
        delete props.searchConfig.product

        props.taFetchById(props.productId, {
          ...props.searchConfig,
          productType: 'TaProduct',
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.productId])

    const onClickToggleTab = useCallback(
      index => () => {
        setActiveTab(index)
      },
      []
    )

    return {
      loading,
      setLoading,
      onClickToggleTab,
      activeTab,
      textHeader: ['ผลประโยชน์ในประเทศ', 'ผลประโยชน์ต่างประเทศ'],
    }
  })
)

export default enhancer(ModalDetail)
