import { useState, useEffect, useCallback, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../../enhancers/index.js'
import { get } from 'lodash'
import { Products } from '../../life/show/index'
import { withFormik } from 'formik'

// const validate = values => {
//   const errors = {}

//   if (!values.maleRegister) {
//     errors.maleRegister = 'reqiured'
//   }

//   if (!values.femaleRegister) {
//     errors.femaleRegister = 'reqiured'
//   }

//   const stepValidates = []
//   values.steps &&
//     values.steps.forEach((item, itemIndex) => {
//       stepValidates.push({})
//       if (item) {
//         if (
//           item.min &&
//           item.max &&
//           values.maxSumInsured &&
//           values.minSumInsured
//         ) {
//           if (
//             parseInt(item.min) < parseInt(values.minSumInsured) ||
//             parseInt(item.min) > parseInt(item.max) ||
//             parseInt(item.min) > parseInt(values.maxSumInsured)
//           ) {
//             stepValidates[itemIndex].min = 'isValid'
//           }

//           if (
//             parseInt(item.max) < parseInt(values.minSumInsured) ||
//             parseInt(item.min) > parseInt(item.max) ||
//             parseInt(item.max) > parseInt(values.maxSumInsured)
//           ) {
//             stepValidates[itemIndex].max = 'isValid'
//           }
//         }
//       }
//     })

//   if (!values.premiumMasters || values.premiumMasters.length === 0) {
//     errors.premiumValidate = 'required'
//   }

//   if (stepValidates.length > 0) {
//     errors.steps = stepValidates
//   }
//   return errors
// }

const enhancer = compose(
  withRouter,
  withStores(stores => {
    return {
      create: stores.healthProductStore.create,
      fetchConfig: stores.healthProductStore.fetchConfig,
      configs: stores.healthProductStore.configs,
    }
  }),
  withFormik({
    displayName: 'editLifeProduct',
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, props) => {
      values.premiumMasters &&
        values.premiumMasters.forEach(
          (premiumMastersItem, premiumMastersIndex) => {
            const newPeriods = []

            Object.keys(premiumMastersItem.periods).forEach(item => {
              const arr = []
              premiumMastersItem.periods[item].premiumDetails &&
                premiumMastersItem.periods[item].premiumDetails.forEach(
                  (item, index) => {
                    if (item) {
                      const newItem = {
                        ...item,
                        age: index,
                      }
                      arr.push(newItem)
                    }
                  }
                )
              newPeriods.push({
                period: premiumMastersItem.periods[item].period,
                premiumOfSumInsured:
                  premiumMastersItem.periods[item].premiumOfSumInsured,
                premiumDetails: arr,
                discounts: premiumMastersItem.periods[item].discounts,
              })
            })

            values.premiumMasters[premiumMastersIndex].periods = newPeriods
          }
        )

      let customStatus = ''
      if (values.status) {
        customStatus = 'online'
      } else {
        customStatus = 'offline'
      }

      if (values.type === 'hs') {
        delete values.ciBenefit
      } else if (values.type === 'ci') {
        delete values.hsBenefit
      }

      await props.props.create({
        ...values,
        maleMinAge: values.maleRegister && values.maleRegister[0],
        maleMaxAge: values.maleRegister && values.maleRegister[1],
        femaleMinAge: values.femaleRegister && values.femaleRegister[0],
        femaleMaxAge: values.femaleRegister && values.femaleRegister[1],
        status: customStatus,
        pensionsStartAge: values.pensions && values.pensions[0],
        pensionsEndAge: values.pensions && values.pensions[1],
        // benefit: newBenefit,
      })

      props.props.history.push('/products/health')
    },
    mapPropsToValues: props => {
      const configs = get(props, 'configs', {})
      const productSetting = {}

      let hsBenefitNew = {}
      configs.product &&
        Object.keys(configs.product.hsBenefit).forEach(
          (hsBenefitItem, hsBenefitItemIndex) => {
            let newArr = []
            configs.product.hsBenefit[hsBenefitItem].type.options.forEach(
              (hsBenefitDetail, hsBenefitDetailIndex) => {
                newArr.push({
                  type: hsBenefitDetail.value,
                  formula: 'none',
                })
              }
            )
            hsBenefitNew[`${hsBenefitItem}s`] = newArr
          }
        )

      let ciBenefitNew = {}
      configs.product &&
        Object.keys(configs.product.ciBenefit).forEach(
          (ciBenefitItem, hsBenefitItemIndex) => {
            let newArr = []
            configs.product.ciBenefit[ciBenefitItem].type.options.forEach(
              (ciBenefitDetail, hsBenefitDetailIndex) => {
                if (ciBenefitDetail.value === 'number_specific_disease_ci') {
                  newArr.push({
                    type: ciBenefitDetail.value,
                    formula: 'number',
                  })
                }
                if (
                  ciBenefitDetail.value === 'beginning_benefit_ci' ||
                  ciBenefitDetail.value === 'middle_benefit_ci' ||
                  ciBenefitDetail.value === 'severe_benefit_ci' ||
                  ciBenefitDetail.value === 'benefit_specific_disease_ci'
                ) {
                  newArr.push({
                    type: ciBenefitDetail.value,
                    formula: 'percent',
                  })
                }
                if (
                  ciBenefitDetail.value === 'dead' ||
                  ciBenefitDetail.value === 'after_specific_disease_ci' ||
                  ciBenefitDetail.value === 'normal_dead_benefit_ci' ||
                  ciBenefitDetail.value === 'specific_dead_benefit_ci'
                ) {
                  newArr.push({
                    type: ciBenefitDetail.value,
                    formula: 'none',
                  })
                }
              }
            )
            ciBenefitNew[`${ciBenefitItem}s`] = newArr
          }
        )

      productSetting.hsBenefit = hsBenefitNew
      productSetting.ciBenefit = ciBenefitNew
      return {
        ...productSetting,
      }
    },
    enableReinitialize: true,
  }),
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState('1')
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    useEffect(() => {
      props.fetchConfig()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (queryString.parse(props.location.search).company_id) {
        props.change(
          'companyId',
          queryString.parse(props.location.search).company_id
        )
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialValues])

    const onClickToggleTab = index => () => {
      setActiveTab(index)
    }

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])

    return {
      loading,
      setLoading,
      readOnly: false,
      isNew: true,
      textHeader: 'New Page',
      activeTab,
      onClickToggleTab,
      isCompany: queryString.parse(props.location.search).company_id,
      isHealth: true,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      buttonSubmit,
    }
  })
)

export default enhancer(Products)
