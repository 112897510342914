import React, { useState } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../../enhancers'
import { camelCase } from 'lodash'
import FieldComponent from './FieldComponent'

const SetupHealthBenefit = props => (
  <div>
    <table className="table m-b-0 table-bordered">
      <thead>
        <tr style={{ background: '#1d2226' }}>
          <th className="text-center text-silver-lighter p-b-15" />
          <th className="text-center text-silver-lighter p-b-15">Unit</th>
          <th className="text-center text-silver-lighter p-b-15">ผลประโยชน์</th>
          <th className="text-center text-silver-lighter p-b-15">เงื่อนไข</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(props.configs).map((ciBenefitKey, ciBenefitKeyIndex) => (
          <React.Fragment key={ciBenefitKey}>
            {props.configs[ciBenefitKey].type.options.map(
              (typeItem, typeIndex) => (
                <FieldComponent
                  key={typeIndex}
                  name={`ciBenefit.${camelCase(ciBenefitKey)}s[${typeIndex}]`}
                  options={props.configs[ciBenefitKey].formula.options}
                  typeOptions={props.configs[ciBenefitKey].type.options}
                  className="width-150"
                  ciBenefitKey={ciBenefitKey}
                  ciBenefitKeyIndex={ciBenefitKeyIndex}
                  typeIndex={typeIndex}
                  typeItem={typeItem}
                  initialValues={props.initialValues}
                  initialValueFormula={
                    props.initialValues.ciBenefit[`${ciBenefitKey}s`] &&
                    props.initialValues.ciBenefit[`${ciBenefitKey}s`][typeIndex]
                      .formula
                  }
                  setFieldValue={props.setFieldValue}
                />
              )
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  </div>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)

    return {
      loading,
      setLoading,
    }
  })
)

export default enhancer(SetupHealthBenefit)
