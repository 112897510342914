import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../../enhancers/index.js'
import { get } from 'lodash'

const LifeDetail = props => (
  <div className="table-responsive">
    <table className="table table-striped table-bordered m-b-0">
      <thead>
        <tr style={{ background: '#1d2226' }}>
          <th
            colSpan={11}
            style={{ textAlign: 'center' }}
            className="text-silver-lighter align-middle"
          >
            อัตราเบี้ยต่อเงินประกัน
          </th>
        </tr>
        <tr style={{ background: '#1d2226' }}>
          <th
            style={{ textAlign: 'center' }}
            className="text-silver-lighter align-middle"
            rowSpan={2}
          >
            อายุ
          </th>
          <th
            style={{ textAlign: 'center' }}
            className="text-silver-lighter align-middle"
            rowSpan={2}
          >
            งวด
          </th>
          <th
            style={{ textAlign: 'center' }}
            className="text-silver-lighter align-middle"
            rowSpan={2}
          >
            เบี้ย
          </th>
          <th
            style={{ textAlign: 'center' }}
            className="text-silver-lighter align-middle"
            rowSpan={2}
          >
            เบี้ยประกันภัยสะสม
          </th>
          <th
            style={{ textAlign: 'center' }}
            className="text-silver-lighter align-middle"
            rowSpan={2}
          >
            เบี้ยจากส่วนลด
          </th>
          <th
            style={{ textAlign: 'center' }}
            className="text-silver-lighter"
            colSpan={2}
          >
            เงินคืนรายงวด
          </th>
          <th
            style={{ textAlign: 'center' }}
            className="text-silver-lighter"
            colSpan={2}
          >
            คุ้มครองเมื่อเสียชีวิต
          </th>
          {props.current.type === 'annuity' && (
            <th
              style={{ textAlign: 'center' }}
              className="text-silver-lighter"
              rowSpan={2}
            >
              การันตีตาย
            </th>
          )}
        </tr>
        <tr style={{ background: '#1d2226' }}>
          <th className="text-center text-silver-lighter">อัตรา (%)</th>
          <th className="text-center text-silver-lighter">จำนวนเงิน </th>
          <th className="text-center text-silver-lighter">อัตรา (%) </th>
          <th className="text-center text-silver-lighter">
            จำนวนเงินเอาประกันภัย
          </th>
        </tr>
      </thead>
      <tbody>
        {props.current.benefitTable &&
          props.current.benefitTable.table.map(item => (
            <tr key={item.age}>
              <td className="text-center">{item.age}</td>
              <td className="text-center">{item.round}</td>
              <td className="text-center">
                {item.premium
                  ? new Intl.NumberFormat().format(item.premium)
                  : ''}
              </td>
              <td className="text-center">
                {item.sumPremium
                  ? new Intl.NumberFormat().format(item.sumPremium)
                  : ''}
              </td>
              <td className="text-center">
                {item.discount ? item.discount : ''}
              </td>
              <td className="text-center">{item.compensationRate}</td>
              <td className="text-center">
                {item.compensation
                  ? new Intl.NumberFormat().format(item.compensation)
                  : ''}
              </td>
              <td className="text-center">{item.coverageForDeathRate}</td>
              <td className="text-center">
                {item.coverageForDeath
                  ? new Intl.NumberFormat().format(item.coverageForDeath)
                  : ''}
              </td>
              {props.current.type === 'annuity' && (
                <td className="text-center">
                  {item.deathProtectGuarantee
                    ? new Intl.NumberFormat().format(item.deathProtectGuarantee)
                    : ''}
                </td>
              )}
            </tr>
          ))}
        {/* <tr>
          <td colSpan={4} className="text-center">รวม</td>
          <td className="text-center">10</td>
          <td className="text-center">20</td>
        </tr> */}
        <tr>
          <td colSpan={5} className="text-center">
            ผลประโยชน์เมื่อครบสัญญา
          </td>
          <td className="text-center">
            {get(props, 'current.benefitTable.sumCompleteTheContractRate', '')}
          </td>
          <td className="text-center">
            {new Intl.NumberFormat().format(
              get(props, 'current.benefitTable.sumCompleteTheContract', '')
            )}
          </td>
        </tr>
        <tr>
          <td colSpan={5} className="text-center">
            สรุปผลประโยชน์ตลอดสัญญา
          </td>
          <td className="text-center">
            {get(props, 'current.benefitTable.sumCompensationRate', '')}
          </td>
          <td className="text-center">
            {new Intl.NumberFormat().format(
              get(props, 'current.benefitTable.sumCompensation', '')
            )}
          </td>
        </tr>
        <tr>
          <td colSpan={5} className="text-center">
            IRR
          </td>
          <td colSpan={2} className="text-center">
            {get(props, 'current.benefitTable.irr', '')}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    lifeFetchById: stores.search.lifeFetchById,
    current: stores.search.current,
    // searchConfig: get(stores, 'search.searchConfig', []),
  })),
  withHooks(props => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      delete props.searchConfig.lifeConfigs
      delete props.searchConfig.healthConfigs
      delete props.searchConfig.taConfigs
      delete props.searchConfig.paConfigs
      delete props.searchConfig.productConfigs
      delete props.searchConfig.partnerConfigs

      if (props.productId) {
        delete props.searchConfig.companies
        delete props.searchConfig.product

        props.lifeFetchById(props.productId, {
          ...props.searchConfig,
          productType: 'LifeProduct',
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.productId])

    return {
      loading,
      setLoading,
    }
  })
)

export default enhancer(LifeDetail)
