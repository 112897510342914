import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../../../enhancers'
import { Select } from '../../../../../components/formikComponent/index'
import { withFormik, Field } from 'formik'

const AddPeriod = props => (
  <>
    {props.options.length > 0 ? (
      <div
        className="input-group m-b-5 m-l-10"
        style={{ width: 'fit-content' }}
      >
        <Field
          name={`period`}
          component={Select}
          type="text"
          className="width-150 m-t-5 text-black"
          options={props.options}
          isClearable={false}
        />
        <div className="input-group-append " style={{ zIndex: 0 }}>
          <button
            type="button"
            className="btn btn-success btn-icon  btn-lg  m-t-5 "
            style={{ height: '38px' }}
            onClick={props.handleSubmit}
          >
            <i className="ion-md-add" />
          </button>
        </div>
      </div>
    ) : null}
  </>
)

const enhancer = compose(
  withStores(stores => ({
    configs: stores.paProductStore.configs,
  })),
  withFormik({
    displayName: 'paProductEdit',
    handleSubmit: async (values, props) => {
      const index =
        props.props.fields.length > 0 &&
        props.props.fields.findIndex(
          x => parseInt(x.period) < parseInt(values.period)
        )
      if ((!index || index === -1) && index !== 0) {
        props.props.push(values)
        props.props.setActiveTab(props.fields.length)
      } else {
        props.props.insert(index, values)
        props.props.setActiveTab(index)
      }
    },
    mapPropsToValues: props => {
      return {}
    },
    enableReinitialize: true,
  }),
  withHooks(props => {
    const [options, setOptions] = useState([])

    useEffect(() => {
      let newOptions = [
        { label: 'ราย 12 เดือน', value: '12' },
        { label: 'ราย 6 เดือน', value: '6' },
        { label: 'ราย 3 เดือน', value: '3' },
        { label: 'ราย 1 เดือน', value: '1' },
      ]
      props.fields.length > 0 &&
        props.fields.forEach(item => {
          const index = newOptions.findIndex(x => x.value === item.period)
          newOptions.splice(index, 1)
        })

      setOptions([...newOptions])
      if (newOptions.length > 0)
        props.setFieldValue('period', newOptions[0].value)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fields.length])

    return { options }
  })
)

export default enhancer(AddPeriod)
