import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Panel, PanelHeader } from '../../../../components/panel/panel.jsx'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../../enhancers/index.js'

import {
  Input,
  Select,
  Switch,
  CheckboxGroup,
  Radio,
  InputMaxMin,
} from '../../../../components/formikComponent/index'
import SetupPremium from './setupPremium/index'
import SetupGlobalBenefit from './globalBenefit'
import SetupLocalBenefit from './localBenefit'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert'
import queryString from 'query-string'
import { get, camelCase } from 'lodash'
import { withFormik, Field, Form, FastField } from 'formik'

export const Products = props => (
  <Form onSubmit={props.handleSubmit}>
    <ol className="breadcrumb float-xl-right">
      <li className="breadcrumb-item">
        <Link to="/products/ta">Ta Product</Link>
      </li>
      <li className="breadcrumb-item active">
        {props.isNew ? 'Product New' : 'Product Edit'}
      </li>
    </ol>
    <h1 className="page-header">
      {props.textHeader}
      {/* <small>{props.textHeader}</small> */}
    </h1>
    <Panel style={{ padding: '16px' }}>
      <PanelHeader noButton={true}>
        <div className="d-flex justify-content-between">
          <div className="text-center p-t-10">{props.textHeader}</div>
          <div className="row">
            <div className="d-flex align-items-center">
              <Field
                name="status"
                component={Switch}
                readOnly={!props.isNew}
                onChange={props.onClickStatus}
              />
              {props.status && (
                <span className="label label-info m-r-3 width-40">online</span>
              )}
              {!props.status && (
                <span className="label label-danger m-r-3 width-40">
                  offine
                </span>
              )}
            </div>
            {props.isEdit && (
              <>
                <button
                  type="button"
                  className="btn btn-success width-60 m-r-10 m-l-10"
                  onClick={props.onClickIsEdit}
                  style={{ justifySelf: 'flex-end' }}
                >
                  Edit
                </button>
                <button
                  type="button"
                  className="btn btn-danger width-60"
                  onClick={props.onClickDelete}
                >
                  Delete
                </button>
              </>
            )}
            {!props.isEdit && (
              <>
                <button
                  type="button"
                  className="btn btn-primary  width-60 m-r-10 m-l-10"
                  style={{ justifySelf: 'flex-end' }}
                  onClick={props.setConfirmUpdateToCon}
                >
                  Save
                </button>
                {!props.isNew && (
                  // <button
                  //   type="button"
                  //   className="btn btn-danger  width-60 "
                  //   onClick={props.onReset}
                  // >
                  //   Cancel
                  // </button>
                  <button
                    type="button"
                    className="btn btn-danger width-60 "
                    onClick={props.onClickDelete}
                  >
                    Delete
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </PanelHeader>
      <div style={{ padding: '16px' }}>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">Product Name</label>
          <div className="col-md-9">
            <FastField
              name="productName"
              component={Input}
              type="text"
              className="form-control width-250"
              classNameReadOnly="width-300"
              placeholder="Product Name"
              required
              readOnly={props.isEdit}
            />
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">Company</label>
          <div className="col-md-9">
            <Field
              name="companyId"
              component={Select}
              type="number"
              className=" width-250"
              placeholder="company"
              classNameReadOnly="width-300"
              required
              readOnly={props.isEdit}
              options={
                (props.configs.companies && props.configs.companies.options) ||
                []
              }
              disabled={props.isCompany}
            />
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">ระยะเวลาจ่ายเบี้ย</label>
          <div className="col-md-9">
            <div className="input-group width-250">
              <Field
                name="paymentPeriodType"
                component={Select}
                classNameReadOnly="width-100 p-t-15"
                type="number"
                width="105px"
                required
                readOnly={props.isEdit}
                options={get(
                  props,
                  'configs.taProduct.paymentPeriodType.options',
                  []
                )}
                isClearable={false}
              />

              <FastField
                name="paymentPeriodValue"
                component={Input}
                classNameReadOnly="width-100 p-t-10"
                type="number"
                className="form-control"
                placeholder="ระยะเวลาจ่ายเบี้ย"
                required
                readOnly={props.isEdit}
                style={{ height: '38px', width: '135px', marginLeft: '10px' }}
              />
            </div>
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">ระยะเวลาคุ้มครอง</label>
          <div className="col-md-9">
            <div className="input-group width-250">
              <Field
                name="protectPeriodType"
                component={Select}
                classNameReadOnly="width-100"
                type="number"
                width="105px"
                required
                readOnly={props.isEdit}
                options={get(
                  props,
                  'configs.taProduct.protectPeriodType.options',
                  []
                )}
                isClearable={false}
              />

              <FastField
                name="protectPeriodValue"
                component={Input}
                classNameReadOnly="width-100 p-t-15"
                type="number"
                className="form-control"
                placeholder="ระยะเวลาคุ้มครอง"
                required
                readOnly={props.isEdit}
                style={{ height: '38px', width: '135px', marginLeft: '10px' }}
              />
            </div>
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">อายุรับสมัครชาย</label>
          <div className="row p-l-20">
            <FastField
              name="maleMinAgeYear"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="ปี"
              min={0}
              max={99}
            />
            <FastField
              name="maleMinAgeMonth"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="เดือน"
              min={0}
              max={11}
            />
            <div className="f-s-10 m-l-10 m-r-10 d-flex align-items-center">
              -
            </div>
            <FastField
              name="maleMaxAgeYear"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="ปี"
              min={0}
              max={99}
            />
            <FastField
              name="maleMaxAgeMonth"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="เดือน"
              min={0}
              max={11}
            />
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">อายุรับสมัครหญิง</label>
          <div className="row p-l-20">
            <FastField
              name="femaleMinAgeYear"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="ปี"
              min={0}
              max={99}
            />
            <FastField
              name="femaleMinAgeMonth"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="เดือน"
              min={0}
              max={11}
            />
            <div className="f-s-10 m-l-10 m-r-10 d-flex align-items-center">
              -
            </div>
            <FastField
              name="femaleMaxAgeYear"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="ปี"
              min={0}
              max={99}
            />
            <FastField
              name="femaleMaxAgeMonth"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="เดือน"
              min={0}
              max={11}
            />
          </div>
        </div>

        <div className="form-group row m-b-15">
          <div className="col-sm-3 col-form-label">ประเภท</div>
          <div className="row m-l-10">
            <Field
              name="category"
              component={CheckboxGroup}
              className="form-control width-250 "
              options={get(props.configs, 'taProduct.category.options', [])}
              readOnly={props.isEdit}
              required
            />
          </div>
        </div>

        <div className="form-group row m-b-15">
          <div className="col-sm-3 col-form-label">รูปแบบการเดินทาง</div>
          <div className="row m-l-10">
            <Field
              name="type"
              component={CheckboxGroup}
              className="form-control width-250 "
              options={get(props.configs, 'taProduct.type.options', [])}
              readOnly={props.isEdit}
              required
            />
          </div>
        </div>

        <div className="form-group row m-b-15">
          <div className="col-sm-3 col-form-label">
            สามารถใช้ทำวีซ่าเชงเก้นได้
          </div>
          <div className="row m-l-10">
            <FastField
              name="visa"
              component={Radio}
              className="form-control width-250 "
              options={get(props.configs, 'taProduct.roles.options', [
                { label: 'ได้', value: true },
                { label: 'ไม่ได้', value: false },
              ])}
              readOnly={props.isEdit}
              required
            />
          </div>
        </div>
      </div>

      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title=""
          onConfirm={props.onClickconfirmUpdate}
          onCancel={props.setConfirmUpdateToCon}
        >
          ต้องการบันทึกใช่หรือไม่ ?
        </SweetAlert>
      )}

      {props.confirmDelete && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={props.onDelete}
          onCancel={props.onClickDelete}
        >
          ต้องการลบใช่หรือไม่ ?
        </SweetAlert>
      )}
      {props.confirmChangeStatus && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="Are you sure update status?"
          onConfirm={props.onUpdateStatus}
          onCancel={props.onStatusCancel}
        >
          ต้องการ update status ใช่หรือไม่ ?
        </SweetAlert>
      )}
    </Panel>
    <div className="row p-l-10 p-r-10">
      <div className="width-full (100%)">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: props.activeTab === '1' })}
              onClick={props.onClickToggleTab('1')}
            >
              <span className="d-sm-none">Tab 1</span>
              <span className="d-sm-block d-none">Premium</span>
            </NavLink>
          </NavItem>
          {(props.values.category || []).includes('local') && (
            <NavItem>
              <NavLink
                className={classnames({ active: props.activeTab === '2' })}
                onClick={props.onClickToggleTab('2')}
              >
                <span className="d-sm-none">Tab 2</span>
                <span className="d-sm-block d-none">Local Coverage</span>
              </NavLink>
            </NavItem>
          )}
          {(props.values.category || []).includes('global') && (
            <NavItem>
              <NavLink
                className={classnames({ active: props.activeTab === '3' })}
                onClick={props.onClickToggleTab('3')}
              >
                <span className="d-sm-none">Tab 3</span>
                <span className="d-sm-block d-none">Global Coverage</span>
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={props.activeTab}>
          <TabPane tabId="1">
            <SetupPremium
              name="premiumMasters"
              configs={props.configs}
              isHealth={props.isHealth}
              type={props.values.type || []}
              isEdit={props.isEdit}
              setFieldValue={props.setFieldValue}
              values={props.values}
            />
          </TabPane>
          <TabPane tabId="2">
            <SetupLocalBenefit
              configs={
                (props.configs.taProduct &&
                  props.configs.taProduct.taBenefit.taLocalBenefit) ||
                []
              }
              current={props.current}
              fieldArrayInitial={props.fieldArrayInitialLocal}
              initialValues={props.initialValues}
              isEdit={props.isEdit}
            />
          </TabPane>
          <TabPane tabId="3">
            <SetupGlobalBenefit
              configs={
                (props.configs.taProduct &&
                  props.configs.taProduct.taBenefit.taGlobalBenefit) ||
                []
              }
              current={props.current}
              fieldArrayInitial={props.fieldArrayInitialGlobal}
              initialValues={props.initialValues}
              isEdit={props.isEdit}
            />
          </TabPane>
        </TabContent>
      </div>
    </div>
    <button type="submit" ref={props.buttonSubmit} hidden />
  </Form>
)
// const validate = values => {
//   const errors = {}

//   if (!values.type || values.type.length === 0) {
//     errors.type = 'Please select at least 1 item.'
//   }

//   if (!values.category || values.category.length === 0) {
//     errors.category = 'Please select at least 1 item.'
//   }

//   if (typeof values.visa === 'undefined') {
//     errors.visa = 'Please select 1 item.'
//   }

//   const taBenefitValidate = { taGlobalBenefits: [], taLocalBenefits: [] }
//   values.taBenefit &&
//     values.category &&
//     values.category.includes('global') &&
//     values.taBenefit.taGlobalBenefits.forEach((item, itemIndex) => {
//       taBenefitValidate.taGlobalBenefits.push({})
//       if (item) {
//         if (values.femaleRegister && values.maleRegister) {
//           const min = Math.min(values.femaleRegister[0], values.maleRegister[0])
//           const max = Math.max(values.femaleRegister[1], values.maleRegister[1])

//           if (itemIndex === 0) {
//             if (
//               parseInt(item.minAge) < parseInt(min) ||
//               parseInt(item.minAge) > parseInt(max) ||
//               parseInt(item.minAge) > parseInt(item.maxAge) ||
//               !item.minAge
//             ) {
//               taBenefitValidate.taGlobalBenefits[itemIndex].minAge = 'isValid'
//             }
//             if (
//               parseInt(item.maxAge) < parseInt(min) ||
//               parseInt(item.maxAge) > parseInt(max) ||
//               parseInt(item.minAge) > parseInt(item.maxAge) ||
//               !item.maxAge
//             ) {
//               taBenefitValidate.taGlobalBenefits[itemIndex].maxAge = 'isValid'
//             }
//           } else {
//             if (
//               parseInt(item.minAge) < parseInt(min) ||
//               parseInt(item.minAge) > parseInt(max) ||
//               parseInt(item.minAge) > parseInt(item.maxAge)
//             ) {
//               taBenefitValidate.taGlobalBenefits[itemIndex].minAge = 'isValid'
//             }
//             if (
//               parseInt(item.maxAge) < parseInt(min) ||
//               parseInt(item.maxAge) > parseInt(max) ||
//               parseInt(item.minAge) > parseInt(item.maxAge)
//             ) {
//               taBenefitValidate.taGlobalBenefits[itemIndex].maxAge = 'isValid'
//             }
//           }
//         }
//       }
//     })

//   values.taBenefit &&
//     values.category &&
//     values.category.includes('local') &&
//     values.taBenefit.taLocalBenefits.forEach((item, itemIndex) => {
//       taBenefitValidate.taLocalBenefits.push({})
//       if (item) {
//         if (values.femaleRegister && values.maleRegister) {
//           const min = Math.min(values.femaleRegister[0], values.maleRegister[0])
//           const max = Math.max(values.femaleRegister[1], values.maleRegister[1])

//           if (itemIndex === 0) {
//             if (
//               parseInt(item.minAge) < parseInt(min) ||
//               parseInt(item.minAge) > parseInt(max) ||
//               parseInt(item.minAge) > parseInt(item.maxAge) ||
//               !item.minAge
//             ) {
//               taBenefitValidate.taLocalBenefits[itemIndex].minAge = 'isValid'
//             }
//             if (
//               parseInt(item.maxAge) < parseInt(min) ||
//               parseInt(item.maxAge) > parseInt(max) ||
//               parseInt(item.minAge) > parseInt(item.maxAge) ||
//               !item.maxAge
//             ) {
//               taBenefitValidate.taLocalBenefits[itemIndex].maxAge = 'isValid'
//             }
//           } else {
//             if (
//               parseInt(item.minAge) < parseInt(min) ||
//               parseInt(item.minAge) > parseInt(max) ||
//               parseInt(item.minAge) > parseInt(item.maxAge)
//             ) {
//               taBenefitValidate.taLocalBenefits[itemIndex].minAge = 'isValid'
//             }
//             if (
//               parseInt(item.maxAge) < parseInt(min) ||
//               parseInt(item.maxAge) > parseInt(max) ||
//               parseInt(item.minAge) > parseInt(item.maxAge)
//             ) {
//               taBenefitValidate.taLocalBenefits[itemIndex].maxAge = 'isValid'
//             }
//           }
//         }
//       }
//     })

//   const taPremiumMasterValidate = { taPremiumPerRound: [] }
//   values.taPremiumMaster &&
//     values.taPremiumMaster.taPremiumPerRound.forEach((item, itemIndex) => {
//       taPremiumMasterValidate.taPremiumPerRound.push({})
//       if (item) {
//         if (
//           item.startAge &&
//           item.endAge &&
//           values.taPremiumMaster.taPremiumPerRound[itemIndex - 1] &&
//           values.taPremiumMaster.taPremiumPerRound[itemIndex - 1].endAge
//         ) {
//           if (itemIndex === 0) {
//             if (parseInt(item.startAge) > parseInt(item.endAge)) {
//               taPremiumMasterValidate.taPremiumPerRound[itemIndex].startAge =
//                 'isValid'
//               taPremiumMasterValidate.taPremiumPerRound[itemIndex].endAge =
//                 'isValid'
//             }
//           } else {
//             if (
//               parseInt(item.startAge) <=
//               parseInt(
//                 values.taPremiumMaster.taPremiumPerRound[itemIndex - 1].endAge
//               )
//             ) {
//               taPremiumMasterValidate.taPremiumPerRound[itemIndex].startAge =
//                 'isValid'
//             }
//             if (parseInt(item.startAge) > parseInt(item.endAge)) {
//               taPremiumMasterValidate.taPremiumPerRound[itemIndex].startAge =
//                 'isValid'
//               taPremiumMasterValidate.taPremiumPerRound[itemIndex].endAge =
//                 'isValid'
//             }
//           }
//         }
//       }
//     })

//   if (
//     taBenefitValidate.taGlobalBenefits.length > 0 ||
//     taBenefitValidate.taLocalBenefits.length > 0
//   ) {
//     errors.taBenefit = taBenefitValidate
//   }

//   if (taPremiumMasterValidate.taPremiumPerRound.length > 0) {
//     errors.taPremiumMaster = taPremiumMasterValidate
//   }
//   return errors
// }

const enhancer = compose(
  withRouter,
  withStores(stores => {
    const configs = get(stores.taProductStore, 'configs', [])
    const productSetting = get(stores.taProductStore, 'current', {})

    const newInitailGlobal = {}
    configs.taProduct &&
      Object.keys(configs.taProduct.taBenefit.taGlobalBenefit).forEach(key => {
        const newProtech = []
        configs.taProduct.taBenefit.taGlobalBenefit[key].type.options.forEach(
          item => {
            newProtech.push({ type: item.value, formula: 'none' })
          }
        )
        newInitailGlobal[`${key}s`] = newProtech
      })

    const newInitailLocal = {}
    configs.taProduct &&
      Object.keys(configs.taProduct.taBenefit.taLocalBenefit).forEach(key => {
        const newProtech = []
        configs.taProduct.taBenefit.taLocalBenefit[key].type.options.forEach(
          item => {
            newProtech.push({ type: item.value, formula: 'none' })
          }
        )
        newInitailLocal[`${key}s`] = newProtech
      })

    if (
      productSetting.taBenefit &&
      productSetting.taBenefit.taGlobalBenefits.length === 0
    ) {
      productSetting.taBenefit.taGlobalBenefits.push({ ...newInitailGlobal })
    }

    if (
      productSetting.taBenefit &&
      productSetting.taBenefit.taLocalBenefits.length === 0
    ) {
      productSetting.taBenefit.taLocalBenefits.push({ ...newInitailLocal })
    }

    productSetting.maleRegister = [
      productSetting.maleMinAge,
      productSetting.maleMaxAge,
    ]

    productSetting.femaleRegister = [
      productSetting.femaleMinAge,
      productSetting.femaleMaxAge,
    ]

    if (productSetting.status === 'online') {
      productSetting.status = true
    } else {
      productSetting.status = false
    }

    if (
      productSetting.taPremiumMaster &&
      productSetting.taPremiumMaster.taPremiumPerRound.length === 0
    ) {
      productSetting.taPremiumMaster.taPremiumPerRound = [{}]
    }

    return {
      fetchById: stores.taProductStore.fetchById,
      current: stores.taProductStore.current,
      update: stores.taProductStore.update,
      delete: stores.taProductStore.delete,
      configs: stores.taProductStore.configs,
      fetchConfig: stores.taProductStore.fetchConfig,
      clearData: stores.taProductStore.clearData,
      initialValues: {
        ...productSetting,
      },
      updateStatusPublish: stores.taProductStore.updateStatusPublishDetail,
      updateStatusUnpublished:
        stores.taProductStore.updateStatusUnpublishedDetail,
      fieldArrayInitialGlobal: newInitailGlobal,
      fieldArrayInitialLocal: newInitailLocal,
    }
  }),
  withFormik({
    displayName: 'taProductEdit',
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, props) => {
      let customStatus = ''
      if (values.status) {
        customStatus = 'online'
      } else {
        customStatus = 'offline'
      }

      get(props.props.configs, 'taProduct.type.options', []).forEach(item => {
        if (!values.type.includes(item.value)) {
          delete values.taPremiumMaster[
            camelCase(`ta_premium_per_${item.value}`)
          ]
        }
      })

      get(props.props.configs, 'taProduct.category.options', []).forEach(
        item => {
          if (!values.category.includes(item.value)) {
            delete values.taBenefit[camelCase(`ta_${item.value}_benefits`)]
          }
        }
      )

      props.props.update(props.props.match.params.id, {
        ...values,
        maleMinAge: values.maleRegister && values.maleRegister[0],
        maleMaxAge: values.maleRegister && values.maleRegister[1],
        femaleMinAge: values.femaleRegister && values.femaleRegister[0],
        femaleMaxAge: values.femaleRegister && values.femaleRegister[1],
        status: customStatus,
      })
    },
    mapPropsToValues: props => {
      return {
        ...props.initialValues,
      }
    },
    enableReinitialize: true,
  }),
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [confirmDelete, setconFirmDelete] = useState(false)
    const [activeTab, setActiveTab] = useState('1')
    const [confirmChangeStatus, setConfirmChangeStatus] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    useEffect(() => {
      props.fetchById(props.match.params.id)
      props.fetchConfig()
      return () => {
        props.clearData()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onUpdateStatus = useCallback(() => {
      if (props.status) {
        props.updateStatusPublish(props.match.params.id, 'online')
      } else {
        props.updateStatusUnpublished(props.match.params.id, 'offline')
      }

      setConfirmChangeStatus(!confirmChangeStatus)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmChangeStatus, props.status])

    const onStatusCancel = useCallback(() => {
      props.setFieldValue('status', props.initialValues.status)
      setConfirmChangeStatus(!confirmChangeStatus)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmChangeStatus])

    const onClickStatus = useCallback(() => {
      setConfirmChangeStatus(!confirmChangeStatus)
    }, [confirmChangeStatus])

    const onClickIsEdit = useCallback(() => {
      props.setFieldValue('status', props.initialValues.status)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialValues])

    const onClickDelete = useCallback(() => {
      setconFirmDelete(!confirmDelete)
    }, [confirmDelete])

    const onReset = useCallback(() => {
      props.reset()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onDelete = useCallback(async () => {
      await props.delete(props.match.params.id)

      props.history.push('/products/ta')

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id])

    const onClickToggleTab = useCallback(
      index => () => {
        setActiveTab(index)
      },
      []
    )

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])

    return {
      loading,
      setLoading,
      onClickIsEdit,
      onDelete,
      confirmDelete,
      onClickDelete,
      activeTab,
      onClickToggleTab: onClickToggleTab,
      textHeader: 'Products Detail',
      isCompany: queryString.parse(props.location.search).company_id,
      onReset,
      onClickStatus,
      confirmChangeStatus,
      onUpdateStatus,
      onStatusCancel,
      category: props.category || [],
      isEdit: false,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      buttonSubmit,
    }
  })
)

export default enhancer(Products)
