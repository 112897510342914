import React, { useState } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import { FieldArray } from 'formik'
import FieldComponent from './FieldComponent'

const SetupGlobalBenefit = props => (
  <div>
    <div className="p-b-10">
      <FieldArray
        name="taBenefit.taLocalBenefits"
        render={fieldArrayProps => (
          <FieldComponent
            {...fieldArrayProps}
            configs={props.configs}
            fieldArrayInitial={props.fieldArrayInitial}
            initialValues={props.initialValues}
            isEdit={props.isEdit}
          />
        )}
      />
    </div>
  </div>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)

    return {
      loading,
      setLoading,
    }
  })
)

export default enhancer(SetupGlobalBenefit)
