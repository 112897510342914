import React, { useState, useCallback, useEffect } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import { FastField } from 'formik'
import { Input } from '../../../../../components/formikComponent'

const DailyPremium = props => (
  <div>
    <div
      style={{ background: '#1d2226' }}
      className="d-flex height-40 text-white"
    >
      <div className="f-s-20 f-w-700 d-flex justify-content-center align-items-center width-full">
        รายปี
      </div>
    </div>
    <div
      style={{ background: '#1d2226' }}
      className="d-flex height-40 text-white m-b-20"
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '25%' }}
      >
        เบี้ย (บาท)
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '25%' }}
      >
        แสตมป์ (บาท)
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '25%' }}
      >
        ภาษี (บาท)
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '25%' }}
      >
        เบี้ยสุทธิ (บาท)
      </div>
    </div>

    <div className="d-flex m-b-20">
      <div className="text-center m-r-10" style={{ width: '25%' }}>
        <FastField
          name={`${props.name}.premium`}
          component={Input}
          type="number"
          className="form-control"
          classNameReadOnly="f-s-15 text-center"
          placeholder="เบี้ย (บาท)"
          readOnly={props.isEdit}
          required
        />
      </div>
      <div className="text-center m-r-10" style={{ width: '25%' }}>
        <FastField
          name={`${props.name}.stamp`}
          component={Input}
          type="number"
          className="form-control"
          classNameReadOnly="f-s-15 text-center"
          placeholder="แสตมป์ (บาท)"
          readOnly={props.isEdit}
          required
        />
      </div>
      <div className="text-center m-r-10" style={{ width: '25%' }}>
        <FastField
          name={`${props.name}.tax`}
          component={Input}
          type="number"
          className="form-control"
          classNameReadOnly="f-s-15 text-center"
          placeholder="ภาษี (บาท)"
          readOnly={props.isEdit}
          required
        />
      </div>
      <div className="text-center m-r-10" style={{ width: '25%' }}>
        <FastField
          name={`${props.name}.premiumCf`}
          component={Input}
          type="number"
          className="form-control"
          classNameReadOnly="f-s-15 text-center"
          placeholder="เบี้ยสุทธิ (บาท)"
          readOnly={props.isEdit}
          required
        />
      </div>
    </div>
  </div>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      const premium =
        props.value && props.value.premium ? props.value.premium : 0
      const stamp = props.value && props.value.stamp ? props.value.stamp : 0
      const tax = props.value && props.value.tax ? props.value.tax : 0

      props.setFieldValue(
        'taPremiumMaster.taPremiumPerYear.premiumCf',
        parseFloat(premium) + parseFloat(stamp) + parseFloat(tax)
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value])

    const onAdd = useCallback(() => {
      props.fields.push({})
    }, [props.fields])

    const onDelete = useCallback(
      index => () => {
        props.fields.remove(index)
      },
      [props.fields]
    )

    return {
      loading,
      setLoading,
      onAdd,
      onDelete,
    }
  })
)

export default enhancer(DailyPremium)
