import { useState, useEffect, useCallback, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../../enhancers/index.js'
import { get, camelCase } from 'lodash'
import { Products } from '../show/index'
import { withFormik } from 'formik'

// const validate = values => {
//   const errors = {}

//   if (!values.type || values.type.length === 0) {
//     errors.type = 'Please select at least 1 item.'
//   }

//   if (!values.category || values.category.length === 0) {
//     errors.category = 'Please select at least 1 item.'
//   }

//   if (typeof values.visa === 'undefined') {
//     errors.visa = 'Please select 1 item.'
//   }

//   const taBenefitValidate = { taGlobalBenefits: [], taLocalBenefits: [] }
//   values.taBenefit &&
//     values.category &&
//     values.category.includes('global') &&
//     values.taBenefit.taGlobalBenefits.forEach((item, itemIndex) => {
//       taBenefitValidate.taGlobalBenefits.push({})
//       if (item) {
//         if (values.femaleRegister && values.maleRegister) {
//           const min = Math.min(values.femaleRegister[0], values.maleRegister[0])
//           const max = Math.max(values.femaleRegister[1], values.maleRegister[1])

//           if (itemIndex === 0) {
//             if (
//               parseInt(item.minAge) < parseInt(min) ||
//               parseInt(item.minAge) > parseInt(max) ||
//               parseInt(item.minAge) > parseInt(item.maxAge) ||
//               !item.minAge
//             ) {
//               taBenefitValidate.taGlobalBenefits[itemIndex].minAge = 'isValid'
//             }
//             if (
//               parseInt(item.maxAge) < parseInt(min) ||
//               parseInt(item.maxAge) > parseInt(max) ||
//               parseInt(item.minAge) > parseInt(item.maxAge) ||
//               !item.maxAge
//             ) {
//               taBenefitValidate.taGlobalBenefits[itemIndex].maxAge = 'isValid'
//             }
//           } else {
//             if (
//               parseInt(item.minAge) < parseInt(min) ||
//               parseInt(item.minAge) > parseInt(max) ||
//               parseInt(item.minAge) > parseInt(item.maxAge)
//             ) {
//               taBenefitValidate.taGlobalBenefits[itemIndex].minAge = 'isValid'
//             }
//             if (
//               parseInt(item.maxAge) < parseInt(min) ||
//               parseInt(item.maxAge) > parseInt(max) ||
//               parseInt(item.minAge) > parseInt(item.maxAge)
//             ) {
//               taBenefitValidate.taGlobalBenefits[itemIndex].maxAge = 'isValid'
//             }
//           }
//         }
//       }
//     })

//   values.taBenefit &&
//     values.category &&
//     values.category.includes('local') &&
//     values.taBenefit.taLocalBenefits.forEach((item, itemIndex) => {
//       taBenefitValidate.taLocalBenefits.push({})
//       if (item) {
//         if (values.femaleRegister && values.maleRegister) {
//           const min = Math.min(values.femaleRegister[0], values.maleRegister[0])
//           const max = Math.max(values.femaleRegister[1], values.maleRegister[1])

//           if (itemIndex === 0) {
//             if (
//               parseInt(item.minAge) < parseInt(min) ||
//               parseInt(item.minAge) > parseInt(max) ||
//               parseInt(item.minAge) > parseInt(item.maxAge) ||
//               !item.minAge
//             ) {
//               taBenefitValidate.taLocalBenefits[itemIndex].minAge = 'isValid'
//             }
//             if (
//               parseInt(item.maxAge) < parseInt(min) ||
//               parseInt(item.maxAge) > parseInt(max) ||
//               parseInt(item.minAge) > parseInt(item.maxAge) ||
//               !item.maxAge
//             ) {
//               taBenefitValidate.taLocalBenefits[itemIndex].maxAge = 'isValid'
//             }
//           } else {
//             if (
//               parseInt(item.minAge) < parseInt(min) ||
//               parseInt(item.minAge) > parseInt(max) ||
//               parseInt(item.minAge) > parseInt(item.maxAge)
//             ) {
//               taBenefitValidate.taLocalBenefits[itemIndex].minAge = 'isValid'
//             }
//             if (
//               parseInt(item.maxAge) < parseInt(min) ||
//               parseInt(item.maxAge) > parseInt(max) ||
//               parseInt(item.minAge) > parseInt(item.maxAge)
//             ) {
//               taBenefitValidate.taLocalBenefits[itemIndex].maxAge = 'isValid'
//             }
//           }
//         }
//       }
//     })

//   const taPremiumMasterValidate = { taPremiumPerRound: [] }
//   values.taPremiumMaster &&
//     values.taPremiumMaster.taPremiumPerRound.forEach((item, itemIndex) => {
//       taPremiumMasterValidate.taPremiumPerRound.push({})
//       if (item) {
//         if (
//           item.startAge &&
//           item.endAge &&
//           values.taPremiumMaster.taPremiumPerRound[itemIndex - 1] &&
//           values.taPremiumMaster.taPremiumPerRound[itemIndex - 1].endAge
//         ) {
//           if (itemIndex === 0) {
//             if (parseInt(item.startAge) > parseInt(item.endAge)) {
//               taPremiumMasterValidate.taPremiumPerRound[itemIndex].startAge =
//                 'isValid'
//               taPremiumMasterValidate.taPremiumPerRound[itemIndex].endAge =
//                 'isValid'
//             }
//           } else {
//             if (
//               parseInt(item.startAge) <=
//               parseInt(
//                 values.taPremiumMaster.taPremiumPerRound[itemIndex - 1].endAge
//               )
//             ) {
//               taPremiumMasterValidate.taPremiumPerRound[itemIndex].startAge =
//                 'isValid'
//             }
//             if (parseInt(item.startAge) > parseInt(item.endAge)) {
//               taPremiumMasterValidate.taPremiumPerRound[itemIndex].startAge =
//                 'isValid'
//               taPremiumMasterValidate.taPremiumPerRound[itemIndex].endAge =
//                 'isValid'
//             }
//           }
//         }
//       }
//     })

//   if (
//     taBenefitValidate.taGlobalBenefits.length > 0 ||
//     taBenefitValidate.taLocalBenefits.length > 0
//   ) {
//     errors.taBenefit = taBenefitValidate
//   }

//   if (taPremiumMasterValidate.taPremiumPerRound.length > 0) {
//     errors.taPremiumMaster = taPremiumMasterValidate
//   }
//   return errors
// }

const enhancer = compose(
  withRouter,
  withStores(stores => {
    const configs = get(stores.taProductStore, 'configs', [])

    const newInitailGlobal = {}
    configs.taProduct &&
      Object.keys(configs.taProduct.taBenefit.taGlobalBenefit).forEach(key => {
        const newProtech = []
        configs.taProduct.taBenefit.taGlobalBenefit[key].type.options.forEach(
          item => {
            newProtech.push({ type: item.value, formula: 'none' })
          }
        )
        newInitailGlobal[`${key}s`] = newProtech
      })

    const newInitailLocal = {}
    configs.taProduct &&
      Object.keys(configs.taProduct.taBenefit.taLocalBenefit).forEach(key => {
        const newProtech = []
        configs.taProduct.taBenefit.taLocalBenefit[key].type.options.forEach(
          item => {
            newProtech.push({ type: item.value, formula: 'none' })
          }
        )
        newInitailLocal[`${key}s`] = newProtech
      })

    return {
      create: stores.taProductStore.create,
      fetchConfig: stores.taProductStore.fetchConfig,
      configs: configs,
      initialValues: {
        taBenefit: {
          taGlobalBenefits: [{ ...newInitailGlobal }],
          taLocalBenefits: [{ ...newInitailLocal }],
        },
        taPremiumMaster: { taPremiumPerRound: [{}] },
      },
      fieldArrayInitialGlobal: newInitailGlobal,
      fieldArrayInitialLocal: newInitailLocal,
    }
  }),
  withFormik({
    displayName: 'taProductEdit',
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, props) => {
      let customStatus = ''
      if (values.status) {
        customStatus = 'online'
      } else {
        customStatus = 'offline'
      }

      values.type &&
        get(props.props.configs, 'taProduct.type.options', []).forEach(item => {
          if (!values.type.includes(item.value)) {
            delete values.taPremiumMaster[
              camelCase(`ta_premium_per_${item.value}`)
            ]
          }
        })

      values.category &&
        get(props.props.configs, 'taProduct.category.options', []).forEach(
          item => {
            if (!values.category.includes(item.value)) {
              delete values.taBenefit[camelCase(`ta_${item.value}_benefits`)]
            }
          }
        )

      await props.props.create({
        ...values,
        maleMinAge: values.maleRegister && values.maleRegister[0],
        maleMaxAge: values.maleRegister && values.maleRegister[1],
        femaleMinAge: values.femaleRegister && values.femaleRegister[0],
        femaleMaxAge: values.femaleRegister && values.femaleRegister[1],
        status: customStatus,
      })

      props.props.history.push('/products/ta')
    },
    mapPropsToValues: props => {
      return {
        ...props.initialValues,
      }
    },
    enableReinitialize: true,
  }),
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState('1')
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    useEffect(() => {
      props.fetchConfig()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickToggleTab = index => () => {
      setActiveTab(index)
    }

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])

    return {
      loading,
      setLoading,
      readOnly: false,
      isNew: true,
      textHeader: 'New Page',
      activeTab,
      onClickToggleTab,
      isCompany: queryString.parse(props.location.search).company_id,
      category: props.category || [],
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      buttonSubmit,
    }
  })
)

export default enhancer(Products)
