import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Panel, PanelHeader } from '../../../../components/panel/panel.jsx'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../../enhancers/index.js'
import { withFormik, Form, FastField, FieldArray, Field } from 'formik'
import {
  Input,
  Select,
  Slider,
  Switch,
  Checkbox,
  CurrencyInput,
  InputMaxMin,
  Image,
} from '../../../../components/formikComponent/index'
import SetupPremium from './setupPremium/index'
import SetupBenefit from './setupBenefit'
import SetupHealthBenefit from './setupHealthBenefit'
import SetupStep from './setupStep'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert'
import queryString from 'query-string'
import { get, range, snakeCase, camelCase, cloneDeep } from 'lodash'

export const Products = props => (
  <Form onSubmit={props.handleSubmit}>
    <ol className="breadcrumb float-xl-right">
      <li className="breadcrumb-item">
        <Link to={props.isHealth ? '/products/health' : '/products/life'}>
          {props.isHealth ? 'Health Product' : 'Life Product'}
        </Link>
      </li>
      <li className="breadcrumb-item active">
        {props.isNew ? 'Product New' : 'Product Edit'}
      </li>
    </ol>
    <h1 className="page-header">
      {props.textHeader}
      {/* <small>{props.textHeader}</small> */}
    </h1>
    <Panel style={{ padding: '16px' }}>
      <PanelHeader noButton={true}>
        <div className="d-flex justify-content-between">
          <div className="text-center p-t-10">{props.textHeader}</div>
          <div className="row">
            <div className="d-flex align-items-center">
              <Field
                name="status"
                component={Switch}
                className="form-control width-250"
                readOnly={!props.isNew}
                onClick={props.onClickStatus}
              />
              {props.values.status && (
                <span className="label label-info m-r-3 width-40">online</span>
              )}
              {!props.values.status && (
                <span className="label label-danger m-r-3 width-40">
                  offine
                </span>
              )}
            </div>
            {props.isEdit && (
              <>
                <button
                  type="button"
                  className="btn btn-success width-60 m-r-10 m-l-10"
                  onClick={props.onClickIsEdit}
                  style={{ justifySelf: 'flex-end' }}
                >
                  Edit
                </button>
                <button
                  type="button"
                  className="btn btn-danger width-60 "
                  onClick={props.onClickDelete}
                >
                  Delete
                </button>
              </>
            )}
            {!props.isEdit && (
              <>
                <button
                  type="button"
                  className="btn btn-primary width-60 m-r-10 m-l-10"
                  onClick={props.setConfirmUpdateToCon}
                  style={{ justifySelf: 'flex-end' }}
                >
                  Save
                </button>
                {!props.isNew && (
                  <button
                    type="button"
                    className="btn btn-danger width-60 "
                    onClick={props.onClickDelete}
                  >
                    Delete
                  </button>
                  // <button
                  //   type="button"
                  //   className="btn btn-danger  width-60 "
                  //   onClick={props.onReset}
                  // >
                  //   Cancel
                  // </button>
                )}
              </>
            )}
          </div>
        </div>
      </PanelHeader>
      <div style={{ padding: '16px' }}>
        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">Product Name</label>
          <div className="col-md-9">
            <FastField
              name="productName"
              component={Input}
              type="text"
              className="form-control width-250"
              classNameReadOnly="width-300"
              placeholder="Product Name"
              required
              readOnly={props.isEdit}
            />
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">Type</label>
          <div className="col-md-9">
            <Field
              name="type"
              component={Select}
              type="number"
              className="width-250"
              classNameReadOnly="width-300"
              placeholder="Product Types"
              required
              readOnly={props.isEdit}
              options={
                (props.configs.product && props.configs.product.type.options) ||
                []
              }
              isClearable={false}
            />
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">Company</label>
          <div className="col-md-9">
            <Field
              name="companyId"
              component={Select}
              type="number"
              className="width-250"
              placeholder="company"
              classNameReadOnly="width-300"
              required
              readOnly={props.isEdit}
              options={
                (props.configs.companies && props.configs.companies.options) ||
                []
              }
              disabled={props.isCompany}
              isClearable={false}
            />
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">ทุนประกัน</label>
          <div className="col-md-9">
            <div
              className="input-group"
              style={{
                border: props.isEdit ? 'unset' : '1px solid #d5dbe0',
                width: 'fit-content',
                borderRadius: '4px',
              }}
            >
              <div className="input-group-prepend">
                <FastField
                  name="minSumInsured"
                  component={CurrencyInput}
                  classNameReadOnly="width-150"
                  className="form-control width-100"
                  placeholder="ทุนประกันต่ำสุด"
                  required={!props.isHealth}
                  readOnly={props.isEdit}
                  style={{ border: 'unset' }}
                />
              </div>
              <div
                className="input-group-text bg-white width-50 text-center d-flex justify-content-center"
                style={{ border: 'unset', background: 'white' }}
              >
                -
              </div>
              <div className="input-group-append">
                <Field
                  name="maxSumInsured"
                  component={CurrencyInput}
                  classNameReadOnly="width-150"
                  className="form-control width-100"
                  placeholder="ทุนประกันสูงสุด"
                  readOnly={props.isEdit}
                  style={{ border: 'unset' }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">ระยะเวลาจ่ายเบี้ย</label>
          <div className="col-md-9">
            <div className="input-group width-250">
              <Field
                name="paymentPeriodType"
                component={Select}
                classNameReadOnly="width-100 p-t-15"
                type="number"
                width="105px"
                required
                readOnly={props.isEdit}
                options={get(
                  props,
                  'configs.product.paymentPeriodType.options',
                  []
                )}
                isClearable={false}
              />

              <FastField
                name="paymentPeriodValue"
                component={Input}
                classNameReadOnly="width-100 p-t-10"
                type="number"
                className="form-control"
                placeholder="ระยะเวลาจ่ายเบี้ย"
                required
                readOnly={props.isEdit}
                style={{ height: '38px', width: '135px', marginLeft: '10px' }}
              />
            </div>
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">ระยะเวลาคุ้มครอง</label>
          <div className="col-md-9">
            <div className="input-group width-250">
              <Field
                name="protectPeriodType"
                component={Select}
                classNameReadOnly="width-100"
                type="number"
                width="105px"
                required
                readOnly={props.isEdit}
                options={get(
                  props,
                  'configs.product.protectPeriodType.options',
                  []
                )}
                isClearable={false}
              />

              <FastField
                name="protectPeriodValue"
                component={Input}
                classNameReadOnly="width-100 p-t-15"
                type="number"
                className="form-control"
                placeholder="ระยะเวลาคุ้มครอง"
                required
                readOnly={props.isEdit}
                style={{ height: '38px', width: '135px', marginLeft: '10px' }}
              />
            </div>
          </div>
        </div>

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">อายุรับสมัครชาย</label>
          <div className="row p-l-20">
            {/* <FastField
              name="maleRegister"
              component={Slider}
              readOnly={props.isEdit}
              required
            /> */}
            <FastField
              name="maleMinAgeYear"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="ปี"
              min={0}
              max={99}
            />
            <FastField
              name="maleMinAgeMonth"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="เดือน"
              min={0}
              max={11}
            />
            <div className="f-s-10 m-l-10 m-r-10 d-flex align-items-center">
              -
            </div>
            <FastField
              name="maleMaxAgeYear"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="ปี"
              min={0}
              max={99}
            />
            <FastField
              name="maleMaxAgeMonth"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="เดือน"
              min={0}
              max={11}
            />
          </div>
        </div>

        {props.isHealth && (
          <div className="form-group row m-b-15">
            <label className="col-sm-3 col-form-label">
              ต่ออายุสัญญาได้ถึง (ชาย)
            </label>
            <div className="col-md-9">
              <FastField
                name="maleExtendContract"
                component={Input}
                type="number"
                className="form-control width-250"
                classNameReadOnly="width-300"
                placeholder="ต่ออายุสัญญาได้ถึง"
                required
                readOnly={props.isEdit}
              />
            </div>
          </div>
        )}

        <div className="form-group row m-b-15">
          <label className="col-sm-3 col-form-label">อายุรับสมัครหญิง</label>
          <div className="row p-l-20">
            {/* <FastField
              name="femaleRegister"
              component={Slider}
              readOnly={props.isEdit}
              required
            /> */}
            <FastField
              name="femaleMinAgeYear"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="ปี"
              min={0}
              max={99}
            />
            <FastField
              name="femaleMinAgeMonth"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="เดือน"
              min={0}
              max={11}
            />
            <div className="f-s-10 m-l-10 m-r-10 d-flex align-items-center">
              -
            </div>
            <FastField
              name="femaleMaxAgeYear"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="ปี"
              min={0}
              max={99}
            />
            <FastField
              name="femaleMaxAgeMonth"
              component={InputMaxMin}
              className="form-control width-70"
              placeholder="เดือน"
              min={0}
              max={11}
            />
          </div>
        </div>

        {props.isHealth && (
          <div className="form-group row m-b-15">
            <label className="col-sm-3 col-form-label">
              ต่ออายุสัญญาได้ถึง (หญิง)
            </label>
            <div className="col-md-9">
              <FastField
                name="femaleExtendContract"
                component={Input}
                type="number"
                className="form-control width-250"
                classNameReadOnly="width-300"
                placeholder="ต่ออายุสัญญาได้ถึง"
                required
                readOnly={props.isEdit}
              />
            </div>
          </div>
        )}

        {props.values.type === 'annuity' && (
          <div className="form-group row m-b-15">
            <label className="col-sm-3 col-form-label">อายุรับบำนาญ</label>
            <div className="row p-l-20  width-300">
              <FastField
                name="pensions"
                component={Slider}
                readOnly={props.isEdit}
                required
              />
            </div>
          </div>
        )}

        {!props.isHealth && (
          <div className="form-group row m-b-15">
            <label className="col-sm-3 col-form-label"></label>
            <div>
              <div className="row p-l-20 width-300">
                <FastField
                  name="hasDividend"
                  component={Checkbox}
                  readOnly={props.isEdit}
                  required
                  label="ปันผล"
                />
              </div>

              <div className="row p-l-20 width-300">
                <FastField
                  name="isTaxSaving"
                  component={Checkbox}
                  readOnly={props.isEdit}
                  required
                  label="ลดหย่อนภาษีได้"
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title=""
          onConfirm={props.onClickconfirmUpdate}
          onCancel={props.setConfirmUpdateToCon}
        >
          ต้องการบันทึกใช่หรือไม่ ?
        </SweetAlert>
      )}

      {props.confirmDelete && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title=""
          onConfirm={props.onDelete}
          onCancel={props.onClickDelete}
        >
          ต้องการลบใช่หรือไม่ ?
        </SweetAlert>
      )}
      {props.confirmChangeStatus && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title=""
          onConfirm={props.onUpdateStatus}
          onCancel={props.onStatusCancel}
        >
          ต้องการ update status ใช่หรือไม่ ?
        </SweetAlert>
      )}
    </Panel>
    <div className="row p-l-10 p-r-10">
      <div className="width-full (100%)">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: props.activeTab === '1' })}
              onClick={props.onClickToggleTab('1')}
            >
              <span className="d-sm-none">Tab 1</span>
              <div className="d-flex">
                <span className="d-sm-block d-none">Premium</span>
                {props.submitFailed &&
                  props._reduxForm.syncErrors.premiumValidate && (
                    <span
                      className="d-sm-block d-none"
                      style={{ color: 'red' }}
                    >
                      *
                    </span>
                  )}
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: props.activeTab === '2' })}
              onClick={props.onClickToggleTab('2')}
            >
              <span className="d-sm-none">Tab 2</span>
              <div className="d-flex">
                <span className="d-sm-block d-none">Benefit</span>
                {props.submitFailed &&
                  props._reduxForm.syncErrors.benefitValidate && (
                    <span
                      className="d-sm-block d-none"
                      style={{ color: 'red' }}
                    >
                      *
                    </span>
                  )}
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: props.activeTab === '3' })}
              onClick={props.onClickToggleTab('3')}
            >
              <span className="d-sm-none">Tab 3</span>
              <span className="d-sm-block d-none">Step</span>
            </NavLink>
          </NavItem>
          {!props.isNew && (
            <NavItem>
              <NavLink
                className={classnames({ active: props.activeTab === '4' })}
                onClick={props.onClickToggleTab('4')}
              >
                <span className="d-sm-none">Tab 4</span>
                <span className="d-sm-block d-none">Graph</span>
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={props.activeTab}>
          <TabPane tabId="1">
            {/* <FieldArray
              name="premiumMasters"
              component={SetupPremium}
              configs={props.configs}
              isHealth={props.isHealth}
              isEdit={props.isEdit}
            /> */}
            <FieldArray
              name="premiumMasters"
              render={fieldArrayProps => (
                <SetupPremium
                  {...fieldArrayProps}
                  configs={props.configs}
                  isHealth={props.isHealth}
                  isEdit={props.isEdit}
                />
              )}
            />
          </TabPane>
          <TabPane tabId="2">
            {props.isHealth ? (
              <SetupHealthBenefit
                configs={props.configs}
                current={props.current}
                initialValues={props.initialValues}
                type={props.values.type}
                isEdit={props.isEdit}
                setFieldValue={props.setFieldValue}
              />
            ) : (
              // <FieldArray
              //   name="benefit"
              //   component={SetupBenefit}
              //   configs={props.configs}
              //   isEdit={props.isEdit}
              // />
              <FieldArray
                name="benefit"
                render={fieldArrayProps => (
                  <SetupBenefit
                    {...fieldArrayProps}
                    configs={props.configs}
                    isHealth={props.isHealth}
                    isEdit={props.isEdit}
                  />
                )}
              />
            )}
          </TabPane>
          <TabPane tabId="3">
            {/* <FieldArray
              name="steps"
              component={SetupStep}
              configs={props.configs}
              isEdit={props.isEdit}
            /> */}
            <FieldArray
              name="steps"
              render={fieldArrayProps => (
                <SetupStep
                  {...fieldArrayProps}
                  configs={props.configs}
                  isHealth={props.isHealth}
                  isEdit={props.isEdit}
                />
              )}
            />
            <></>
          </TabPane>
          {!props.isNew && (
            <TabPane tabId="4">
              <div className="form-group m-r-5">
                <label htmlFor="gender">Graph</label>
                <Field
                  name="graph"
                  component={Image}
                  update={props.updateGraph}
                  id={get(props, 'match.params.id', [])}
                />
              </div>
              <div className="form-group m-r-5">
                <label htmlFor="gender">TQLD Graph</label>
                <Field
                  name="tqldGraph"
                  component={Image}
                  update={props.updateTqldGraph}
                  id={get(props, 'match.params.id', [])}
                />
              </div>
            </TabPane>
          )}
        </TabContent>
      </div>
    </div>
    <FastField name="benefitValidate" component={Input} type="text" hidden />
    <FastField name="premiumValidate" component={Input} type="text" hidden />
    <button type="submit" ref={props.buttonSubmit} hidden />
  </Form>
)

const enhancer = compose(
  withRouter,
  withStores(stores => {
    return {
      fetchById: stores.product.fetchById,
      clearAll: stores.product.clearAll,
      current: stores.product.current,
      update: stores.product.update,
      delete: stores.product.delete,
      configs: stores.product.configs,
      fetchConfig: stores.product.fetchConfig,
      clearData: stores.product.clearData,
      updateStatusPublish: stores.product.updateStatusPublishDetail,
      updateStatusUnpublished: stores.product.updateStatusUnpublishedDetail,
      updateGraph: stores.product.updateGraph,
      updateTqldGraph: stores.product.updateTqldGraph,
    }
  }),
  withFormik({
    displayName: 'editLifeProduct',
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, props) => {
      values.premiumMasters &&
        values.premiumMasters.forEach(
          (premiumMastersItem, premiumMastersIndex) => {
            const newPeriods = []

            Object.keys(premiumMastersItem.periods).forEach(item => {
              const arr = []
              premiumMastersItem.periods[item].premiumDetails &&
                premiumMastersItem.periods[item].premiumDetails.forEach(
                  (item, index) => {
                    if (item) {
                      const newItem = {
                        ...item,
                        age: index,
                      }
                      arr.push(newItem)
                    }
                  }
                )
              newPeriods.push({
                period: premiumMastersItem.periods[item].period,
                premiumOfSumInsured:
                  premiumMastersItem.periods[item].premiumOfSumInsured,
                premiumDetails: arr,
                discounts: premiumMastersItem.periods[item].discounts,
              })
            })

            values.premiumMasters[premiumMastersIndex].periods = newPeriods
          }
        )

      let newBenefit = {}
      get(values, 'benefit', []).forEach(benefit => {
        if (benefit.type === 'complete_the_contract')
          newBenefit = {
            ...newBenefit,
            [camelCase(benefit.type)]: {
              ...benefit,
            },
          }
        else
          newBenefit = {
            ...newBenefit,
            [camelCase(benefit.type)]: {
              [`${camelCase(benefit.type)}Rules`]: benefit.benefitDetails,
            },
          }
      })

      let customStatus = ''
      if (values.status) {
        customStatus = 'online'
      } else {
        customStatus = 'offline'
      }

      values.pensionsStartAge = values.pensions && values.pensions[0]
      values.pensionsEndAge = values.pensions && values.pensions[1]
      delete values.graph
      delete values.tqldGraph
      delete values.pensions

      await props.props.update(props.props.match.params.id, {
        ...values,
        status: customStatus,
        benefit: newBenefit,
      })
    },
    mapPropsToValues: props => {
      const productSetting = cloneDeep(get(props, 'current', {}))

      get(productSetting, 'premiumMasters', []).forEach(premiumMaster => {
        premiumMaster.periods.forEach(period => {
          const premiumDetails = range(100).map(age =>
            period.premiumDetails.find(
              premiumDetail => premiumDetail.age === age
            )
          )

          period.premiumDetails = premiumDetails
        })
      })

      let newBenefit = []
      get(productSetting, 'benefit', {}) &&
        Object.keys(get(productSetting, 'benefit', {})).forEach(benefit => {
          if (benefit === 'completeTheContract') {
            if (productSetting.benefit.completeTheContract)
              newBenefit.push({
                type: snakeCase(benefit),
                ...get(productSetting, `benefit.${benefit}`, []),
              })
          } else if (
            get(productSetting, `benefit.${benefit}.${benefit}Rules`, [])
              .length > 0
          )
            newBenefit.push({
              type: snakeCase(benefit),
              benefitDetails: get(
                productSetting,
                `benefit.${benefit}.${benefit}Rules`,
                []
              ),
            })
        })

      productSetting.benefit = newBenefit

      productSetting.pensions = [
        productSetting.pensionsStartAge,
        productSetting.pensionsEndAge,
      ]

      if (productSetting.status === 'online') {
        productSetting.status = true
      } else {
        productSetting.status = false
      }

      productSetting.graph = productSetting.graphPath
      productSetting.tqldGraph = productSetting.tqldGraphPath

      return {
        ...productSetting,
      }
    },
    enableReinitialize: true,
  }),
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [confirmDelete, setconFirmDelete] = useState(false)
    const [activeTab, setActiveTab] = useState('1')
    const [confirmChangeStatus, setConfirmChangeStatus] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    useEffect(() => {
      props.fetchById(props.match.params.id)
      props.fetchConfig()
      return () => {
        props.clearData()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (queryString.parse(props.location.search).company_id) {
        props.setFieldValue(
          'companyId',
          queryString.parse(props.location.search).company_id
        )
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialValues])

    const onUpdateStatus = useCallback(() => {
      if (props.values.status) {
        props.updateStatusPublish(props.match.params.id, 'online')
      } else {
        props.updateStatusUnpublished(props.match.params.id, 'offline')
      }
      setConfirmChangeStatus(!confirmChangeStatus)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmChangeStatus, props.status])

    const onStatusCancel = useCallback(() => {
      props.setFieldValue('status', props.initialValues.status)
      setConfirmChangeStatus(!confirmChangeStatus)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmChangeStatus])

    const onClickStatus = useCallback(() => {
      setConfirmChangeStatus(!confirmChangeStatus)
    }, [confirmChangeStatus])

    const onClickIsEdit = useCallback(() => {
      props.change('status', props.initialValues.status)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialValues])

    const onClickDelete = useCallback(() => {
      setconFirmDelete(!confirmDelete)
    }, [confirmDelete])

    const onReset = useCallback(() => {
      props.reset()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onDelete = useCallback(async () => {
      await props.delete(props.match.params.id)

      props.history.push('/products/life')

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id])

    const onClickToggleTab = useCallback(
      index => () => {
        setActiveTab(index)
      },
      []
    )

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])

    return {
      loading,
      setLoading,
      onClickIsEdit,
      onDelete,
      confirmDelete,
      onClickDelete,
      activeTab,
      onClickToggleTab: onClickToggleTab,
      textHeader: 'Products Detail',
      isCompany: queryString.parse(props.location.search).company_id,
      onReset,
      onClickStatus,
      confirmChangeStatus,
      onUpdateStatus,
      onStatusCancel,
      isEdit: false,
      setConfirmUpdateToCon,
      confirmUpdate,
      buttonSubmit,
      onClickconfirmUpdate,
      isHealth: false,
    }
  })
)

export default enhancer(Products)
