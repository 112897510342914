import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { Panel, PanelHeader } from './../../../components/panel/panel.jsx'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../enhancers/index.js'
import ReactTable from 'react-table'
import 'react-table/react-table.css'

const tableColumns = [
  {
    Header: 'Name',
    Cell: row => (
      <span className="btn-group">
        <img
          src={row.original.logoPath && row.original.logoPath}
          className="width-30 height-30 rounded-corner bg-grey-transparent-2 text-center p-auto"
          alt="company logo"
        />
        <div className="m-l-10 p-t-5">{row.original.name}</div>
      </span>
    ),
  },
  {
    Header: 'Product Count',
    className: 'p-t-15',
    Cell: row => (
      <span className="btn-group">
        {row.original.totalWholeLifeProduct !== 0 && (
          <span className="label label-pink m-r-3">
            Life - Whole Life : {row.original.totalWholeLifeProduct}
          </span>
        )}
        {row.original.totalTermProduct !== 0 && (
          <span className="label label-info m-r-3">
            Life - Term : {row.original.totalTermProduct}
          </span>
        )}
        {row.original.totalSavingProduct !== 0 && (
          <span className="label label-purple m-r-3">
            Life - Saving : {row.original.totalSavingProduct}
          </span>
        )}
        {row.original.totalAnnuityProduct !== 0 && (
          <span className="label label-yellow m-r-3">
            Life - Annuity : {row.original.totalAnnuityProduct}
          </span>
        )}
      </span>
    ),
  },
]

const Company = props => (
  <div>
    <ol className="breadcrumb float-xl-right">
      <li className="breadcrumb-item"></li>
    </ol>
    <h1 className="page-header">Company</h1>
    <Panel>
      <PanelHeader noButton={true}>
        company
        <button
          type="button"
          className="btn btn-success m-l-15"
          onClick={props.goToNewPage}
        >
          new
        </button>
      </PanelHeader>

      <ReactTable
        filterable
        data={props.all}
        columns={tableColumns}
        className="-highlight"
        defaultPageSize={10}
        getTrProps={props.goToEditPage}
      />
    </Panel>
  </div>
)

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    fetch: stores.company.fetch,
    all: stores.company.all,
  })),

  withHooks(props => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      props.fetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const goToNewPage = useCallback(
      () => props.history.push('/company/new'),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    const goToEditPage = useCallback((state, rowInfo) => {
      return {
        onClick: e => {
          props.history.push(`/company/${rowInfo.original.id}`)
        },
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { loading, setLoading, goToNewPage, goToEditPage }
  })
)

export default enhancer(Company)
