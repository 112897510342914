import React, { useState } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import { FieldArray } from 'formik'
import RoundPremium from './RoundPremium'
import YearlyPremium from './YearlyPremium'

const SetupPremium = props => (
  <div>
    <div className="p-b-10">
      {props.type.includes('round') && (
        <FieldArray
          name="taPremiumMaster.taPremiumPerRound"
          render={fieldArrayProps => (
            <RoundPremium {...fieldArrayProps} isEdit={props.isEdit} />
          )}
        />
      )}
      {props.type.includes('year') && (
        <YearlyPremium
          name="taPremiumMaster.taPremiumPerYear"
          isEdit={props.isEdit}
          value={props.values.taPremiumMaster.taPremiumPerYear}
          setFieldValue={props.setFieldValue}
        />
      )}
    </div>
  </div>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)

    return {
      loading,
      setLoading,
    }
  })
)

export default enhancer(SetupPremium)
