import React, { useState, useCallback } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import { CurrencyInput } from '../../../../../components/formikComponent/index'
import { FastField, Field } from 'formik'
import { withRouter } from 'react-router-dom'

const SetupStep = props => (
  <div className="table-responsive overflow-hidden">
    <table
      className="table m-b-0 table-bordered table-td-valign-middle"
      style={{ background: 'white' }}
    >
      <thead>
        <tr style={{ background: '#1d2226' }}>
          <th className="text-center text-silver-lighter">Min</th>
          <th className="text-center text-silver-lighter">Max</th>
          <th className="text-center text-silver-lighter">Step</th>
          {!props.isEdit && <th className="width-100" />}
        </tr>
      </thead>
      <tbody>
        {props.form.values.steps &&
          props.form.values.steps.map((item, index) => (
            <tr className="table-active" key={`${props.name}` + index}>
              <td className="width-200">
                <Field
                  name={`${props.name}[${index}].min`}
                  component={CurrencyInput}
                  className="form-control"
                  placeholder={`ทุนขั้นต่ำ ${new Intl.NumberFormat().format(
                    props.form.values.minSumInsured
                  ) || ''}`}
                  readOnly={props.isEdit}
                  required
                />
              </td>
              <td className="width-200">
                <Field
                  name={`${props.name}[${index}].max`}
                  component={CurrencyInput}
                  className="form-control"
                  placeholder={`ทุนสูงสุด ${new Intl.NumberFormat().format(
                    props.form.values.maxSumInsured
                  ) || ''}`}
                  readOnly={props.isEdit}
                  required
                />
              </td>
              <td>
                <FastField
                  name={`${props.name}[${index}].step`}
                  className="form-control width-150"
                  readOnly={props.isEdit}
                  component={CurrencyInput}
                  required
                />
              </td>

              {!props.isEdit && (
                <td className="text-center">
                  <button
                    type="button"
                    className="btn btn-danger btn-icon  btn-sm m-r-2"
                    onClick={props.removeItem(index)}
                  >
                    <i className="fa fa-minus"></i>
                  </button>
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </table>

    {!props.isEdit && (
      <button
        type="button"
        className="btn btn-success btn-icon btn-sm m-r-2 width-full height-30"
        onClick={props.addItem}
      >
        <i className="ion-md-add"></i>
      </button>
    )}
  </div>
)

const enhancer = compose(
  withRouter,

  withHooks(props => {
    const [loading, setLoading] = useState(false)

    const addItem = useCallback(() => {
      props.push()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const removeItem = useCallback(index => () => props.remove(index), [])

    return {
      loading,
      setLoading,
      addItem,
      removeItem,
    }
  })
)

export default enhancer(SetupStep)
