import React, { useState, useCallback } from 'react'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../../../enhancers'
import { FastField } from 'formik'
import { Input } from '../../../../../components/formikComponent'
import { get } from 'lodash'
import Details from './DetailComponent'

const FieldComponent = props => (
  <div>
    <div className="p-b-10">
      {get(props, 'form.values.paBenefits', []).map((value, index) => (
        <div className="border p-10 m-b-10" key={index}>
          <div className="d-flex justify-content-end">
            {get(props, 'form.values.paBenefits', []).length > 1 && (
              <button
                type="button"
                className="btn btn-danger btn-icon"
                onClick={props.onDelete(index)}
              >
                <i className="ion-md-trash" />
              </button>
            )}
          </div>
          <div className="form-group row m-b-10">
            <label className="col-form-label w-50">อายุ</label>
            <div className="w-50">
              <div
                className="input-group"
                style={{
                  border: props.isEdit ? 'unset' : '1px solid #d5dbe0',
                  width: 'fit-content',
                  borderRadius: '4px',
                }}
              >
                <div className="input-group-prepend">
                  <FastField
                    name={`${props.name}[${index}].minAge`}
                    component={Input}
                    classNameReadOnly="f-s-15 text-center"
                    type="number"
                    className="form-control width-100"
                    placeholder="อายุเริ่มต้น"
                    readOnly={props.isEdit}
                    style={{ border: 'unset' }}
                  />
                </div>
                <div
                  className="input-group-text bg-white width-50 text-center"
                  style={{ border: 'unset', background: 'white' }}
                >
                  -
                </div>
                <div className="input-group-append">
                  <FastField
                    name={`${props.name}[${index}].maxAge`}
                    component={Input}
                    classNameReadOnly="f-s-15 text-center"
                    type="number"
                    className="form-control width-100"
                    placeholder="อายุสิ้นสุด"
                    readOnly={props.isEdit}
                    style={{ border: 'unset' }}
                  />
                </div>
              </div>
            </div>
          </div>

          {props.configs.paProduct &&
            Object.keys(props.configs.paProduct.paBenefit).map(
              (key, keyIndex) => (
                <div key={key} className="m-b-10">
                  {props.configs.paProduct.paBenefit[key].type.options.map(
                    (item, itemIndex) => (
                      <Details
                        name={`${props.name}[${index}].${key}s[${itemIndex}]`}
                        key={item.value}
                        options={
                          props.configs.paProduct.paBenefit[key].formula.options
                        }
                        label={item.label}
                        isEdit={props.isEdit}
                        values={get(value, `[${key}s][${itemIndex}]`, {})}
                        setFieldValue={props.form.setFieldValue}
                      />
                    )
                  )}
                </div>
              )
            )}
        </div>
      ))}
      <button type="button" className="btn btn-success" onClick={props.onAdd}>
        Add
      </button>
    </div>
  </div>
)

const enhancer = compose(
  withStores(stores => ({
    configs: stores.paProductStore.configs,
  })),

  withHooks(props => {
    const [loading, setLoading] = useState(false)

    const onAdd = useCallback(() => {
      props.push({ ...props.form.values.paBenefits[0], minAge: '', maxAge: '' })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.values])

    const onDelete = useCallback(
      index => () => {
        props.remove(index)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    return {
      loading,
      setLoading,
      configs: get(props, 'configs', {}),
      onAdd,
      onDelete,
    }
  })
)

export default enhancer(FieldComponent)
