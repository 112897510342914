import { useState, useCallback, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../enhancers/index.js'
import { reduxForm, formValues } from 'redux-form'
import { v4 as uuidv4 } from 'uuid'
import { Partner } from '../show/index'
import { useEffect } from 'react'

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    create: stores.partnerStore.create,
    fetchConfig: stores.partnerStore.fetchConfig,
    configs: stores.partnerStore.configs,
  })),
  reduxForm({
    form: 'newPartner',
    enableReinitialize: true,
  }),
  formValues('remainingcompany'),
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    useEffect(() => {
      props.fetchConfig()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSave = props.handleSubmit(async values => {
      const products = (values.productIds || []).map(value => value.id)

      await props.create({ ...values, productIds: products })
      props.history.push('/partner')
    })

    const keyGenerate = () => {
      props.change('apiKey', uuidv4())
    }

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])
    return {
      loading,
      setLoading,
      textHeader: 'New Page',
      isNew: true,
      onSave,
      keyGenerate,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      buttonSubmit,
    }
  })
)

export default enhancer(Partner)
