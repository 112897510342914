import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  Panel,
  PanelHeader,
  PanelBody,
} from './../../../../components/panel/panel.jsx'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../../enhancers/index.js'
import { Form, Field, withFormik, FieldArray } from 'formik'
import { Input, Select } from '../../../../components/formikComponent'
import 'react-table/react-table.css'
import FinancialBudgetCompanyShow from './financialBudgetCompany'
import { isEmpty, cloneDeep } from 'lodash'
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert'
import * as yup from 'yup'

export const validate = yup.object().shape({
  year: yup.number().required(),
  quarter: yup.number().required(),
  weightCar: yup.number().required(),
  financialBudgetCompanies: yup.array().of(
    yup.object().shape({
      companyId: yup.string().required(),
      tca: yup.number().required(),
      tcr: yup.number().required(),
      il: yup.number().required(),
      tl: yup.number().required(),
    })
  ),
})

export const FinancialBudgetShow = props => (
  <div>
    <Form onSubmit={props.handleSubmit}>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/score_star/financial_budget">
            Financial Budget Tables{' '}
          </Link>
        </li>
        <li className="breadcrumb-item active">
          {props.isNew
            ? 'Financial Budget Tables New'
            : 'Financial Budget Tables Edit'}
        </li>
      </ol>
      <h1 className="page-header">{props.textHeader}</h1>
      <Panel>
        <PanelHeader noButton={true}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="text-center">Financial Budget Table</div>
            {props.isEdit && (
              <div>
                <button
                  type="button"
                  className="btn btn-info width-60"
                  onClick={props.onClickIsEdit}
                >
                  Edit
                </button>
                <button
                  type="button"
                  className="btn btn-danger m-l-15"
                  onClick={props.onClickOpenModalDelete}
                >
                  Delete
                </button>
              </div>
            )}
            {!props.isEdit && (
              <div>
                <button
                  type="button"
                  className="btn btn-primary width-60 m-r-10 m-l-10"
                  onClick={props.setConfirmUpdateToCon}
                  style={{ justifySelf: 'flex-end' }}
                >
                  save
                </button>
                {/* <button
                  type="button"
                  className="btn btn-danger  m-l-15"
                  onClick={props.onReset}
                >
                  cancel
                </button> */}
                {!props.isNew && (
                  <button
                    type="button"
                    className="btn btn-danger width-60"
                    onClick={props.onClickOpenModalDelete}
                  >
                    Delete
                  </button>
                )}
              </div>
            )}
          </div>
        </PanelHeader>
        <PanelBody>
          <div className="form-group row m-b-10">
            <label className="col-sm-3 col-form-label">Year</label>
            <div className="col-md-9">
              <Field
                name={'year'}
                component={Input}
                classNameReadOnly="f-s-15 width-200"
                className="form-control width-200"
                placeholder="year"
                readOnly={props.isEdit}
                type="number"
                required
              />
            </div>
          </div>
          <div className="form-group row m-b-10">
            <label className="col-sm-3 col-form-label">Quarter</label>
            <div className="col-md-9">
              <Field
                name={'quarter'}
                component={Select}
                classNameReadOnly="text-center width-200"
                className="width-200"
                placeholder="Quarter"
                readOnly={props.isEdit}
                options={QuarterConfigs}
                type="number"
                required
              />
            </div>
          </div>

          <div className="form-group row m-b-10">
            <label className="col-sm-3 col-form-label">WeightCar</label>
            <div className="col-md-9">
              <Field
                name="weightCar"
                component={Input}
                classNameReadOnly="f-s-15 width-200"
                className="form-control width-200"
                placeholder="weightCar"
                readOnly={props.isEdit}
              />
            </div>
          </div>

          <Panel>
            <PanelHeader noButton={true}>
              <div className="d-flex justify-content-between align-items-center">
                <div>Financial Budget Companies</div>
                {!props.isEdit && (
                  <div>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={props.onClickOpenOrCloseModal}
                    >
                      New Company
                    </button>
                  </div>
                )}
              </div>
            </PanelHeader>
            <PanelBody>
              {props.companyConfigs && props.companyConfigs.options && (
                <div>
                  <FieldArray
                    name="financialBudgetCompanies"
                    render={fieldArrayProps => (
                      <FinancialBudgetCompanyShow
                        {...fieldArrayProps}
                        isEditMainForm={props.isEdit}
                        configs={props.companyConfigs}
                        filterCompanyConfigs={props.filterCompanyConfigs || []}
                        scoreStarConfigs={props.scoreStarConfigs}
                        ModalIsOpen={props.ModalIsOpen}
                        setModalIsOpen={props.setModalIsOpen}
                        toggle={props.onClickOpenOrCloseModal}
                        values={props.values}
                      />
                    )}
                  />
                </div>
              )}
            </PanelBody>
          </Panel>
        </PanelBody>
      </Panel>

      {props.confirmUpdate && (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title=""
          onConfirm={props.onClickconfirmUpdate}
          onCancel={props.setConfirmUpdateToCon}
        >
          ต้องการบันทึกใช่หรือไม่ ?
        </SweetAlert>
      )}
      {props.ModalDeleteIsOpen && (
        <SweetAlert
          showCancel
          confirmBtnText="Confirm"
          cancelBtnBsStyle="danger"
          confirmBtnBsStyle="success"
          title=""
          onConfirm={props.onDelete}
          onCancel={props.toggle}
        >
          ต้องการลบใช่หรือไม่ ?
        </SweetAlert>
      )}
      <button type="submit" ref={props.buttonSubmit} hidden />
    </Form>
  </div>
)

const QuarterConfigs = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
]

const enhancer = compose(
  withRouter,
  withStores(stores => {
    return {
      fetchDetail: stores.financialBudgetStore.fetchDetail,
      financialBudgetTable: stores.financialBudgetStore.currentYear,
      update: stores.financialBudgetStore.update,
      fetchConfig: stores.financialBudgetStore.fetchConfig,
      companyConfigs: stores.financialBudgetStore.configs.companies,
      fetchScoreStarConfig: stores.scoreStarConfigStore.fetch,
      scoreStarConfigs: stores.scoreStarConfigStore.current,
      delete: stores.financialBudgetStore.delete,
    }
  }),
  withFormik({
    displayName: 'financialBudgetTable',
    validateOnChange: false,
    handleSubmit: async (values, props) => {
      await props.props.update(values.id, values)
    },
    mapPropsToValues: props => {
      const financialBudgetTable = props.financialBudgetTable

      return {
        ...financialBudgetTable,
      }
    },
    enableReinitialize: true,
    validationSchema: validate,
  }),

  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [ModalIsOpen, setModalIsOpen] = useState(false)
    const [ModalDeleteIsOpen, setDeleteModalIsOpen] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    const filterCompanyConfigs = useMemo(() => {
      let options =
        props.companyConfigs && cloneDeep(props.companyConfigs.options)
      const arr_ids = (props.values.financialBudgetCompanies || []).map(
        item => item.companyId
      )

      options = (options || []).filter(item => !arr_ids.includes(item.value))

      return options
    }, [props.companyConfigs, props.values.financialBudgetCompanies])

    const onReset = useCallback(() => {
      props.resetForm()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickOpenModalDelete = useCallback(() => {
      setDeleteModalIsOpen(!ModalDeleteIsOpen)
    }, [ModalDeleteIsOpen])

    const onClickIsEdit = useCallback(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialValues])

    const onClickOpenOrCloseModal = useCallback(() => {
      setModalIsOpen(!ModalIsOpen)
    }, [ModalIsOpen])

    const toggle = useCallback(() => {
      setDeleteModalIsOpen(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ModalDeleteIsOpen])

    const onDelete = useCallback(async () => {
      await props.delete(props.match.params.id)
      props.history.push(`/score_star/financial_budget`)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id])

    useEffect(() => {
      props.fetchDetail(props.match.params.id)
      props.fetchConfig()
      props.fetchScoreStarConfig()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (isEmpty(props.errors) && props.isSubmitting) {
      }
    }, [props.isSubmitting, props.errors])

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])

    return {
      textHeader: 'Financial Budget Table',
      isNew: false,
      loading,
      setLoading,
      isEdit: false,
      onClickIsEdit,
      onReset,
      onClickOpenOrCloseModal,
      ModalIsOpen,
      setModalIsOpen,
      onClickOpenModalDelete,
      ModalDeleteIsOpen,
      toggle,
      onDelete,
      filterCompanyConfigs,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      buttonSubmit,
    }
  })
)

export default enhancer(FinancialBudgetShow)
