import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../enhancers/index.js'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import LifeProduct from '../show/life/index'
import HealthCiProduct from '../show/health/ci/index'
import HealthHsProduct from '../show/health/hs/index'
import TaProduct from '../show/ta/index'
import PaProduct from '../show/pa/index'

const ModalDetail = props => (
  <Modal
    isOpen={props.isOpen}
    toggle={props.toggle}
    size="lg"
    style={{ maxWidth: '1600px', width: '80%' }}
  >
    <ModalHeader toggle={props.toggle}>
      {props.current.productName || ''}
    </ModalHeader>
    <ModalBody>
      {props.searchConfig &&
        props.searchConfig.productType === 'lifeConfigs' && (
          <LifeProduct
            productId={props.productId}
            searchConfig={props.searchConfig}
          />
        )}

      {props.searchConfig &&
        props.searchConfig.productType === 'healthConfigs' && (
          <>
            <HealthCiProduct
              productId={props.productId}
              searchConfig={props.searchConfig}
            />
            <HealthHsProduct
              productId={props.productId}
              searchConfig={props.searchConfig}
            />
          </>
        )}

      {props.searchConfig && props.searchConfig.productType === 'paConfigs' && (
        <PaProduct
          productId={props.productId}
          searchConfig={props.searchConfig}
        />
      )}

      {props.searchConfig && props.searchConfig.productType === 'taConfigs' && (
        <TaProduct
          productId={props.productId}
          searchConfig={props.searchConfig}
        />
      )}
    </ModalBody>
    <ModalFooter>
      <button className="btn btn-white" onClick={props.toggle}>
        Close
      </button>
    </ModalFooter>
  </Modal>
)

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    lifeFetchById: stores.search.lifeFetchById,
    healthFetchById: stores.search.healthFetchById,
    current: stores.search.current,
    fetchConfig: stores.search.fetchConfig,
    // searchConfig: get(stores, 'search.searchConfig', []),
    taFetchById: stores.search.taFetchById,
    paFetchById: stores.search.paFetchById,
  })),
  withHooks(props => {
    const [loading, setLoading] = useState(false)

    return {
      loading,
      setLoading,
      textHeader: [
        'เบี้ยที่ต้องชำระ',
        'ผลประโยชน์ทั่วไป',
        'ค่ารักษาพยาบาลในฐานะผู้ป่วยใน',
        'ค่ารักษาพยาบาลในฐานะผู้ป่วยนอก',
        'ค่ารักษาพยาบาลสำหรับผลประโยชน์อื่นๆ',
      ],
    }
  })
)

export default enhancer(ModalDetail)
