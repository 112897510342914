import React from 'react'
import ReactTable from 'react-table'

const TableResult = props => (
  <>
    <ReactTable
      filterable
      data={props.data}
      columns={props.tableColumns}
      className="-highlight"
      defaultPageSize={10}
      getTrProps={(state, rowInfo) => {
        return {
          onClick: e => {
            props.setModalIsOpen(true)
            props.setProductId(rowInfo.original.id)
          },
        }
      }}
    />
  </>
)

export default TableResult
