import { useState, useCallback, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../../enhancers/index'
import { withFormik } from 'formik'
import { MortalityShow } from '../showFormik/index'
import { useEffect } from 'react'
import { get, fill } from 'lodash'

const enhancer = compose(
  withRouter,
  withStores(stores => {
    const riskType = get(
      stores.mortalityBaseStore,
      'configs.riskType.options',
      []
    )
    const gender = get(stores.mortalityBaseStore, 'configs.gender.options', [])

    const initial = {
      common: { male: [], female: [] },
      industry: { male: [], female: [] },
    }

    riskType.forEach(riskTypeItem => {
      gender.forEach(genderItem => {
        const details = []
        fill(Array(100), '').forEach((item, index) => {
          details.push({
            age: index,
            riskType: riskTypeItem.value,
            gender: genderItem.value,
          })
        })
        initial[riskTypeItem.value][genderItem.value] = details
      })
    })

    return {
      create: stores.mortalityBaseStore.create,
      fetchConfig: stores.mortalityBaseStore.fetchConfig,
      configs: stores.mortalityBaseStore.configs,
      riskType: riskType,
      gender: gender,
      initialValues: { mortalityBaseTables: initial },
    }
  }),
  withFormik({
    displayName: 'editMortalityBaseTable',
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: async (values, props) => {
      await props.props.create(values)
      props.props.history.push('/score_star/mortality_base')
    },
    mapPropsToValues: props => {
      const values = props.initialValues

      return {
        ...values,
      }
    },
    enableReinitialize: true,
  }),
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [confirmUpdate, setConfirmUpdate] = useState(false)

    useEffect(() => {
      props.fetchConfig()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [activeTab, setActiveTab] = useState('1')

    const toggleTab = useCallback(
      tab => {
        if (activeTab !== tab) {
          setActiveTab(tab)
        }
      },
      [activeTab]
    )

    const setConfirmUpdateToCon = useCallback(() => {
      setConfirmUpdate(!confirmUpdate)
    }, [confirmUpdate])

    const buttonSubmit = useRef(null)

    const onClickconfirmUpdate = useCallback(() => {
      buttonSubmit.current.click()
      setConfirmUpdate(false)
    }, [])

    return {
      loading,
      setLoading,
      textHeader: 'New Page',
      isNew: true,
      toggleTab,
      activeTab,
      setConfirmUpdateToCon,
      onClickconfirmUpdate,
      confirmUpdate,
      buttonSubmit,
    }
  })
)

export default enhancer(MortalityShow)
