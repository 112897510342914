import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../../enhancers/index.js'
import { get } from 'lodash'

const ModalDetail = props => (
  <div>
    {props.current.benefitTable &&
      props.current.benefitTable.map((benefitItem, benefitIndex) => (
        <>
          {Object.keys(benefitItem).map((key, keyIndex) => (
            <>
              {benefitIndex !== 0 ? (
                benefitItem[key].map((detail, detailIndex) => (
                  <div className="m-t-20 p-l-40" key={detailIndex}>
                    <h5>{detail.label}</h5>
                    <div className="f-s-14 p-l-10">{detail.value}</div>
                  </div>
                ))
              ) : (
                <div className="m-t-20 p-l-40">
                  <h5>{benefitItem[key].label}</h5>
                  <div className="f-s-14 p-l-10">{benefitItem[key].value}</div>
                </div>
              )}
            </>
          ))}
        </>
      ))}
  </div>
)

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    current: stores.search.current,
    searchConfig: get(stores, 'search.searchConfig', []),
    paFetchById: stores.search.paFetchById,
  })),
  withHooks(props => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      delete props.searchConfig.lifeConfigs
      delete props.searchConfig.healthConfigs
      delete props.searchConfig.taConfigs
      delete props.searchConfig.paConfigs
      delete props.searchConfig.productConfigs
      delete props.searchConfig.partnerConfigs

      if (props.productId) {
        delete props.searchConfig.companies
        delete props.searchConfig.product

        props.paFetchById(props.productId, {
          ...props.searchConfig,
          productType: 'PaProduct',
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.productId])

    return {
      loading,
      setLoading,
    }
  })
)

export default enhancer(ModalDetail)
